import "react-datepicker/dist/react-datepicker.css";
import "./MultiValueField.styles.scss";
import React from "react";
import StringField from "../StringField";
import { Field } from "types";

interface MultiValueFieldProps extends Field {
  id?: string;
  readOnly?: boolean;
}

const MultiValueField = ({
  label,
  onChange,
  value,
  ...props
}: MultiValueFieldProps) => {
  const config = JSON.parse(props.config ? props.config : null);
  const values =
    String(value) !== "undefined" ? JSON.parse(String(value)) : "{}";
  const fields = [];
  if (props.config) {
    for (let i = 0; i < config.fields.length; i++) {
      const partField = config.fields[i];
      fields.push(
        <StringField
          name={props.name + i}
          key={props.uuid + "-" + partField.name}
          placeholder={partField.placeholder}
          readOnly={props.readOnly}
          value={values[partField.name] || ""}
          onChange={(event) => {
            values[partField.name] = event.target.value;
            onChange && onChange(JSON.stringify(values));
          }}
          type="STRING"
          label=""
          status="VISIBLE"
          options={values}
          hint={props.hint !== "null" ? props.hint : ""}
          order={props.order}
          sectionId={props.sectionId}
          required={false}
        />
      );
    }
  }
  return (
    <div className="multi-value-field">
      <label className="multi-value-field__label">{label}</label>
      {fields}
    </div>
  );
};

export default MultiValueField;
