const useQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const hasUrlParams = (params: string) => {
    return urlParams.get(params);
  };

  return {
    hasUrlParams,
    urlParams,
  };
};

export default useQueryParams;
