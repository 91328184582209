import "./SaveAssignmentConfigureModal.styles.scss";
import React from "react";
import { Icon, Button } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
};

const SaveAssignmentConfigureModal = ({ onCancel, onConfirm }: Props) => {
  return (
    <div className="assignment-deletion-confirmation">
      <div className="assignment-deletion-confirmation__icon">
        <Icon src={WarningIcon} />
      </div>
      <div className="assignment-deletion-confirmation__title">
        Do you want to save configure settings?
      </div>
      <div className="assignment-deletion-confirmation__buttons">
        <Button
          onClick={onCancel}
          name={`cancel_update_assignment_button`}
          value="Never mind"
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={onConfirm}
          name={`confirm_update_assignment_button`}
          value="Yes, save"
          extraClass="danger"
          size="small"
        />
      </div>
    </div>
  );
};

export default SaveAssignmentConfigureModal;
