import React from "react";
import { Icon } from "components";
import Search from "assets/icons/magnifying-glass.svg";

interface SubmissionsTableSearchProps {
  data: any;
  onChange: (filteredData: any) => void;
  patientId?: string;
  groupId?: string;
}

const SubmissionsTableSearch = ({
  data,
  onChange,
  patientId,
  groupId,
}: SubmissionsTableSearchProps) => {
  const handleChange = (e: { target: { value: any } }) => {
    searchSubmissions(e.target.value);
  };

  let filterableData = data;

  if (patientId) {
    filterableData = data.filter(
      (submission: { userId: string }) => submission.userId === patientId
    );
  } else if (groupId) {
    filterableData = data.filter(
      (submission: { groupId: string }) => submission.groupId === groupId
    );
  }

  const searchSubmissions = (searchInput: string) => {
    const filteredData = filterableData.filter(
      (value: { assignmentLabel: string }) => {
        return value.assignmentLabel
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      }
    );

    return onChange(filteredData);
  };

  return (
    <div className="data-table__search-wrapper">
      <div className="data-table__search-icon">
        <Icon src={Search} />
      </div>
      <input
        className="data-table__search-field"
        onChange={handleChange}
        placeholder="Search..."
      />
    </div>
  );
};

export default SubmissionsTableSearch;
