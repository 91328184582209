import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useFetchGroupUsers } from "utils/hooks";
import { User } from "types";

const useGetGroupUsers = (groupId: string) => {
  const { fetchGroupUsers } = useFetchGroupUsers();

  const groupUsers = useSelector((state: RootStateOrAny) => {
    return state.groups && state.groups.groupUsers;
  });

  useEffect(() => {
    if (groupUsers?.length === 0) {
      fetchGroupUsers(groupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortByRolePriority = (users: User[]) => {
    const rolePriority: { [key: string]: number } = {
      owner: 1,
      moderator: 2,
      member: 3,
    };

    return users
      .slice()
      .sort(
        (a, b) =>
          rolePriority[a.role as string] - rolePriority[b.role as string]
      );
  };

  return {
    groupUsers: sortByRolePriority(groupUsers),
  };
};

export default useGetGroupUsers;
