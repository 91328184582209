import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useFetchInviteCodes } from "utils/hooks";
import { InviteQRCode } from "types";
import { findInviteCode } from "utils/helpers";

const useGetInviteCodes = (groupId?: string) => {
  const { fetchInviteCodes } = useFetchInviteCodes();

  const { inviteCodes } = useSelector(
    (state: RootStateOrAny) => state.inviteCodes && state.inviteCodes
  );

  useEffect(() => {
    if (inviteCodes?.length === 0) {
      fetchInviteCodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    inviteCodes: inviteCodes
      .slice()
      .sort((a: InviteQRCode, b: InviteQRCode) =>
        b.createdAt.localeCompare(a.createdAt)
      ),
    inviteCode: findInviteCode(inviteCodes, groupId),
  };
};

export default useGetInviteCodes;
