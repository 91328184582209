import "./TherapistProfileForPatientsCard.styles.scss";
import React from "react";
import { toAmericanDateFromUnix } from "utils/helpers";
import { Icon, ImageSeparator } from "components";
import EnvelopeIcon from "assets/icons/envelope.svg";
import BluePerbson from "assets/images/perbson-1-blue.png";
import { useTranslation } from "react-i18next";

const TherapistProfileForPatientsCard = ({ therapist }) => {
  const { t } = useTranslation(["common"]);
  return (
    <div className="therapist-profile-for-patients-card">
      <h2 className="therapist-profile-for-patients-card__title">
        {therapist.firstName} {therapist.lastName}
      </h2>
      <small className="therapist-profile-for-patients-card__timestamp">
        {t("patient_therapist_page.connected_since_label")}{" "}
        {toAmericanDateFromUnix(therapist.connectedAt)}
      </small>
      <ImageSeparator image={BluePerbson} alt="blue person" />
      <div className="therapist-profile-for-patients-card__item">
        <div className="therapist-profile-for-patients-card__item-icon">
          <Icon src={EnvelopeIcon} />
        </div>
        <div className="therapist-profile-for-patients-card__item-label">
          <a
            href={`mailto:${therapist.email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {therapist.email}
          </a>
        </div>
      </div>
    </div>
  );
};

export default TherapistProfileForPatientsCard;
