import React from "react";

const MeetingBox = () => {
  return (
    <div>
      <p>Meeting Box!</p>
    </div>
  );
};

export default MeetingBox;
