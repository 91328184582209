import React from "react";
import { Field, MoodScale } from "types";

interface Props {
  field: Field;
  defaultValue: string | string[] | null;
  onChange: (...args: any) => void;
}
const CustomMoodScaleField = ({ field, defaultValue, onChange }: Props) => {
  let selected: { emoji: string; label: string };
  try {
    selected = JSON.parse(defaultValue as string) as {
      emoji: string;
      label: string;
    };
  } catch (e) {
    selected = { emoji: "", label: "" };
  }
  if (!selected) {
    selected = { emoji: "", label: "" };
  }

  const moodScale = field.moodScale as MoodScale;
  return (
    <div className={"custom-mood-scale-field"}>
      <div className={"label"}>{field.label}</div>
      <div className={"emojis"}>
        {moodScale.emojis.map((emoji, index) => (
          <div
            key={index}
            className={`emoji-wrapper ${
              selected.label == emoji.label ? `selected` : ``
            }`}
          >
            <button
              onClick={(e) => {
                e.preventDefault();

                onChange(JSON.stringify(emoji));
              }}
              className={"emoji-box"}
            >
              {emoji.emoji}
            </button>
            <div className={"emoji-label"}>{emoji.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomMoodScaleField;
