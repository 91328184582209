import React from "react";
import "./MultiResponseField.styles.scss";
import { Field, MultiResponseOption } from "types";

interface MultiResponseFieldProps {
  id: string | undefined;
  label: string;
  name: string | undefined;
  readOnly?: boolean;
  field: Field;
  value?: string;
  onChange: (...args: any) => void;
}

const MultiResponseField = ({
  label,
  readOnly,
  field,
  value,
  ...props
}: MultiResponseFieldProps) => {
  const options: MultiResponseOption[] = field.multiResponseOptions || [];
  const values = JSON.parse(value || "[]");
  if (values.length < options.length) {
    for (let i = values.length; i < options.length; i++) {
      values.push("");
    }
  }
  return (
    <div className="multi-response-field">
      <div className="multi-response-field__label">{label}</div>
      {options.map((option, index) => (
        <div className="multi-response-field__item" key={index}>
          <span className="list-numbers">{index + 1}.</span>
          <input
            className={"input__field"}
            disabled={readOnly}
            type="text"
            value={values[index]}
            placeholder={option.placeholder}
            onChange={(e) => {
              const newValues = values;
              newValues[index] = e.target.value;
              props.onChange(JSON.stringify(newValues));
            }}
          />
        </div>
      ))}
      {field.hint && field.hint !== "null" && (
        <p className="multi-response-field__hint">
          <small>{field.hint}</small>
        </p>
      )}
    </div>
  );
};

export default MultiResponseField;
