import "./styles.scss";
import React from "react";
import { Tabs, TopNav, PageTitle } from "components";
import { useTranslation } from "react-i18next";
import useSettingsPageTabs from "./useSettingsPageTabs";
import { APP_VERSION, BRAND_NAME } from "utils/constants";

const SettingsPage = () => {
  const { t } = useTranslation(["common"]);
  const { tabItems, activeTab, handleTabChange } = useSettingsPageTabs();
  const title = t("page_titles.settings_title");

  return (
    <>
      <TopNav
        topL={<PageTitle text={title} />}
        botL={
          <Tabs
            responsive
            items={tabItems}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
            size={"large"}
          />
        }
      />
      <div className="settings-page">
        <div>{activeTab?.mainComponent}</div>
      </div>
      <div>
        {BRAND_NAME} v{APP_VERSION}
      </div>
    </>
  );
};

export default SettingsPage;
