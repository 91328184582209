import { useDispatch } from "react-redux";
import {
  fetchPublicFilesAction,
  fetchTherapistFilesAction,
  fetchPatientFilesAction,
  fetchPatientPublicFilesAction,
  fetchGroupFilesAction,
} from "store/actions/files";
import { toast } from "react-toastify";
import { useLogout } from "utils/hooks";

const useFetchFiles = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();

  const fetchPublicFiles = () => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getAllFiles {
                fileUrl
                title
                description
                order
                uuid
                addedBy
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            return dispatch(fetchPublicFilesAction(resData.data.getAllFiles));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchTherapistFiles = () => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getFilesByTherapistId {
                fileUrl
                title
                description
                order
                uuid
                addedBy
                updatedAt
                type
                sharedWith {
                  label
                  value
                }
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchTherapistFilesAction(resData.data.getFilesByTherapistId)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchPatientFiles = () => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getFilesByPatientId {
                fileUrl
                title
                description
                order
                uuid
                addedBy
                updatedAt
                groupId
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchPatientFilesAction(resData.data.getFilesByPatientId)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchPatientPublicFiles = (patientId: string) => {
    if (!patientId) return null;

    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              getPublicFilesByPatientId(patientId: "${patientId}") {
                fileUrl
                title
                description
                order
                uuid
                addedBy
                updatedAt
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchPatientPublicFilesAction(
                resData.data.getPublicFilesByPatientId
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchGroupFiles = (groupId?: string) => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getFilesByGroupId(groupId: "${groupId ? groupId : null}") {
                fileUrl
                title
                description
                order
                uuid
                addedBy
                updatedAt
                groupId
                type
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchGroupFilesAction(resData.data.getFilesByGroupId)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return {
    fetchPublicFiles,
    fetchTherapistFiles,
    fetchPatientFiles,
    fetchPatientPublicFiles,
    fetchGroupFiles,
  };
};

export default useFetchFiles;
