import React from "react";
import PropTypes from "prop-types";
import SVG from "react-inlinesvg";
import iconMap from "./iconMap";

interface IconProps {
  src: string | null | undefined;
  alt?: string;
}

const Icon = ({ src, alt }: IconProps) => {
  const iconSrc = !src?.includes("/static/media/") && src ? iconMap[src] : src;
  return <SVG src={iconSrc} cacheRequests description={alt} />;
};

Icon.propTypes = {
  src: PropTypes.string,
};

export default Icon;
