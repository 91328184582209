import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import GroupMembersTable from "./components/GroupMembersTable/GroupMembersTable";
import {
  useFetchGroupUsers,
  useGetGroupUsers,
  useGetGroups,
} from "utils/hooks";
import GroupMembersTableEmptyState from "./components/GroupMembersTableEmptyState/GroupMembersTableEmptyState";
import { PageActionBar, SidePanel } from "components";
import AddMemberButton from "./components/AddMemberButton/AddMemberButton";
import AddMemberForm from "./components/AddMemberForm/AddMemberForm";

const GroupMembers = () => {
  const { uuid } = useParams();
  const { specificGroup: group } = useGetGroups({ slug: uuid });
  const MemoizedGroupMembersTable = React.memo(GroupMembersTable);
  const { fetchGroupUsers } = useFetchGroupUsers();
  const { groupUsers } = useGetGroupUsers(group.uuid);
  const memoizedGroupMembers = useMemo(() => groupUsers, [groupUsers]);

  const [showMemberPanel, setShowMemberPanel] = useState(false);

  const handleMemberPanelClose = () => {
    setShowMemberPanel(false);
  };

  const handleAddNewMember = () => {
    setShowMemberPanel(true);
  };

  useEffect(() => {
    fetchGroupUsers(group.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.uuid]);

  return (
    <div>
      <PageActionBar>
        <AddMemberButton onClick={handleAddNewMember} />
      </PageActionBar>
      {groupUsers?.length > 0 ? (
        <MemoizedGroupMembersTable
          groupId={group.uuid}
          data={memoizedGroupMembers}
        />
      ) : (
        <GroupMembersTableEmptyState />
      )}
      <SidePanel
        title="Add member"
        isVisible={showMemberPanel}
        onClose={handleMemberPanelClose}
      >
        <AddMemberForm group={group} onSuccess={handleMemberPanelClose} />
      </SidePanel>
    </div>
  );
};

export default GroupMembers;
