import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { removePatientAction } from "store/actions/patients";
import { toast } from "react-toastify";
import { useLogout, useFetchPatients, useCurrentUser } from "utils/hooks";
import { useNavigate } from "react-router-dom";
import { truncateText } from "utils/helpers";

const useRemovePatient = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchAllPatients } = useFetchPatients();
  const { user } = useCurrentUser();
  const history = useNavigate();

  const removePatient = (args) => {
    if (!args) {
      return null;
    }
    return new Promise(() => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
            mutation {
              removePatient(uuid: "${args.uuid}")
            }
          `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(`Hmm, something went wrong.`);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
            } else {
              return dispatch(
                removePatientAction(resData.data.removePatient[0])
              );
            }
          })
          .then((res) => {
            if (res) {
              fetchAllPatients();
              ReactGA.event("patient_removed", {
                user_id: user?.id,
                patient_id: args.uuid,
                connection_was: args.profile.status,
              });
              toast(
                `Done! ${truncateText(
                  args?.firstName ?? args.email
                )} has been removed from your account`
              );
              history(`/patients/`);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    removePatient,
  };
};

export default useRemovePatient;
