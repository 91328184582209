import "./GroupNotesEmptyState.styles.scss";
import React from "react";
import BirdOne from "assets/images/bird-1.png";
import { useGetGroups } from "utils/hooks";
import { useParams } from "react-router-dom";

const GroupNotesEmptyState = () => {
  const { uuid } = useParams();
  const { specificGroup: group } = useGetGroups({ slug: uuid });
  return (
    <div className="workbook-empty-state">
      <h3>You have not written any notes about {group?.name} yet.</h3>
      <p>Add a note using the &quot;Add Note&quot; button above</p>
      <div className="workbook-empty-state__image-holder">
        <img
          className="workbook-empty-state__image"
          src={BirdOne}
          alt="small bird"
        />
      </div>
    </div>
  );
};

export default GroupNotesEmptyState;
