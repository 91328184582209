/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-onchange */
import "./TablePagination.styles.scss";
import React, { useEffect } from "react";
import SkipToEnd from "assets/icons/chevron-double-right.svg";
import SkipToStart from "assets/icons/chevron-double-left.svg";
import NextPage from "assets/icons/chevron-right.svg";
import PreviousPage from "assets/icons/chevron-left.svg";
import { Icon } from "components";
import { Trans, useTranslation } from "react-i18next";
import { Updater } from "@tanstack/react-table";

interface TablePaginationProps {
  goToPage: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageCount: () => number;
  pageOptions: number[];
  pageIndex: number;
  pageSize: string;
  setPageSize: (updater: Updater<number>) => void;
}

const TablePagination = ({
  goToPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageOptions,
  pageIndex,
  pageSize,
  setPageSize,
}: TablePaginationProps) => {
  const { t } = useTranslation(["common"]);
  const storedPageSize = localStorage.getItem("submissionPageSize");

  useEffect(() => {
    if (storedPageSize) {
      setPageSize(Number(storedPageSize));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedPageSize]);
  return (
    <div className="data-table__pagination">
      <div className="data-table__pagination-buttons">
        <div
          className={`data-table__pagination-buttons--button ${
            !canPreviousPage ? "pagination-disabled" : ""
          }`}
          onClick={() => canPreviousPage && goToPage(0)}
        >
          <Icon src={SkipToStart} />
        </div>
        <div
          className={`data-table__pagination-buttons--button ${
            !canPreviousPage ? "pagination-disabled" : ""
          }`}
          onClick={() => canPreviousPage && previousPage()}
        >
          <Icon src={PreviousPage} />
        </div>
        <div
          className={`data-table__pagination-buttons--button ${
            !canNextPage ? "pagination-disabled" : ""
          }`}
          onClick={() => canNextPage && nextPage()}
        >
          <Icon src={NextPage} />
        </div>
        <div
          className={`data-table__pagination-buttons--button ${
            !canNextPage ? "pagination-disabled" : ""
          }`}
          onClick={() => canNextPage && goToPage(pageCount() - 1)}
        >
          <Icon src={SkipToEnd} />
        </div>
      </div>
      <div className="data-table__pagination-page-count">
        <Trans
          i18nKey="common:data_table.pagination"
          values={{ current: pageIndex + 1, total: pageOptions.length }}
          components={{ strong: <strong /> }}
        />
      </div>
      <div className="data-table__pagination-page-separator">|</div>
      <div className="data-table__pagination-page-input">
        {t("data_table.go_to_page")}:
        <input
          className="data-table__pagination-page-input-field"
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            goToPage(page);
          }}
        />
      </div>
      <div className="data-table__pagination-page-separator">|</div>
      <div className="data-table__pagination-count-select">
        <select
          className="data-table__pagination-count-select-field"
          value={storedPageSize || pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            localStorage.setItem("submissionPageSize", e.target.value);
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {t("data_table.show_per_page", {
                number: pageSize,
              })}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TablePagination;
