import "./DashboardEmptyState.styles.scss";
import React from "react";
import { Link } from "components";
import BalloonTwo from "assets/images/balloon-2.png";

const DashboardEmptyState = ({ user }) => {
  return (
    <div className="dashboard-empty-state">
      <h2>
        Hello, <b>{user.firstName}.</b> 👋
      </h2>
      <h3>Let&apos;s Get Started</h3>
      <p>
        This is your dashboard. Once you have created an action plan for your
        patient and they&apos;ve submitted responses, you&apos;ll be able to
        check on their progress here.
      </p>
      <p>
        <Link to={"/patients"}>Add a patient</Link> to get started, then{" "}
        <Link to={"/action-plans"}>create an action plan</Link> for them.
      </p>
      <div className="dashboard-empty-state__image-holder">
        <img
          className="dashboard-empty-state__image"
          src={BalloonTwo}
          alt="hot air balloon with person waving"
        />
      </div>
    </div>
  );
};

export default DashboardEmptyState;
