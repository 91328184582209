import { RootStateOrAny, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import { useMemo } from "react";
import { User } from "types";

const useCurrentUser = () => {
  const userRaw = useSelector((state: RootStateOrAny) => state.auth?.user);

  if (userRaw) {
    ReactGA.set({
      title: "user_properties",
      user_id: userRaw.id,
      user_type: userRaw.type,
      user_isVerified: userRaw.verified,
    });
  }

  const isAuthenticated = useMemo(() => {
    if (!userRaw) {
      return false;
    }
    const currentTime = new Date().getTime() / 1000;
    return currentTime < userRaw.expiresIn;
  }, [userRaw]);

  const user: User | null = useMemo(
    () =>
      userRaw
        ? {
            fullName: `${userRaw.firstName} ${userRaw.lastName}`,
            firstName: userRaw.firstName,
            lastName: userRaw.lastName,
            email: userRaw.email,
            type: userRaw.type,
            id: userRaw.userId,
            createdAt: userRaw.createdAt,
            plan: userRaw.plan || null,
            defaultLanguage: userRaw.defaultLanguage,
            digestSubscription: userRaw.digestSubscription,
            is2FAEnabled: userRaw.is2FAEnabled,
            slug: userRaw.slug,
            defaultReminder: userRaw.defaultReminder,
            notificationTimes: userRaw.notificationTimes,
            daysOfWeek: userRaw.daysOfWeek,
            notificationMessage: userRaw.notificationMessage,
            color: userRaw.color,
            icon: userRaw.icon,
            alias: userRaw.alias,
          }
        : null,
    [userRaw]
  );

  return {
    isVerified: userRaw?.verified === "true",
    isSubscribed: userRaw?.isSubscribed === "true",
    isAuthenticated,
    isPatient: userRaw?.type === "patient",
    isTherapist: userRaw?.type === "therapist",
    isTherapistProfileComplete: userRaw?.isTherapistProfileComplete,
    isTherapistPracticeInfoComplete: userRaw?.isTherapistPracticeInfoComplete,
    user,
  };
};

export default useCurrentUser;
