// TopNav.tsx
import "./TopNav.styles.scss";
import React, { ReactNode, useState, useEffect, useRef } from "react";
import { UserMenu } from "./components";
import Divider from "components/elements/Divider";
import { ConfirmEmailBar, Icon } from "components";
import HelpIcon from "assets/icons/circle-question.svg";
import { useCurrentUser } from "utils/hooks";
import { useTopNavHeight } from "./TopNavHeightContext";

declare global {
  interface Window {
    simplebase?: {
      widget?: {
        show: () => void;
        hide: () => void;
      };
      updateUserAttributes: any;
    };
  }
}

interface TopNavProps {
  hasAlertBar?: boolean | null;
  topL?: ReactNode;
  topR?: ReactNode;
  botL?: ReactNode;
  botR?: ReactNode;
}

const TopNav = ({
  hasAlertBar,
  topL,
  topR = <UserMenu />,
  botL,
  botR,
}: TopNavProps) => {
  const { isTherapist, user } = useCurrentUser();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const topNavRef = useRef<HTMLDivElement | null>(null);
  const { setTopNavHeight } = useTopNavHeight();

  const handleHelpClick = () => {
    if (window.simplebase && window.simplebase.widget) {
      if (isChatOpen) {
        window.simplebase.widget.hide();
      } else {
        window.simplebase.widget.show();
      }
      setIsChatOpen(!isChatOpen);
    }
  };

  useEffect(() => {
    const handleIframeVisibility = () => {
      const launcherIframe = document.querySelector(
        ".sb-chat-launcher-iframe"
      ) as HTMLIFrameElement | null;
      if (launcherIframe) {
        launcherIframe.style.display = isChatOpen ? "block" : "none";
      }
    };

    handleIframeVisibility();

    const observer = new MutationObserver(handleIframeVisibility);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [isChatOpen]);

  // Use effect to detect height changes
  useEffect(() => {
    if (topNavRef.current) {
      const height = topNavRef.current.offsetHeight;
      setTopNavHeight(height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAlertBar, topL, topR, botL, botR]);

  const topNavOnly = !botL && !botR;

  return (
    <div className="top-nav-wrapper" ref={topNavRef}>
      {hasAlertBar && <ConfirmEmailBar user={user} />}
      <div
        className={`top-nav flex justify-between align-center ${
          topNavOnly ? "top-nav-only" : ""
        }`}
      >
        <div className="top-l">{topL}</div>
        <div className="top-r flex align-center">
          {isTherapist && (
            <>
              <div
                className="top-nav__help-icon"
                onClick={handleHelpClick}
                onKeyDown={handleHelpClick}
                role="button"
                tabIndex={0}
              >
                <Icon src={HelpIcon} />
              </div>
              <Divider vertical />
            </>
          )}
          {topR}
        </div>
      </div>
      {(botL || botR) && (
        <div className="bottom-nav flex justify-between align-center">
          <div className="bot-l">{botL}</div>
          <div className="bot-r">{botR}</div>
        </div>
      )}
    </div>
  );
};

export default TopNav;
