import "./StatsCard.styles.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "components";
import ArrowRight from "assets/icons/arrow-right.svg";
import { AssignmentSubmission } from "types";

interface StatsCardProps {
  data: AssignmentSubmission[];
  title: string;
  color: string;
  icon: string;
  linked?: boolean;
}

const StatsCard = ({
  data,
  title,
  color,
  icon,
  linked = true,
}: StatsCardProps) => {
  const history = useNavigate();
  const handleStatsCardNavigation = () => {
    if (linked) {
      history(`/${title.replace(/\s+/g, "-").toLowerCase()}`);
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleStatsCardNavigation}
      onKeyDown={undefined}
      aria-label="navigate to stats page"
      className={`stats-card ${color} ${!linked ? "not-linked" : ""}`}
    >
      <div className="stats-card_right">
        <div className="stats-card_icon--wrapper">
          <div className="stats-card_icon">
            <Icon src={icon} />
          </div>
        </div>
        <div className="stats-card_data">
          <p className="stats-card_count">{data.length}</p>
          <p className="stats-card_title">
            {data.length > 1 || data.length === 0 ? title : title.slice(0, -1)}
          </p>
        </div>
      </div>
      {linked && (
        <div id="statsCardRight">
          <div className="stats-car__nav-arrow">
            <Icon src={ArrowRight} />
          </div>
        </div>
      )}
    </div>
  );
};

export default StatsCard;
