import React, { useEffect, useState } from "react";

const loadingStates = [
  "Creating account...",
  "Securing credentials...",
  "Verifying encryption...",
  "Almost done...",
];

const ButtonLoading = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex === loadingStates.length - 1) {
      return;
    }
    const interval = setInterval(() => {
      const updatedData = currentIndex + 1;
      setCurrentIndex(updatedData);
    }, 2500);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return <span>{loadingStates[currentIndex]}</span>;
};

export default ButtonLoading;
