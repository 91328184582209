/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import "./SideNav.styles.scss";
import React from "react";
import SideNavItem from "./components/SideNavItem";
import { BRAND_NAME } from "utils/constants";
import {
  useTherapistNavItems,
  usePatientNavItems,
} from "../constants/navItems";
import { useCurrentUser, useSafetyPlan } from "utils/hooks";

const SideNav = () => {
  const { isPatient } = useCurrentUser();
  const { therapistNavItems } = useTherapistNavItems();
  const { patientNavItems } = usePatientNavItems();
  const { isSafetyPlanAvailable } = useSafetyPlan({});

  const availablePatientNavItems = patientNavItems.filter(
    (item) => isSafetyPlanAvailable || item.label !== "Safety Plan"
  );

  const navItems = isPatient ? availablePatientNavItems : therapistNavItems;

  return (
    <>
      <div className={`side-nav side-nav__expanded`}>
        <div className="side-nav__top__wrapper">
          <div className="side-nav__top">
            <div className="side-nav__brand">{BRAND_NAME?.toLowerCase()}</div>
          </div>
          <div className="side-nav__menu__wrapper">
            <ul className="side-nav__menu">
              {navItems.map((sideNavItem, i) => {
                return <SideNavItem key={i} expanded item={sideNavItem} />;
              })}
            </ul>
          </div>
        </div>

        <ul className="side-nav__menu"></ul>
      </div>
    </>
  );
};

export default SideNav;
