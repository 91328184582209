/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./GenericLayout.styles.scss";
import React, { useEffect } from "react";
import { useGuestRedirect } from "utils/hooks";
import { Logo } from "components";

interface GenericLayoutProps {
  children: React.ReactNode;
  hideLogo?: string;
}

const GenericLayout = ({ children, hideLogo }: GenericLayoutProps) => {
  const { redirect } = useGuestRedirect();
  useEffect(() => {
    redirect();
  }, [redirect]);

  return (
    <div className="flex col generic-layout">
      {!hideLogo && (
        <div className="generic-layout-header">
          <Logo theme="white" />
        </div>
      )}
      <div className="flex col generic-layout-body">{children}</div>
    </div>
  );
};

export default GenericLayout;
