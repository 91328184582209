import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useFetchFiles from "./useFetchFiles";

interface useGetFileProps {
  fileId?: string;
}

const useGetFile = ({ fileId }: useGetFileProps) => {
  const { fetchPublicFiles, fetchTherapistFiles } = useFetchFiles();

  const files = useSelector(
    (state: RootStateOrAny) => state.files && state.files
  );

  useEffect(() => {
    if (files?.therapistFiles?.length === 0) {
      fetchTherapistFiles();
    }
    if (files?.publicFiles?.length === 0) {
      fetchPublicFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    file:
      files.therapistFiles.find((f: { uuid: string }) => f.uuid === fileId) ||
      files.files.find((f: { uuid: string }) => f.uuid === fileId),
  };
};

export default useGetFile;
