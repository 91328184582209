import React, { useState, useEffect, useCallback } from "react";
import "./FieldConfigure.styles.scss";
import { Input, Icon, Button } from "components";
import { Assignment, Section } from "types";
import { useUpdateAssignment } from "utils/hooks";

type Props = {
  field: any;
  section: Section;
  setActiveAssignment: any;
  originalAssignment?: Assignment;
};

const displayFieldType = [
  "FILE_ATTACHMENT",
  "PARAGRAPH_FIELD",
  "HEADING_FIELD",
  "RICH_TEXT",
  "VIDEO_EMBED",
  "IMAGE",
];

const FieldConfigure = ({
  field,
  section,
  setActiveAssignment,
  originalAssignment,
}: Props): any => {
  const [showInputFieldLabel, setShowInputFieldLabel] = useState(false);
  const [showInputFieldDescr, setShowInputFieldDescr] = useState(false);
  const [showSectionLabelInput, setShowSectionLabelInput] = useState(false);
  const { updateUnsavedConfigSettings } = useUpdateAssignment();

  useEffect(() => {
    if (field.status !== "VISIBLE") {
      setShowInputFieldLabel(false);
      setShowInputFieldDescr(false);
    }
  }, [field.status]);

  useEffect(() => {
    setShowInputFieldLabel(false);
    setShowInputFieldDescr(false);
    setShowSectionLabelInput(false);
  }, [originalAssignment]);

  const areDeeplyEqual: () => boolean = useCallback(() => {
    const originalSection = originalAssignment?.sections.find(
      (item) => item.uuid === section.uuid
    );
    const sectionLabel = section.label === originalSection?.label;
    const fields = section.fields.every((field, index) => {
      const status = field.status === originalSection?.fields[index].status;
      const label = field.label === originalSection?.fields[index].label;
      const hint = field.hint === originalSection?.fields[index].hint;
      if (status && label && hint) {
        return true;
      } else {
        return false;
      }
    });

    if (sectionLabel && fields) {
      return true;
    } else {
      return false;
    }
  }, [
    originalAssignment?.sections,
    section.fields,
    section.label,
    section.uuid,
  ]);

  const checkChanges = () => {
    const areUpdates = areDeeplyEqual();
    updateUnsavedConfigSettings(!areUpdates);
  };

  const handleChangeSectionLabel = (e: any) => {
    setActiveAssignment((assign: Assignment) => {
      const newAssignment = { ...assign };
      const activeSection = newAssignment.sections.find(
        (item) => item.uuid === section.uuid
      );
      if (activeSection) {
        activeSection.label = e.target.value;
      }
      return newAssignment;
    });
    checkChanges();
  };

  const handleHideField = () => {
    if (field.status === "VISIBLE") {
      setActiveAssignment((assign: Assignment) => {
        const newAssignment = { ...assign };
        const activeSection = newAssignment.sections.find(
          (item) => item.uuid === section.uuid
        );
        if (!activeSection) return;
        const activeField = activeSection.fields.find(
          (item) => item.uuid === field.uuid
        );
        if (!activeField) return;
        activeField.status = "HIDDEN";
        return newAssignment;
      });
    } else {
      setActiveAssignment((assign: Assignment) => {
        const newAssignment = { ...assign };
        const activeSection = newAssignment.sections.find(
          (item) => item.uuid === section.uuid
        );
        if (!activeSection) return;
        const activeField = activeSection.fields.find(
          (item) => item.uuid === field.uuid
        );
        if (!activeField) return;
        activeField.status = "VISIBLE";
        return newAssignment;
      });
    }
    checkChanges();
  };

  const handleClickOnSectionEdit = () => {
    if (!showSectionLabelInput) {
      setShowSectionLabelInput(true);
    } else {
      setActiveAssignment((assign: Assignment) => {
        const newAssignment = { ...assign };
        const activeSection = newAssignment.sections.find(
          (item) => item.uuid === section.uuid
        );
        if (activeSection && originalAssignment) {
          const originalSection = originalAssignment.sections.find(
            (item) => item.uuid === section.uuid
          );
          if (originalSection) {
            activeSection.label = originalSection?.label;
          }
        }
        return newAssignment;
      });
      setShowSectionLabelInput(false);
    }
    checkChanges();
  };

  const handleChangeField = (e: any) => {
    setActiveAssignment((assign: Assignment) => {
      const newAssignment = { ...assign };
      const activeSection = newAssignment.sections.find(
        (item) => item.uuid === section.uuid
      );
      if (!activeSection) return;
      const activeField = activeSection.fields.find(
        (item) => item.uuid === field.uuid
      );
      if (activeField) {
        activeField.label = e.target.value;
      }
      return newAssignment;
    });
    checkChanges();
  };

  const handleClickOnFieldEdit = () => {
    if (!showInputFieldLabel && !showInputFieldDescr) {
      setShowInputFieldLabel(true);
      setShowInputFieldDescr(true);
    } else {
      setActiveAssignment((assign: Assignment) => {
        const newAssignment = { ...assign };
        const activeSection = newAssignment.sections.find(
          (item) => item.uuid === section.uuid
        );
        if (!activeSection) return;
        const activeField = activeSection.fields.find(
          (item) => item.uuid === field.uuid
        );
        if (activeField && originalAssignment) {
          const originalSection = originalAssignment.sections.find(
            (item) => item.uuid === section.uuid
          );
          if (!originalSection) return;
          const originalField = originalSection.fields.find(
            (item) => item.uuid === activeField.uuid
          );
          if (originalField) {
            activeField.label = originalField.label;
            activeField.hint = originalField.hint;
          }
        }
        return newAssignment;
      });
      setShowInputFieldLabel(false);
      setShowInputFieldDescr(false);
    }
    checkChanges();
  };

  const handleChangeDescription = (e: any) => {
    setActiveAssignment((assign: Assignment) => {
      const newAssignment = { ...assign };
      const activeSection = newAssignment.sections.find(
        (item) => item.uuid === section.uuid
      );
      if (!activeSection) return;
      const activeField = activeSection.fields.find(
        (item) => item.uuid === field.uuid
      );
      if (activeField) {
        activeField.hint = e.target.value;
      }
      return newAssignment;
    });
    checkChanges();
  };

  //  If field's value is a static data (like image or video) it's label and description should not change
  if (displayFieldType.includes(field.type)) return;

  return (
    <>
      {field.order === 1 &&
        (section.label || section.label === "") &&
        originalAssignment &&
        originalAssignment.sections.length > 1 && (
          <>
            {/* SECTION LABEL */}
            <div className="section-label">
              <p className="section-label_text">{section.label}</p>
              <Button
                value={
                  <>
                    {showSectionLabelInput ? (
                      <Icon src={"CancelArrow"} />
                    ) : (
                      <Icon src={"ChangeIcon"} />
                    )}
                    <span>{showSectionLabelInput ? "CANCEL" : "EDIT"}</span>
                  </>
                }
                name="button-icon_change"
                extraClass="button-icon button-icon_change"
                onClick={handleClickOnSectionEdit}
              />
            </div>
            {showSectionLabelInput && (
              <Input
                value={section.label}
                onChange={handleChangeSectionLabel}
              />
            )}
          </>
        )}

      {/* FIELD */}
      <div className="field_container">
        <div className="field_first-line">
          {/* Field label */}
          <div className="label_container">
            <Button
              value={
                <Icon
                  src={field.status === "VISIBLE" ? "Preview" : "HideIcon"}
                />
              }
              name="button-icon_hide"
              extraClass={`button-icon ${
                field.status !== "VISIBLE" ? "button-icon_hide" : ""
              }`}
              onClick={handleHideField}
            />
            <span
              className={`
                label-text ${
                  field.status !== "VISIBLE" ? "label-text_hidden" : ""
                }`}
            >
              {field.label}
            </span>
            {field.status === "VISIBLE" && (
              <Button
                value={
                  <>
                    {showInputFieldLabel || showInputFieldDescr ? (
                      <Icon src={"CancelArrow"} />
                    ) : (
                      <Icon src={"ChangeIcon"} />
                    )}
                    <span>
                      {showInputFieldLabel || showInputFieldDescr
                        ? "CANCEL"
                        : "EDIT"}
                    </span>
                  </>
                }
                name="button-icon_change"
                extraClass="button-icon button-icon_change"
                onClick={handleClickOnFieldEdit}
              />
            )}
          </div>

          {/* Field description */}
          {field.hint &&
            field.hint !== "null" &&
            field.status === "VISIBLE" && (
              <div className="label_container">
                <p className="field_description">{field.hint}</p>
              </div>
            )}

          {/* Inputs to change label and description */}
          {field.status === "VISIBLE" && showInputFieldLabel && (
            <Input
              value={field.label}
              placeholder={field.label ? "" : "Add a label here"}
              onChange={handleChangeField}
            />
          )}
          {field.status === "VISIBLE" && showInputFieldDescr && (
            <Input
              value={field.hint === "null" ? "" : field.hint}
              placeholder={
                field.hint && field.hint !== "null"
                  ? ""
                  : "Add a description here"
              }
              onChange={handleChangeDescription}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FieldConfigure;
