import SadTear from "assets/icons/moods/sad-tear.svg";
import Frown from "assets/icons/moods/frown.svg";
import Meh from "assets/icons/moods/meh.svg";
import Smile from "assets/icons/moods/smile.svg";
import GrinBeam from "assets/icons/moods/grin-beam.svg";

const moods = [
  {
    label: "Awful",
    icon: SadTear,
    theme: "not-ok",
  },
  {
    label: "Bad",
    icon: Frown,
    theme: "sad",
  },
  {
    label: "meh",
    icon: Meh,
    theme: "meh",
  },
  {
    label: "good!",
    icon: Smile,
    theme: "great",
  },
  {
    label: "awesome",
    icon: GrinBeam,
    theme: "awesome",
  },
];

export default moods;
