/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";

const useClickOutside = () => {
  const node = useRef<HTMLInputElement>(null);

  const [isNodeVisible, setIsNodeVisible] = useState(false);
  const handleClickOutside = (e: any) => {
    if (node?.current?.contains(e.target)) {
      return;
    }
    setIsNodeVisible(false);
  };

  useEffect(() => {
    if (isNodeVisible) {
      document.addEventListener("mousedown", (event: MouseEvent) => {
        handleClickOutside(event);
      });
    } else {
      document.removeEventListener("mousedown", (event: MouseEvent) => {
        handleClickOutside(event);
      });
    }
    return () => {
      document.removeEventListener("mousedown", (event: MouseEvent) => {
        handleClickOutside(event);
      });
    };
  }, [isNodeVisible]);

  return {
    isNodeVisible,
    setIsNodeVisible,
    node,
  };
};

export default useClickOutside;
