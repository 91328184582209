/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import "./SideNavItem.styles.scss";
import React from "react";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Icon } from "components";

type SideNavItemType = {
  label: string;
  icon: any;
  link: string;
  hasAlert?: boolean;
};

interface SideNavItemProps {
  expanded: boolean;
  item: SideNavItemType;
}

const SideNavItem = ({ expanded, item }: SideNavItemProps) => {
  const { label, icon, link, hasAlert } = item;
  return (
    <>
      <NavLink
        to={link}
        className={`side-nav-item ${expanded ? "side-nav-item__expanded" : ""}`}
        id={label}
        data-tip
        data-for={`${label.toLowerCase()}-tip`}
      >
        <div className="side-nav-item__icon">
          <Icon src={icon} />
        </div>
        <div className="side-nav-item__label">{label}</div>
        {hasAlert && <div className="side-nav-item__dot" />}
      </NavLink>
      {!expanded && (
        <ReactTooltip id={`${label.toLowerCase()}-tip`} place="right">
          <span>{label}</span>
        </ReactTooltip>
      )}
    </>
  );
};

export default React.memo(SideNavItem);
