import { PURGE } from "redux-persist";

const initialState = {
  patients: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "ADD_PATIENT":
      return {
        ...state,
        patients: [...state.patients, payload],
      };
    case "UPDATE_PATIENT":
      return {
        ...state,
        patients: state.patients.map((patient) =>
          patient.uuid === payload.uuid
            ? {
                ...patient,
                ...payload,
              }
            : patient
        ),
      };
    case "FETCH_PATIENTS":
      // Sort patients by createdAt field to show new patients on top
      const sortedPatients = payload.sort(
        (a, b) => Number(b.createdAt) - Number(a.createdAt)
      );
      return {
        ...state,
        patients: [...sortedPatients],
      };
    case "REMOVE_PATIENT":
      return {
        ...state,
        patients: state.patients.filter((patient) => patient !== payload.uuid),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
