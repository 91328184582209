import React from "react";
import { useForm } from "react-hook-form";
import { useTherapistOnboarding } from "utils/hooks";
import { Button } from "components";
import PracticeInfo from "forms/TherapistForms/PracticeInfo";
import { useTranslation } from "react-i18next";

const TherapistPracticeInfoSettings = () => {
  const { register, handleSubmit, control } = useForm();
  const { createTherapistPracticeInfo, isSubmitting } = useTherapistOnboarding({
    isFromSettings: true,
  });
  const formProps = { register, handleSubmit, control };
  const { t } = useTranslation(["common"]);
  return (
    <section className="settings-section" id="therapist-settings">
      <h2 className="section-title">
        {t("practice_information.practice_information_settings_label")}
      </h2>
      <hr className="divider" />
      <form onSubmit={handleSubmit(createTherapistPracticeInfo)}>
        <div className="settings-section__content">
          <PracticeInfo {...formProps} />
        </div>
        <Button
          type="submit"
          name="therapist_onboarding_button"
          value={t("common_labels.submit_changes")}
          isLoading={isSubmitting}
        />
      </form>
    </section>
  );
};

export default TherapistPracticeInfoSettings;
