import "./IconButton.styles.scss";
import { Icon } from "components";
import React from "react";

interface IconButtonProps {
  icon: string;
  onClick: () => void;
  value?: string;
  name?: string;
  extraClass?: string;
}

const IconButton = ({
  icon,
  onClick,
  value,
  name,
  extraClass,
}: IconButtonProps) => {
  return (
    <button
      name={name}
      className={`icon-button ${extraClass ? extraClass : ""}`}
      onClick={onClick}
    >
      <div className={`${value ? "icon-button_value-wrapper" : ""}`}>
        <Icon src={icon} />
        {value && <span>{value}</span>}
      </div>
    </button>
  );
};

export default IconButton;
