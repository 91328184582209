import { TabItem } from "components/elements/Tabs/Tabs";
import { useState } from "react";

const useNoteDetailsTabs = () => {
  const noteDetailTabs: TabItem[] = [
    {
      name: "Note",
      url: null,
      key: "note",
      icon: null,
      actionComponent: null,
      mainComponent: null,
    },
    {
      name: "Settings",
      url: null,
      key: "note",
      icon: null,
      actionComponent: null,
      mainComponent: null,
    },
  ];
  const [activeTab, setActiveTab] = useState(noteDetailTabs[0]);

  const handleTabChange = (tab: TabItem) => {
    setActiveTab(tab);
  };

  return {
    noteDetailTabs,
    activeTab,
    handleTabChange,
  };
};

export default useNoteDetailsTabs;
