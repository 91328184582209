export const zoomConfig = {
  topic: "Test topic",
  name: "Chrome",
  password: "pass",
  userIdentity: "",
  sessionKey: "",
  // role = 1 to join as host, 0 to join as attendee. The first user must join as host to start the session
  roleType: 1,
  signature: "",
};
