import React from "react";
import "./FieldStyles.styles.scss";
import { Field, MultiResponseOption } from "types";

interface MultiResponseFieldProps {
  field: Field;
  defaultValue: string | string[] | null;
  onChange: (...args: any) => void;
}

const MultiResponseField = ({
  field,
  defaultValue,
  onChange,
}: MultiResponseFieldProps) => {
  const options: MultiResponseOption[] = field.multiResponseOptions || [];
  let values: string[] = [];
  if (typeof defaultValue === "string" && defaultValue !== "") {
    try {
      values = JSON.parse(defaultValue);
    } catch (e) {
      console.error(e);
    }
  } else if (Array.isArray(defaultValue)) {
    values = defaultValue;
  }

  if (values.length < options.length) {
    for (let i = values.length; i < options.length; i++) {
      values.push("");
    }
  }
  const [defaultValues, setValues] = React.useState<string[]>(values);
  return (
    <div className="multi-response-field">
      <div className="multi-response-field__label">{field.label}</div>
      {options.map((option, index) => (
        <div className="multi-response-field__item" key={index}>
          <span className="list-numbers">{index + 1}.</span>
          <input
            className={"input__field"}
            type="text"
            defaultValue={defaultValues[index]}
            placeholder={option.placeholder}
            onChange={(e) => {
              defaultValues[index] = e.target.value;
              setValues([...defaultValues]);
              onChange(defaultValues);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default MultiResponseField;
