export const fetchNotesAction = (notes) => {
  return {
    type: "FETCH_NOTES",
    payload: notes,
  };
};

export const fetchGroupNotesAction = (notes) => {
  return {
    type: "FETCH_GROUP_NOTES",
    payload: notes,
  };
};

export const addNoteAction = (note) => {
  return {
    type: "ADD_NOTE",
    payload: note,
  };
};

export const addGroupNoteAction = (note) => {
  return {
    type: "ADD_GROUP_NOTE",
    payload: note,
  };
};

export const updateNoteAction = (note) => {
  return {
    type: "UPDATE_NOTE",
    payload: note,
  };
};

export const updateGroupNoteAction = (note) => {
  return {
    type: "UPDATE_GROUP_NOTE",
    payload: note,
  };
};

export const deleteNoteAction = (noteId) => {
  return {
    type: "DELETE_NOTE",
    payload: noteId,
  };
};

export const deleteGroupNoteAction = (noteId) => {
  return {
    type: "DELETE_GROUP_NOTE",
    payload: noteId,
  };
};
