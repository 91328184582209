import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { useFileUploader, useUpdateFile } from "utils/hooks";
import "./FileUploader.scss";
import {
  Animate,
  Button,
  Icon,
  Input,
  PatientsDropdown,
  Textarea,
} from "components";
import { getFileTypeIconFromUrl } from "pages/AppPages/TherapistPages/Resources/components/Files/utils";
import FileIcon from "assets/icons/paperclip.svg";

interface FileUploaderProps {
  showPatients?: boolean;
  defaultSharedWith?: any;
  onSuccess: (f?: any) => void;
  currentFile?: any;
  dropZoneOnly?: boolean;
  error?: string;
  groupId?: string;
}

const FileUploader = ({
  showPatients,
  defaultSharedWith,
  onSuccess,
  currentFile,
  dropZoneOnly,
  error,
  groupId,
}: FileUploaderProps) => {
  const {
    onFileChange,
    onFileUpload,
    onGroupFileUpload,
    selectedFile,
    message,
    isLoading,
  } = useFileUploader();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isFullNameNullable =
    defaultSharedWith?.firstName === null &&
    defaultSharedWith?.lastName === null;

  const formattedDefaultSharedWith = defaultSharedWith
    ? {
        value: defaultSharedWith.uuid,
        label: isFullNameNullable
          ? defaultSharedWith.email
          : `${defaultSharedWith.firstName} ${defaultSharedWith.lastName}`,
      }
    : null;

  const [selectedPatients, setSelectedPatients] = useState<any[] | null>(
    currentFile?.sharedWith ||
      (formattedDefaultSharedWith ? [formattedDefaultSharedWith] : [])
  );

  const uploadFile = async (data: any) => {
    groupId
      ? await onGroupFileUpload({
          file: null,
          args: {
            title: data.title,
            description: data.description,
            selectedPatients: selectedPatients,
            groupId,
          },
          onSuccess: (fileId) => onSuccess(fileId as string),
        })
      : await onFileUpload({
          file: null,
          args: {
            title: data.title,
            description: data.description,
            selectedPatients: selectedPatients,
          },
          onSuccess: (fileId) => onSuccess(fileId as string),
        });
    setTimeout(() => {
      onFileChange(null);
    }, 2000);
  };

  const onDrop = useCallback(
    (files: File[] | null) => {
      onFileChange(files);
      if (dropZoneOnly && files) {
        groupId
          ? onGroupFileUpload({
              file: files[0],
              args: {
                title: files[0].name,
                description: "",
                selectedPatients: selectedPatients,
              },
              onSuccess: (fileId) => onSuccess(fileId as string),
            })
          : onFileUpload({
              file: files[0],
              args: {
                title: files[0].name,
                description: "",
                selectedPatients: selectedPatients,
              },
              onSuccess: (fileId) => onSuccess(fileId as string),
            });
      }
    },
    [
      onFileChange,
      dropZoneOnly,
      groupId,
      onGroupFileUpload,
      selectedPatients,
      onFileUpload,
      onSuccess,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onPatientSelect = (patients: any[]) => {
    setSelectedPatients(patients);
  };

  const { submitUpdateFile, submitUpdateGroupFile, isUpdateSuccessful } =
    useUpdateFile({
      files: [currentFile],
      selectedPatients,
      groupId,
    });

  useEffect(() => {
    if (isUpdateSuccessful) {
      onSuccess();
    }
  }, [isUpdateSuccessful, onSuccess]);

  useEffect(() => {
    if (defaultSharedWith) {
      setSelectedPatients([formattedDefaultSharedWith]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSharedWith]);

  const updateHandler = groupId ? submitUpdateGroupFile : submitUpdateFile;

  const onUpdateData = (data: any) => {
    return updateHandler([data]);
  };

  return (
    <form
      className="file-uploader-container"
      onSubmit={handleSubmit(currentFile ? onUpdateData : uploadFile)}
    >
      {currentFile ? (
        <div className="selected-file__inner with-mb">
          <div className="file-icon">
            <Icon src={getFileTypeIconFromUrl(currentFile.fileUrl)} />
          </div>
          <div className="selected-file__name">
            {currentFile.fileUrl?.substring(
              currentFile.fileUrl.lastIndexOf("/") + 1
            )}
          </div>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`file-dropzone ${
            isDragActive ? "file-dropzone--isActive" : ""
          } ${selectedFile ? "selected-file" : ""} ${
            dropZoneOnly ? "drop-zone-only" : "not-drop-zone-only"
          }`}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <>
              <div className="file-uploader-container__icon">
                <Icon src={FileIcon} />
              </div>
              <p className="file-name">Drop your file here ...</p>
            </>
          ) : selectedFile ? (
            isLoading ? (
              <div className="selected-file__inner">
                <div className="file-icon">
                  <Icon src={getFileTypeIconFromUrl(selectedFile.name)} />
                </div>
                <div className="selected-file__name">Uploading...</div>
              </div>
            ) : (
              <div className="selected-file__inner">
                <div className="file-icon">
                  <Icon src={getFileTypeIconFromUrl(selectedFile.name)} />
                </div>
                <div className="selected-file__name">{selectedFile.name}</div>
              </div>
            )
          ) : (
            <>
              <div className="file-uploader-container__icon">
                <Icon src={FileIcon} />
              </div>
              <p className="file-name">
                Drop a file here or click to upload a file
              </p>
            </>
          )}
        </div>
      )}
      {message ? (
        <p className="file-uploader-container__message">
          <small>{message}</small>
        </p>
      ) : (
        <p className="input__error">
          <small>{error}</small>
        </p>
      )}
      {!dropZoneOnly && (
        <>
          <Animate animation="fadeInLeft" delay=".35">
            <Input
              name="title"
              forwardRef={register({
                required: true,
              })}
              error={errors.title && "Title field is required"}
              type="text"
              label="Title"
              placeholder="e.g. What is Cognitive Behavioral Therapy?"
              defaultValue={currentFile?.title}
              extraClass="file-uploader-container__title"
            />
          </Animate>
          <Animate animation="fadeInLeft" delay=".45">
            <Textarea
              name="description"
              label="Description"
              placeholder={
                "e.g CBT is an evidence-based treatment that can help people with depression, anxiety, panic attacks, hard relationships, and many other problems."
              }
              rows={5}
              maxLength={240}
              forwardRef={register({ required: false })}
              defaultValue={currentFile?.description}
            />
          </Animate>
          {showPatients && (
            <PatientsDropdown
              name="selectedPatients"
              isMulti
              label="Share with"
              includeEmail={false}
              placeholder="Choose patients"
              defaultPatient={null}
              onChange={onPatientSelect}
              allowSelectAll
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              value={selectedPatients}
            />
          )}
          <Button
            type="submit"
            name={`upload_file_button`}
            value={
              isLoading
                ? "Uploading..."
                : currentFile
                ? "Edit File"
                : "Add File"
            }
            fullWidth
            isLoading={isLoading}
            disabled={isLoading}
            extraClass="pill submit-button"
          />
        </>
      )}
    </form>
  );
};

export default FileUploader;
