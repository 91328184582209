import React from "react";
import { Button } from "components";

interface AddLibraryResourceButtonProps {
  onClick: () => void;
  title?: string;
}

const AddLibraryResourceButton = ({
  onClick,
  title,
}: AddLibraryResourceButtonProps) => {
  return (
    <Button
      name="add_library_resource_button"
      value={title || "Add Resource"}
      pill
      icon={"Link2"}
      size="small"
      onClick={onClick}
    />
  );
};

export default AddLibraryResourceButton;
