const convertSharedWithToRQLFormat = (patient) => {
  if (patient) {
    return `{ 
        patientId: "${patient?.value}"
      }`;
  }

  return;
};

export const formattedSharedWith = (selectedPatients) =>
  selectedPatients?.map(convertSharedWithToRQLFormat) || [];
