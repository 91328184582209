import { useEffect, useState } from "react";

const usePinnedFields = ({
  currentAssignment,
  currentDraft,
  currentSection,
}) => {
  const [pinnedFields, setPinnedFields] = useState([]);

  const findFieldUuid = (assignment, fields, sectionName, fieldName) => {
    const fieldId = assignment.sections
      .find((item) => item.name === sectionName)
      .fields.find((item) => item.name === fieldName).uuid;
    return fields?.find((field) => field.fieldId === fieldId)?.value;
  };

  useEffect(() => {
    if (!currentAssignment || !currentDraft || !currentSection) {
      // console.warn(
      //   "Got empty assignment or submission or section for pinned fields hook"
      // );
      setPinnedFields([]);
      return;
    }

    const responseFields = currentDraft?.responseFields;

    if (!responseFields) {
      // If found nothing, then hide pinned fields
      setPinnedFields([]);
      return;
    }

    const pinnedFieldResult = [];
    switch (currentSection?.name) {
      case "automatic-emotion": {
        const description = findFieldUuid(
          currentAssignment,
          responseFields,
          "automatic-thought",
          "description"
        );
        if (description) {
          pinnedFieldResult.push({
            title: "You mentioned the automatic thought was:",
            content: description,
          });
        }
        break;
      }
      case "working-thought": {
        const description = findFieldUuid(
          currentAssignment,
          responseFields,
          "automatic-thought",
          "description"
        );
        const evidence = findFieldUuid(
          currentAssignment,
          responseFields,
          "automatic-thought",
          "evidence"
        );
        if (description) {
          pinnedFieldResult.push({
            title: "You mentioned the automatic thought was:",
            content: description,
          });
        }
        if (evidence) {
          pinnedFieldResult.push({
            title: "You mentioned the evidence was:",
            content: evidence,
          });
        }
        break;
      }
      case "working-emotion":
      case "intended-behaviour": {
        const description = findFieldUuid(
          currentAssignment,
          responseFields,
          "working-thought",
          "description"
        );
        if (description) {
          pinnedFieldResult.push({
            title: "You mentioned the working thought was:",
            content: description,
          });
        }
        break;
      }
      case "thought-review": {
        const yourThought = findFieldUuid(
          currentAssignment,
          responseFields,
          "initial-thought",
          "your-thought"
        );
        if (yourThought) {
          pinnedFieldResult.push({
            title: "You mentioned your initial thought was:",
            content: yourThought,
          });
        }
        break;
      }
      default: {
        // nothing
      }
    }
    setPinnedFields(pinnedFieldResult);
  }, [currentAssignment, currentDraft, currentSection, setPinnedFields]);

  return {
    pinnedFields,
  };
};

export default usePinnedFields;
