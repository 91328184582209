import React from "react";
import AuthenticationHeader from "../components/AuthenticationHeader";
import { Animate, Button } from "components";

const ConfirmEmail = () => {
  return (
    <div id="signup_confirmation_page">
      <Animate animation="fadeInLeft" delay=".15">
        <AuthenticationHeader
          headline={`Congrats!`}
          subline="Your account has been created and we've sent you a verification email. 
          Please click the link in that email to continue."
        />
      </Animate>
      <Animate animation="fadeInLeft" delay=".25">
        <Button
          name="signup_confirmation_resend_button"
          value="Resend Confirmation Email"
          fullWidth
          extraClass="secondary"
        />
      </Animate>
    </div>
  );
};

export default ConfirmEmail;
