import React, { useState } from "react";
import { Field } from "types";
import moods from "components/utility/GeneratedField/components/MoodScaleField/moods";
import MoodIcon from "components/utility/GeneratedField/components/MoodScaleField/MoodIcon";

interface Props {
  field: Field;
  defaultValue: any;
  onChange: (value: any) => void;
  error?: string;
}
const MoodScaleField = ({ field, defaultValue, onChange, error }: Props) => {
  const [selectedMood, setSelectedMood] = useState(defaultValue || "");
  const handleChange = (value: any) => {
    setSelectedMood(value);
    onChange(value);
  };
  return (
    <div
      className={`mood-scale-field ${
        error ? "mood-scale-field_wrapper-error" : ""
      }`}
    >
      <label className="mood-scale-field__label">{field.label}</label>
      <div className="mood-scale-field__icon-wrapper">
        {moods.map((mood) => {
          const isSelected = mood.label === selectedMood;
          return (
            <button
              className={`mood-scale-field__icon-choice ${mood.theme} ${
                isSelected ? "selected" : ""
              }`}
              key={mood.label}
              onClick={(e) => {
                e.preventDefault();
                handleChange(mood.label);
              }}
            >
              <MoodIcon mood={mood} />
              <p className="mood-scale-field__icon-label">{mood.label}</p>
            </button>
          );
        })}
      </div>
      <select
        aria-label={field.label}
        className={`mood-scale-field__field ${error ? "error" : ""}`}
        value={selectedMood}
        onChange={(e) => onChange(e.target.value)}
        hidden
      >
        <option value=""></option>
        {moods.map((mood) => {
          return (
            <option key={mood.label} value={mood.label}>
              {mood.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default MoodScaleField;
