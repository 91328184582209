import "./PageTitle.styles.scss";
import React from "react";

interface PageTitleProps {
  text: string;
  children?: React.ReactNode;
}

const PageTitle = ({ text, children }: PageTitleProps) => {
  return (
    <div className="page-title__wrapper">
      <h3 className="page-title">{text}</h3>
      {children}
    </div>
  );
};

export default PageTitle;
