import React from "react";
import "./SimpleSelect.styles.scss";
import Select from "react-select";
interface SelectOption {
  label: string;
  value: string;
  [key: string]: any;
}
interface SimpleSelectProps {
  options: SelectOption[];
  defaultValue?: string;
  hint?: string;
  label?: string;
  onChange: (option: any) => void;
}

const SimpleSelect = ({
  options,
  defaultValue,
  onChange,
  hint,
  label,
}: SimpleSelectProps) => {
  const handleChange = (e: any) => {
    onChange(e.value);
  };
  const defaultOption = options.find((option) => option.value === defaultValue);
  return (
    <div className="select">
      <label className="select__label">{label}</label>
      <Select
        className="select__field"
        classNamePrefix="select"
        options={options}
        defaultValue={defaultOption}
        onChange={handleChange}
      />
      <p className="select__hint">
        <small>{hint}</small>
      </p>
    </div>
  );
};

export default SimpleSelect;
