import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./PatientOnboarding.styles.scss";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Button } from "components";
import { useNavigate } from "react-router-dom";

const PatientOnboarding = () => {
  const history = useNavigate();
  const handleStatsCardNavigation = () => {
    history(`/your-assignments`);
  };
  return (
    <Carousel showThumbs={false} showStatus={false} showArrows={false}>
      <div className="patient-onboarding-slide slide-1">I am one</div>
      <div className="patient-onboarding-slide slide-2">I am two</div>
      <div className="patient-onboarding-slide slide-3">
        I am three
        <Button
          onClick={handleStatsCardNavigation}
          name={`patient_onboarding_continue`}
          value="Got it, let's go!"
          extraClass="secondary"
          size="small"
        />
      </div>
    </Carousel>
  );
};

export default PatientOnboarding;
