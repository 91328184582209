import React, { useMemo } from "react";
import { AssignmentIcon } from "components";
import { toAmericanDateFromUnix, toTimeFromUnix } from "utils/helpers";
import { useTranslation } from "react-i18next";

const usePatientSubmissionsDataColumns = () => {
  const { t } = useTranslation(["common"]);
  const patientSubmissionDataColumns = useMemo(
    () => [
      {
        header: t("patient_assignments.assignment_type_label"),
        accessorKey: "assignmentLabel",
        cell: (props) => {
          return (
            <div className="flex">
              <AssignmentIcon
                style={props.row.original.assignmentLabel}
                alt={props.row.original.assignmentLabel}
                color={props.row.original.color}
                icon={props.row.original.icon?.src}
                variant="small"
              />
              {props.row.original.assignmentLabel}
            </div>
          );
        },
      },
      {
        header: t("patient_assignments.submission_date_label"),
        accessorKey: "dateField",
        cell: (props) => {
          return (
            <>
              <span>
                {toAmericanDateFromUnix(props.row.original.createdAt)} at{" "}
                {toTimeFromUnix(props.row.original.createdAt)}
              </span>
            </>
          );
        },
      },
      {
        header: t("patient_assignments.privacy_label"),
        accessorKey: "privacy",
        cell: (props) => {
          return (
            <>
              <span>
                {props.row.original.privacy
                  ? t("patient_assignments.privacy_private_label")
                  : t("patient_assignments.privacy_shared_label")}
              </span>
            </>
          );
        },
      },
    ],
    [t]
  );
  return patientSubmissionDataColumns;
};

export default usePatientSubmissionsDataColumns;
