export const addPatientAction = (patient) => ({
  type: "ADD_PATIENT",
  payload: patient,
});

export const updatePatientAction = (patient) => ({
  type: "UPDATE_PATIENT",
  payload: patient,
});

export const fetchPatientsAction = (patients) => {
  return {
    type: "FETCH_PATIENTS",
    payload: patients,
  };
};

export const removePatientAction = (patientId) => ({
  type: "REMOVE_PATIENT",
  payload: patientId,
});
