import "./TableWrapper.styles.scss";
import React from "react";

const TableWrapper = (props) => {
  return (
    <table className="data-table-wrapper" {...props}>
      {props.children}
    </table>
  );
};

export default TableWrapper;
