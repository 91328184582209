import "./AssignmentIcon.styles.scss";
import React from "react";
import { Icon } from "components";
import { ASSIGNMENT_TYPES } from "utils/constants";

interface AssignmentIconProps {
  icon: any;
  style?: string;
  variant?: string;
  color?: string;
}

const AssignmentIcon = ({
  icon,
  style,
  variant,
  color,
}: AssignmentIconProps) => {
  const backupIcon = ASSIGNMENT_TYPES.find(
    (type) => type?.type === style || type?.title === style?.toString()
  );
  return (
    <div
      className={`assignment-icon ${backupIcon?.type} ${variant}`}
      style={{ backgroundColor: `${color}` }}
    >
      <Icon src={icon || backupIcon?.icon} />
    </div>
  );
};

export default AssignmentIcon;
