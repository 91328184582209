import React, { useCallback, useState } from "react";
import { useCurrentUser, useDeviceInfo } from "utils/hooks";
import "./AppRedirectButton.styles.scss";
import { Icon } from "components";
import LogoIcon from "assets/icons/logo-icon.svg";
import Cross from "assets/icons/xmark.svg";

const APP_REDIRECT_STATUS_KEY = "app-redirect:status";

const AppRedirectButton = () => {
  const { isTherapist, isPatient } = useCurrentUser();
  const { OS } = useDeviceInfo();
  const [isBannerShown, setBannerShownStatus] = useState(
    sessionStorage.getItem(APP_REDIRECT_STATUS_KEY) !== "dismissed"
  );

  const openApplication = useCallback(() => {
    if (OS === "Android") {
      window.location.href = process.env
        .REACT_APP_NATIVE_ANDROID_APP_STORE_LINK as string;
    } else if (OS === "iOS") {
      window.location.href = process.env
        .REACT_APP_NATIVE_IOS_APP_STORE_LINK as string;
    }
  }, [OS]);

  const hideRedirect = useCallback(() => {
    // Directly update the state without startTransition
    sessionStorage.setItem(APP_REDIRECT_STATUS_KEY, "dismissed");
    setBannerShownStatus(false);
  }, []);

  if (isTherapist || !isBannerShown || (OS !== "Android" && OS !== "iOS")) {
    return null;
  }

  return (
    <>
      {isPatient && (
        <div className="app-redirect-button__overlay">
          <div className="app-redirect-button__button-wrapper">
            <div
              className="app-redirect-button__open-button"
              onClick={openApplication}
              aria-hidden="true"
            >
              <div className="app-redirect-button__open-button-icon">
                <Icon src={LogoIcon} />
              </div>
              <div className="app-redirect-button__open-button-title">
                Open App
              </div>
            </div>
            <div className="app-redirect-button__splitter" />
            <div
              className="app-redirect-button__close-button"
              onClick={hideRedirect}
              aria-hidden="true"
            >
              <Icon src={Cross} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppRedirectButton;
