import React, { useContext, useEffect, useState } from "react";
import zoomContext from "../utils/context/zoom-context";
import ZoomVideo from "@zoom/videosdk";
import MediaContext from "../utils/context/media-context";
import MeetingBox from "./MeetingBox";

const VideoCall = ({ meetingArgs }: any) => {
  const { topic, signature, name, password, roleType } = meetingArgs;

  const [loading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("");
  const [mediaStream, setMediaStream] = useState<any>();

  const client = useContext(zoomContext);

  useEffect(() => {
    const init = async () => {
      client.init("US-EN", "CDN");
      try {
        setLoadingText("Joining session...");
        if (topic) {
          await client.join(topic, signature, name, password, 60);
        }
        const stream = client.getMediaStream();
        setMediaStream(stream);
        setIsLoading(false);
      } catch (err) {
        console.log("Error joining meeting", err);
        setIsLoading(false);
      }
    };

    init();
    return () => {
      ZoomVideo.destroyClient();
    };
  }, [topic, signature, name, password, client, roleType]);

  return (
    <div>
      {loading && <p>loading call {loadingText}</p>}
      {!loading && (
        <MediaContext.Provider value={mediaStream}>
          <MeetingBox />
        </MediaContext.Provider>
      )}
      <p>hello I am video call. Topic: {topic}</p>
    </div>
  );
};

export default VideoCall;
