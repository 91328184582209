import React, { useRef, useState } from "react";
import { Field } from "types";
import { THOUGHT_DISTORTIONS } from "utils/constants";
import { Icon } from "components";
import QuoteLeft from "assets/icons/quote-left.svg";

interface Props {
  field: Field;
  defaultValue: any;
  onChange: (value: any) => void;
  error?: string;
}
const ThoughtDistortionsField = ({ field, defaultValue, onChange }: Props) => {
  const options = THOUGHT_DISTORTIONS;
  // options.unshift();
  const [value, setValue] = useState(defaultValue);
  const [showOptions, setShowOptions] = useState(false);
  const handleSelect = (value: any) => {
    setValue(value);
    setShowOptions(false);
    onChange(value);
    ``;
  };
  const handleClickEvent = (e: any) => {
    if (e.target.closest(".options-list")) {
      return;
    }

    setShowOptions(false);
    document.removeEventListener("click", handleClickEvent);
  };
  const handleShowOptions = () => {
    if (optionsRef.current) {
      const optionsRect = optionsRef.current.getBoundingClientRect();
      const inputRect = inputRef.current?.getBoundingClientRect();
      if (optionsRect.bottom > window.innerHeight) {
        optionsRef.current.style.top = `-${
          optionsRect.height + 10 + (inputRect?.height || 0)
        }px`;
      }
    }
    setShowOptions(true);
    setTimeout(() => {
      document.addEventListener("click", handleClickEvent);
    }, 500);
  };
  const optionsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <div>
        <div className="string-field-new  select-field-new">
          <label className="field__label">{field.label}</label>
          <div className={"input-wrapper"}>
            <input
              ref={inputRef}
              aria-label={field.label}
              placeholder={field.placeholder || ""}
              className={`field__field ${field.error ? "error" : ""}`}
              onFocus={handleShowOptions}
              value={value}
              onChange={() => null}
            />
            <span className={`arrow`}>
              <svg
                width="10"
                height="12"
                viewBox="0 0 20 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M18.9492 1.96875L18.1328 1.10938C17.918 0.894531 17.5742 0.894531 17.4023 1.10938L9.625 8.88672L1.80469 1.10938C1.63281 0.894531 1.28906 0.894531 1.07422 1.10938L0.257812 1.96875C0.0429688 2.14062 0.0429688 2.48438 0.257812 2.69922L9.23828 11.6797C9.45312 11.8945 9.75391 11.8945 9.96875 11.6797L18.9492 2.69922C19.1641 2.48438 19.1641 2.14062 18.9492 1.96875Z" />
              </svg>
            </span>
          </div>
          <div className="options-wrapper">
            <div
              ref={optionsRef}
              className={`options-list ${showOptions ? "" : "hidden"}`}
            >
              {options.map((option: any) => {
                return (
                  <button
                    className="thought-distortion-option"
                    key={option.label}
                    defaultValue={option.value}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSelect(option.value);
                    }}
                  >
                    <div className="thought-distortion-option__label">
                      {option.label}
                    </div>
                    <div className="thought-distortion-option__explanation">
                      {option.explanation}
                    </div>
                    <div className="thought-distortion-option__example">
                      <div className="thought-distortion-option__example--icon">
                        <Icon src={QuoteLeft} />
                      </div>
                      {option.example}
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThoughtDistortionsField;
