import React from "react";
import VideoEmbed from "components/utility/VideoEmbed";
import VideoIcon from "assets/icons/video.svg";
import { Icon } from "components";
import "./VideoField.styles.scss";
import { Field } from "types";

interface VideoFieldProps {
  uuid: string | undefined;
  label: string;
  videoURL?: string;
  validationError?: string;
  field?: Field;
}

const VideoField = ({
  label,
  videoURL,
  uuid,
  validationError,
}: VideoFieldProps) => {
  return (
    <div className="video-field">
      {label && <p className="video-field__label">{label}</p>}
      {!videoURL || videoURL === "" ? (
        <>
          <div className="video-field-empty">
            <div className="video-field-empty__icon">
              <Icon src={VideoIcon} />
            </div>
            <p className="no-mb">
              Enter the url of a video in the field settings
            </p>
          </div>
          {validationError && (
            <p className="input__error">
              <small>{validationError}</small>
            </p>
          )}
        </>
      ) : (
        <VideoEmbed videoURL={videoURL || ""} uuid={uuid} />
      )}
    </div>
  );
};

export default VideoField;
