import "./styles.scss";
import React from "react";

interface AlertBarProps {
  content: React.ReactNode;
  type?: string;
  rounded?: boolean;
  padded?: boolean;
}

const AlertBar = ({
  content,
  type = "info",
  rounded,
  padded,
}: AlertBarProps) => {
  return (
    <div
      className={`alert-bar alert-bar-${type} ${rounded ? "rounded" : ""} ${
        padded ? "padded" : ""
      }`}
    >
      {content}
    </div>
  );
};

export default AlertBar;
