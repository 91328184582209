import "./OptionsList.styles.scss";
import React, { useState, useEffect, useRef } from "react";
import { Button, IconButton } from "components";
import TrashIcon from "assets/icons/trash.svg";
import UpArrow from "assets/icons/square-up.svg";
import DownArrow from "assets/icons/square-down.svg";
import { FieldOption } from "types";
import { v4 as uuid } from "uuid";

interface OptionsListProps {
  options: Array<FieldOption>;
  onChange: (options: FieldOption[]) => void;
  forwardRef: any;
}

const OptionsList = ({ options, onChange, forwardRef }: OptionsListProps) => {
  const [newOptions, setNewOptions] = useState<Array<FieldOption>>([
    ...options,
  ]);
  const inputsRef = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    setNewOptions([...options]);
  }, [options]);

  useEffect(() => {
    inputsRef.current = inputsRef.current.slice(0, newOptions.length);
  }, [newOptions]);

  const addOption = () => {
    const firstBlankIndex = newOptions.findIndex(
      (option) => option.label.trim() === "" || option.value.trim() === ""
    );

    if (firstBlankIndex !== -1) {
      inputsRef.current[firstBlankIndex]?.focus();
    } else {
      newOptions.push({
        id: uuid(),
        label: "",
        value: "",
        order: newOptions.length,
      });
      setNewOptions(newOptions);
      onChange(newOptions);
    }
  };

  const deleteOption = (index: number) => {
    if (newOptions.length === 2) return;
    const updatedOptions = newOptions.filter((_, idx) => idx !== index);
    setNewOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const moveUp = (index: number) => {
    if (index === 0) return;
    const updatedOptions = [...newOptions];
    [updatedOptions[index], updatedOptions[index - 1]] = [
      updatedOptions[index - 1],
      updatedOptions[index],
    ];
    setNewOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const moveDown = (index: number) => {
    if (index === newOptions.length - 1) return;
    const updatedOptions = [...newOptions];
    [updatedOptions[index], updatedOptions[index + 1]] = [
      updatedOptions[index + 1],
      updatedOptions[index],
    ];
    setNewOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const handleOptionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    const updatedOptions = newOptions.map((option, idx) =>
      idx === index
        ? { ...option, label: value, value: value, order: index }
        : option
    );
    setNewOptions(updatedOptions);
    onChange(updatedOptions);
  };

  return (
    <>
      <div className="input__label">Options to Select</div>
      <p className="input__hint">
        <small>Enter the options patients can select from</small>
      </p>
      <div className="options-list">
        {options.map((option, index) => (
          <div key={option.id} className="options-list__item">
            <div className="options-list__move-controls">
              <IconButton
                icon={UpArrow}
                onClick={() => {
                  moveUp(index);
                }}
              />
              <IconButton
                icon={DownArrow}
                onClick={() => {
                  moveDown(index);
                }}
              />
            </div>
            <input
              className="input__field"
              type="text"
              placeholder={`Option ${index + 1}`}
              value={option.value}
              onChange={(e) => handleOptionChange(e, index)}
              ref={(el) => (inputsRef.current[index] = el)}
            />
            <IconButton icon={TrashIcon} onClick={() => deleteOption(index)} />
          </div>
        ))}
        <div className="options-list__add-button">
          <Button
            name={"options_list_add-button"}
            value={"Add Another"}
            onClick={addOption}
          />
        </div>
        <input ref={forwardRef} type="hidden" name="options" />
      </div>
    </>
  );
};

export default OptionsList;
