import "./UpgradeTooltip.styles.scss";
import React from "react";
import { Button, ImageSeparator } from "components";
import PartyIcon from "assets/icons/party-horn.svg";
import UpgradeSplash from "assets/images/splashes/relationshiop-counseling.png";
import ReactTooltip from "react-tooltip";
import { useCurrentUser } from "utils/hooks";

interface UpgradeTooltipProps {
  tooltipId: string;
  heading?: string;
  limitedEntity: string;
  warningLine?: string;
  valueLine?: string;
  ctaText?: string;
  offset?: any;
}

const UpgradeTooltip = ({
  tooltipId,
  offset,
  heading,
  limitedEntity,
  warningLine,
  valueLine,
  ctaText,
}: UpgradeTooltipProps) => {
  const { user } = useCurrentUser();
  const handleUpgradeButton = () => {
    window.open(
      `${process.env.REACT_APP_STRIPE_PLAN_URL}?prefilled_email=${user?.email}`,
      "_blank"
    );
  };

  return (
    <ReactTooltip
      className="upgrade-tip"
      id={tooltipId}
      place="bottom"
      type="light"
      effect="solid"
      delayHide={4000}
      clickable
      eventOff="click"
      offset={offset || { left: 80 }}
    >
      <div className="upgrade-tip__content">
        <h2>{heading || "Unlock more!"}</h2>
        <p>
          {warningLine ||
            `You've reached the limit of how many ${limitedEntity} you can add
          on your current plan.`}
        </p>
        <p>
          {valueLine ||
            `Upgrade your account to add additional ${limitedEntity} and more!`}
        </p>
        <ImageSeparator image={UpgradeSplash} alt="upgrade icon" />
        <Button
          name="upgrade_button"
          value={ctaText || "Upgrade now"}
          pill
          fullWidth
          icon={PartyIcon}
          onClick={handleUpgradeButton}
        />
      </div>
    </ReactTooltip>
  );
};

export default UpgradeTooltip;
