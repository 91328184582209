import { useLogout } from "utils/hooks";
import { toast } from "react-toastify";
import { User } from "types";

interface resendGroupInviteProps {
  groupId: string;
  member: User;
}

const useResendGroupInvite = () => {
  const { logOut } = useLogout();
  const resendGroupInvite = async ({
    groupId,
    member,
  }: resendGroupInviteProps) => {
    const { email, role } = member;

    let mutation;

    if (role === "moderator") {
      mutation = `
          mutation {
            resendTherapistGroupInvite(
              groupId: "${groupId}",
              email: "${email}",
            )
        }
      `;
    } else if (role === "member") {
      mutation = `
          mutation {
            resendPatientGroupInvite(
              groupId: "${groupId}",
              email: "${email}",
            )
        }
      `;
    } else {
      return null;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
          ${mutation}
        `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const resData = await res.json();

      if (res.ok) {
        toast("Invitation has been resent");
      }

      if (!res.ok) {
        if (resData.errors[0].message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(
            `Hmm, something went wrong. ${resData.errors[0].message}`
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    resendGroupInvite,
  };
};

export default useResendGroupInvite;
