import { useDispatch } from "react-redux";
import {
  fetchSubmissionsAction,
  fetchGroupSubmissionsAction,
} from "store/actions/submissions";
import { toast } from "react-toastify";
import { useLogout } from "utils/hooks";
import { useCallback } from "react";

const useFetchSubmissions = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();

  const fetchAllSubmissions = useCallback(() => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
              query {
                getAllSubmissionsByCreatorId {
                  uuid
                  createdAt
                  slug
                  userId
                  assignmentId
                  assignmentLabel
                  privacy
                  color
                  isDraft
                  groupId
                  icon {
                    name
                    src
                  }
                  responseFields {
                    uuid
                    submissionId
                    sectionId
                    sectionLabel
                    sectionOrder
                    fieldId
                    fieldLabel
                    fieldType
                    value
                  }
                }
              }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            dispatch(
              fetchSubmissionsAction(resData.data.getAllSubmissionsByCreatorId)
            );
            Promise.resolve(resData.data.getAllSubmissionsByCreatorId).then(
              (r) => r
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }, [dispatch, logOut]);

  const fetchGroupSubmissions = useCallback(
    (groupId?: string) => {
      if (!groupId) {
        return null;
      }
      return new Promise(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              query {
                getAllSubmissionsByGroupId(groupId: "${groupId}") {
                  uuid
                  createdAt
                  slug
                  userId
                  assignmentId
                  assignmentLabel
                  privacy
                  color
                  isDraft
                  groupId
                  userNameOrEmail
                  icon {
                    name
                    src
                  }
                  responseFields {
                    uuid
                    submissionId
                    sectionId
                    sectionLabel
                    sectionOrder
                    fieldId
                    fieldLabel
                    fieldType
                    value
                  }
                }
              }
          `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(`Hmm, something went wrong.`);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
            } else {
              dispatch(
                fetchGroupSubmissionsAction(
                  resData.data.getAllSubmissionsByGroupId
                )
              );
              Promise.resolve(resData.data.getAllSubmissionsByGroupId).then(
                (r) => r
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    [dispatch, logOut]
  );

  return {
    fetchAllSubmissions,
    fetchGroupSubmissions,
  };
};

export default useFetchSubmissions;
