import { PURGE } from "redux-persist";

const initialState = {
  onlineSessions: [],
};

export default (state = initialState, { type, payload }: any) => {
  switch (type) {
    case "CREATE_ONLINE_SESSION":
      return {
        ...state,
        onlineSessions: [...state.onlineSessions, payload],
      };
    case "JOIN_ONLINE_SESSION":
      return {
        ...state,
        notes: state.onlineSessions.map((onlineSession: any) =>
          onlineSession.channelName === payload.channelName
            ? {
                ...onlineSession,
                ...payload,
              }
            : onlineSession
        ),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
