import "./GroupMembersTableEmptyState.styles.scss";
import React from "react";
import BirdOne from "assets/images/bird-1.png";
import { useTranslation } from "react-i18next";

const GroupMembersTableEmptyState = () => {
  const { t } = useTranslation(["common"]);
  return (
    <div className="group-members-table-empty-state">
      <h3>{t(`groups.members_empty_state_header`)}</h3>
      <p>{t(`groups.members_empty_state_paragraph`)}</p>
      <div className="group-members-table-empty-state__image-holder">
        <img
          className="group-members-table-empty-state__image"
          src={BirdOne}
          alt="red bird"
        />
      </div>
    </div>
  );
};

export default GroupMembersTableEmptyState;
