import { useCallback } from "react";

const useFetchMetadata = () => {
  const fetchMetadata = useCallback(async (url: string) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_REST_API_URL}/get-metadata`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ url }),
        }
      );

      const resData = await res.json();

      if (!res.ok) {
        throw new Error(resData.message);
      }

      return resData;
    } catch (err) {
      console.error(err);
    }
  }, []);

  return { fetchMetadata };
};

export default useFetchMetadata;
