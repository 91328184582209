import { PURGE } from "redux-persist";

const initialState = {
  files: [],
  therapistFiles: [],
  patientFiles: [],
  attachedFiles: [],
  patientPublicFiles: [],
  groupFiles: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PUBLIC_FILES":
      return {
        ...state,
        files: [...payload],
      };
    case "FETCH_PATIENT_FILES":
      return {
        ...state,
        patientFiles: [...payload],
      };
    case "FETCH_THERAPIST_FILES":
      return {
        ...state,
        therapistFiles: [...payload],
      };
    case "FETCH_GROUP_FILES":
      return {
        ...state,
        groupFiles: [...payload],
      };
    case "FETCH_FILE_ATTACHMENT":
      return {
        ...state,
        attachedFiles: [...state.attachedFiles, payload],
      };
    case "ADD_THERAPIST_FILE":
      return {
        ...state,
        therapistFiles: [payload, ...state.therapistFiles],
      };
    case "ADD_GROUP_FILE":
      return {
        ...state,
        groupFiles: [payload, ...state.groupFiles],
      };
    case "UPDATE_THERAPIST_FILES":
      if (payload.length === 0) return;
      const updatedFiles = [...state.therapistFiles];
      payload.map((item) => {
        const indexToUpdate = state.therapistFiles.findIndex(
          (file) => file.uuid === item.uuid
        );
        if (indexToUpdate > 0) {
          updatedFiles.splice(indexToUpdate, 1, item);
        }
      });
      return {
        ...state,
        therapistFiles: updatedFiles,
      };
    case "UPDATE_GROUP_FILES":
      if (payload.length === 0) return;
      const updatedGroupFiles = [...state.groupFiles];
      payload.map((item) => {
        const indexToUpdate = state.groupFiles.findIndex(
          (file) => file.uuid === item.uuid
        );
        if (indexToUpdate > 0) {
          updatedGroupFiles.splice(indexToUpdate, 1, item);
        }
      });
      return {
        ...state,
        groupFiles: updatedGroupFiles,
      };
    case "DELETE_THERAPIST_FILE":
      return {
        ...state,
        therapistFiles: state.therapistFiles.filter(
          (file) => file.uuid !== payload
        ),
      };
    case "DELETE_GROUP_FILE":
      return {
        ...state,
        groupFiles: state.groupFiles.filter((file) => file.uuid !== payload),
      };
    case "FETCH_PATIENT_PUBLIC_FILES":
      return {
        ...state,
        patientPublicFiles: [...payload],
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
