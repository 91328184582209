import "./OnlineSession.styles.scss";
import React, { useState } from "react";
import { Button, Logo, PatientsDropdown } from "components";
import VideoCallGraphic from "assets/images/video-call-graphic.png";
import { useGetPatients } from "utils/hooks";
import { useParams, useNavigate } from "react-router-dom";

const OnlineSession = () => {
  const [videoCall, setVideoCall] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const { uuid } = useParams();
  const { patients } = useGetPatients({});
  const patient = patients.find(
    (patient: any) => patient?.slug === uuid && uuid
  );
  const [currentPatient, setCurrentPatient] = useState(patient || null);
  const [currentPatientSelection, setCurrentPatientSelection] = useState({
    value: patient?.uuid,
    label: `${patient?.firstName} ${patient?.lastName}`,
  });

  const startVideoSession = async () => {
    setVideoCall(true);
  };

  const navigate = useNavigate();
  const handlePatientChange = (patientId: string) => {
    const newPatient = patients.find(
      (patient: any) => patient.uuid === patientId
    );
    setCurrentPatient(newPatient);
    navigate(`/session/${newPatient?.slug}`);
  };

  const handlePatientClear = () => {
    setCurrentPatient(null);
    navigate("/session");
  };

  return (
    <div className="online-session">
      <div className="online-session_top-bar">
        <div className="online-session-top-bar_logo">
          <Logo theme={"white"} />
        </div>
      </div>
      <div className="online-session_body">
        {videoCall ? (
          <div style={{ width: "800px", height: "800px" }}>
            <p>call started</p>
          </div>
        ) : (
          <div className="online-session_pre-call-body">
            {currentPatient && (
              <h2>
                When you are ready to start your session with{" "}
                {currentPatient.firstName},<br /> click &quot;Start
                Session&quot; below
              </h2>
            )}
            <img src={VideoCallGraphic} alt="video call" />
            {!currentPatient && (
              <>
                <h3>
                  Select a patient and click &quot;Start Session&quot; to get
                  started.
                </h3>
                <p className="text">
                  You may be asked to grant microphone and camera access by your
                  browser.
                  <br /> Your patient will automatically be notified that the
                  session has begun via email
                </p>
                <PatientsDropdown
                  name="selectedPatient"
                  includeEmail={false}
                  placeholder="Choose patient"
                  onChange={(v) => {
                    handlePatientChange(v?.value);
                    setCurrentPatientSelection(v);
                  }}
                  hideSelectedOptions={false}
                  value={patient ? currentPatientSelection : null}
                />
              </>
            )}
            <div className="flex align-center justify-center">
              <Button
                onClick={() => startVideoSession()}
                name={`start_session_button`}
                value={`Start Session ${
                  currentPatient ? `with ${currentPatient.firstName}` : ""
                }`}
                extraClass="accent"
                disabled={!currentPatient}
              />
            </div>
            {currentPatient && (
              <div className="mt-2">
                <div
                  className="pseudo-link"
                  role="button"
                  tabIndex={0}
                  onClick={handlePatientClear}
                  onKeyDown={handlePatientClear}
                >
                  Choose a different patient
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OnlineSession;
