import "./Tag.styles.scss";
import React from "react";

interface TagProps {
  title: string;
  color?: string;
  type?: string;
}

const Tag = ({ title, color, type }: TagProps) => {
  return (
    <div
      className={`tag ${type}`}
      style={{ backgroundColor: `${color || "#9e9e9e"}` }}
    >
      {title}
    </div>
  );
};

export default Tag;
