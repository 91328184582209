import { Checkbox } from "components";
import React from "react";
import { useTranslation } from "react-i18next";

const PatientSubmissionPrivacySetting = ({
  onPrivacySettingChange,
  isPrivate,
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <Checkbox
      name="submissionPrivacy"
      label={t("patient_assignments.share_with_therapist_label")}
      ariaLabel={t("patient_assignments.share_with_therapist_label")}
      checked={!isPrivate}
      onChange={() => onPrivacySettingChange(!isPrivate)}
      variant="no-margin"
    />
  );
};

export default PatientSubmissionPrivacySetting;
