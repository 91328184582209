import { PURGE } from "redux-persist";

const initialState = {
  assignments: [],
  groupAssignments: [],
  unsavedReminderSettings: [],
  unsavedConfigureSettings: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PATIENT_ASSIGNMENTS":
      return {
        ...state,
        assignments: [...payload],
      };
    case "FETCH_GROUP_ASSIGNMENTS":
      return {
        ...state,
        groupAssignments: [...payload],
      };
    case "ADD_PATIENT_ASSIGNMENT":
      return {
        ...state,
        assignments: [...state.assignments, payload],
      };
    case "ADD_GROUP_ASSIGNMENT":
      return {
        ...state,
        groupAssignments: [...state.groupAssignments, payload],
      };
    case "DELETE_PATIENT_ASSIGNMENT":
      return {
        ...state,
        assignments: state.assignments.filter(
          (assignment) => assignment.uuid !== payload.uuid
        ),
      };
    case "DELETE_GROUP_ASSIGNMENT":
      return {
        ...state,
        groupAssignments: state.groupAssignments.filter(
          (assignment) => assignment.uuid !== payload.uuid
        ),
      };
    case "UPDATE_NOTIFICATION":
      return {
        ...state,
        assignments: state.assignments.map((assignment) =>
          assignment.uuid === payload.entityId
            ? {
                ...assignment,
                notification: {
                  ...payload,
                },
              }
            : assignment
        ),
      };
    case "UPDATE_GROUP_NOTIFICATION":
      return {
        ...state,
        groupAssignments: state.groupAssignments.map((assignment) =>
          assignment.uuid === payload.entityId
            ? {
                ...assignment,
                notification: {
                  ...payload,
                },
              }
            : assignment
        ),
      };
    case "UPDATE_PATIENT_ASSIGNMENT":
      const newAssignments = state.assignments.filter(
        (item) => item.uuid !== payload.uuid
      );
      return {
        ...state,
        assignments: [...newAssignments, payload],
      };
    case "UPDATE_GROUP_ASSIGNMENT":
      const newGroupAssignments = state.groupAssignments.filter(
        (item) => item.uuid !== payload.uuid
      );
      return {
        ...state,
        groupAssignments: [...newGroupAssignments, payload],
      };
    case "UPDATE_UNSAVED_REMINDER_SETTINGS":
      return {
        ...state,
        unsavedReminderSettings: [...payload],
      };
    case "UPDATE_UNSAVED_CONFIGURE_SETTINGS":
      return {
        ...state,
        unsavedConfigureSettings: payload,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
