/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./ConfettiCanvas.styles.scss";
import React from "react";
import { useSelector } from "react-redux";
import { useConfetti } from "utils/hooks";

const ConfettiCanvas = () => {
  const { triggerConfetti } = useConfetti();
  const isCanvasVisible = useSelector(
    (state) => state.uiState && state.uiState.showConfettiCanvas
  );

  return (
    <div
      className={`confetti-canvas ${
        isCanvasVisible ? "confetti-canvas-visible" : ""
      }`}
      onClick={() => triggerConfetti()}
    >
      <canvas id="confetti-canvas" />
    </div>
  );
};

export default ConfettiCanvas;
