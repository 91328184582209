import { useCurrentUser } from "utils/hooks";
import { useNavigate } from "react-router-dom";
import { useQueryParams } from "utils/hooks";

const useAuthRedirect = () => {
  const { isAuthenticated, isPatient, isSubscribed } = useCurrentUser();
  const history = useNavigate();
  const { hasUrlParams } = useQueryParams();
  const nextUrl = hasUrlParams("next");
  const qrTokenParam = hasUrlParams("qrToken");

  const onboardingWasSkipped = localStorage.getItem(
    "skipped_therapist_onboarding"
  );

  const redirect = () => {
    if (isAuthenticated) {
      if (qrTokenParam) {
        return (window.location.href = `${process.env.REACT_APP_REST_API_URL}/invite-code/scan?qrToken=${qrTokenParam}`);
      }

      if (nextUrl) {
        return history(nextUrl);
      }
      if (isPatient) {
        return history("/your-assignments");
      } else {
        if (!isSubscribed) {
          return history("/please-wait");
        }
        if (onboardingWasSkipped) {
          return history("/dashboard");
        } else {
          return history("/dashboard");
        }
      }
    }
  };

  return {
    redirect,
  };
};

export default useAuthRedirect;
