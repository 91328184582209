import React from "react";
import { flexRender } from "@tanstack/react-table";
import "./TableFooter.styles.scss";

type Props = {
  footerGroups: any;
};

const shouldHideRow = (group: any, type: string) => {
  return group.headers.every(
    (el: any) => typeof el.column.columnDef[type] === "string"
  );
};

const TableFooter = ({ footerGroups }: Props) => {
  return (
    <tfoot className="table-footer">
      {footerGroups.map((footerGroup: any) => (
        <tr
          key={footerGroup.id}
          className="table-footer-row"
          style={{
            display: shouldHideRow(footerGroup, "footer")
              ? "table-row"
              : "none",
          }}
        >
          {footerGroup.headers.map((column: any) => (
            <td
              key={column.id}
              className="table-footer-cell"
              colSpan={column.colSpan}
            >
              {column.isPlaceholder
                ? null
                : flexRender(
                    column.column.columnDef.footer,
                    column.getContext()
                  )}
            </td>
          ))}
        </tr>
      ))}
    </tfoot>
  );
};

export default TableFooter;
