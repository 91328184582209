import "./StringField.styles.scss";
import React from "react";
import { Field } from "types";

interface StringFieldProps extends Field {
  id?: string;
  forwardRef?: any;
  inputType?: string;
  readOnly?: boolean;
}

const StringField = ({
  name,
  label,
  error,
  forwardRef,
  hint,
  required,
  inputType = "text",
  ...props
}: StringFieldProps) => {
  return (
    <div className="string-field">
      <label className="string-field__label">{label}</label>
      <input
        aria-label={label}
        className={`string-field__field ${error ? "error" : ""}`}
        ref={forwardRef}
        required={required}
        name={name}
        {...props}
        type={inputType}
      />
      {error && (
        <p className="string-field__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && hint !== "null" && (
        <p className="string-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default StringField;
