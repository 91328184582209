import "./WarningIcon.styles.scss";
import React from "react";
import { Icon } from "components";
import Warning from "assets/icons/triangle-exclamation-regular.svg";

interface WarningIconProps {
  style?: string;
  variant: string;
}

const WarningIcon = ({ style, variant }: WarningIconProps) => {
  return (
    <div className={`warning-icon ${style} ${variant}`}>
      <Icon src={Warning} alt="Warning Icon" />
    </div>
  );
};

export default WarningIcon;
