import "./TableHead.styles.scss";
import React from "react";
import Sort from "assets/icons/sort.svg";
import SortUp from "assets/icons/sort-up.svg";
import SortDown from "assets/icons/sort-down.svg";
import Icon from "components/utility/Icon";
import { flexRender } from "@tanstack/react-table";

type HeaderGroup = {
  depth: number;
  headers: Array<any>;
  id: string;
};
type Props = {
  headerGroups: Array<HeaderGroup>;
};

const shouldHideRow = (group: any, type: string) => {
  return group.headers.every(
    (el: any) => typeof el.column.columnDef[type] === "string"
  );
};

const TableHead = ({ headerGroups }: Props) => {
  return (
    <thead className="data-table-head">
      {
        // Loop over the header rows
        headerGroups.map((headerGroup) => (
          // Apply the header row props
          <tr
            className="data-table-header-row"
            role="row"
            key={headerGroup.id}
            style={{
              display: shouldHideRow(headerGroup, "header")
                ? "table-row"
                : "none",
            }}
          >
            {
              // Loop over the headers in each row
              headerGroup.headers.map((column) => {
                return (
                  // Apply the header cell props
                  <th
                    className="data-table-column-header"
                    colSpan={column.colSpan}
                    key={column.id}
                    onClick={() => column.column.toggleSorting()}
                    role="columnheader"
                    title={
                      column.column.getCanSort() ? "Toggle SortBy" : undefined
                    }
                    style={{
                      cursor: column.column.getCanSort()
                        ? "pointer"
                        : "default",
                    }}
                  >
                    {column.isPlaceholder
                      ? null
                      : flexRender(
                          column.column.columnDef.header,
                          column.getContext()
                        )}
                    {column.column.getCanSort() && (
                      <span>
                        {column.column.getIsSorted() ? (
                          column.column.getIsSorted() !== "asc" ? (
                            <div className="data-column-sort-icon">
                              <Icon src={SortDown} />
                            </div>
                          ) : (
                            <div className="data-column-sort-icon">
                              <Icon src={SortUp} />
                            </div>
                          )
                        ) : (
                          <div className="data-column-sort-icon data-header-faded">
                            <Icon src={Sort} />
                          </div>
                        )}
                      </span>
                    )}
                  </th>
                );
              })
            }
          </tr>
        ))
      }
    </thead>
  );
};

export default TableHead;
