/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from "react";
import { useGetTherapists, useFetchTherapists } from "utils/hooks";
import { TopNav, PageTitle } from "components";
import TherapistProfileForPatientsEmptyState from "./components/TherapistProfileForPatientsEmptyState";
import TherapistProfileForPatientsCard from "./components/TherapistProfileForPatientsCard";
import TherapistPendingInviteForPatient from "./components/TherapistPendingInviteForPatient";
import { useTranslation } from "react-i18next";

const TherapistProfileForPatientsPage = () => {
  const { t } = useTranslation(["common"]);
  const { fetchAllTherapists } = useFetchTherapists();
  const { therapists } = useGetTherapists({ email: null });
  const pageTitle = `${t("patient_therapist_page.your_therapist_label")}${
    therapists && therapists.length > 1 ? "s" : ""
  }`;

  useEffect(() => {
    fetchAllTherapists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectedTherapists = therapists.filter(
    (therapist: { connectionStatus: string }) =>
      therapist.connectionStatus === "connected"
  );
  const pendingTherapists = therapists.filter(
    (therapist: { connectionStatus: string }) =>
      therapist.connectionStatus === "pending"
  );
  return (
    <>
      <TopNav topL={<PageTitle text={pageTitle} />} />
      <div id="therapist-profile-for-patients-page">
        {connectedTherapists?.length === 0 &&
          pendingTherapists?.length === 0 && (
            <TherapistProfileForPatientsEmptyState />
          )}
        {connectedTherapists.length > 0 && (
          <>
            {connectedTherapists.map(
              (therapist: any, i: React.Key | null | undefined) => {
                return (
                  <TherapistProfileForPatientsCard
                    therapist={therapist}
                    key={i}
                  />
                );
              }
            )}
          </>
        )}
        {pendingTherapists?.length > 0 && (
          <TherapistPendingInviteForPatient
            pendingTherapists={pendingTherapists}
          />
        )}
      </div>
    </>
  );
};

export default TherapistProfileForPatientsPage;
