import "./GroupMembersDataCards.styles.scss";
import React from "react";
import { Icon } from "components";
import { User } from "types";
import GroupMembersTableEmptyState from "../GroupMembersTableEmptyState/GroupMembersTableEmptyState";
import { useCurrentUser } from "utils/hooks";
import GroupMemberButtons from "../GroupMemberButtons/GroupMemberButtons";

import UserIcon from "assets/icons/user.svg";
import EmailIcon from "assets/icons/envelope.svg";
import StatusIcon from "assets/icons/handshake-alt.svg";
import RoleIcon from "assets/icons/id-card.svg";

interface GroupMembersDataCardProps {
  groupMember: User;
  groupId: string;
}

const GroupMembersDataCard = ({
  groupMember,
  groupId,
}: GroupMembersDataCardProps) => {
  const { user } = useCurrentUser();
  const { firstName, lastName, email, status, role } = groupMember;

  const isCurrentTherapist = email === user?.email;

  return (
    <div className="group-member-data-card">
      <div className="group-member-data-card__top">
        <p className="group-member-data-card__name">
          <span className="group-member-data-card__icon">
            <Icon src={UserIcon} />
          </span>{" "}
          <span>
            {firstName} {lastName} {isCurrentTherapist && <>(You)</>}
          </span>
        </p>
        <GroupMemberButtons member={groupMember} groupId={groupId} />
      </div>
      <div className="group-member-data-card__info">
        <p className="group-member-data-card__info-item">
          <span className="group-member-data-card__icon">
            <Icon src={EmailIcon} />
          </span>{" "}
          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
            {email}
          </a>
        </p>
        <p className="group-member-data-card__info-item">
          <span className="group-member-data-card__icon">
            <Icon src={StatusIcon} />
          </span>{" "}
          {status}
        </p>
        <p className="group-member-data-card__info-item">
          <span className="group-member-data-card__icon">
            <Icon src={RoleIcon} />
          </span>{" "}
          {role}
        </p>
      </div>
    </div>
  );
};

interface GroupMembersDataCardsProps {
  groupMembers: User[];
  groupId: string;
}
const GroupMembersDataCards = ({
  groupMembers,
  groupId,
}: GroupMembersDataCardsProps) => {
  return (
    <>
      {groupMembers.length ? (
        <>
          {groupMembers.map((groupMember, i) => {
            return (
              <GroupMembersDataCard
                groupMember={groupMember}
                groupId={groupId}
                key={i}
              />
            );
          })}
        </>
      ) : (
        <GroupMembersTableEmptyState />
      )}
    </>
  );
};

export default GroupMembersDataCards;
