import { useState } from "react";
import ReactGA from "react-ga4";
import { addPatientAction } from "store/actions/patients";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useFetchPatients, useLogout, useCurrentUser } from "utils/hooks";
import { useNavigate } from "react-router-dom";

export interface AddPatientArgs {
  first_name: string;
  last_name: string;
  email: string;
  dob: string;
  gender?: {
    value: string;
  };
}

const useAddPatient = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
  const [newPatient, setNewPatient] = useState<any>(null);
  const dispatch = useDispatch();
  const history = useNavigate();
  const { logOut } = useLogout();
  const { fetchAllPatients } = useFetchPatients();
  const { user } = useCurrentUser();

  const submitAddPatient = (
    args: AddPatientArgs | null
  ): Promise<boolean | void> => {
    if (!args) {
      return Promise.resolve();
    }

    setIsSubmitting(true);

    return new Promise((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                addPatient(patient: {
                  firstName: "${args.first_name}",
                  lastName: "${args.last_name}",
                  email: "${args.email}",
                  dob: "${args.dob}",
                  gender: "${args.gender?.value}",
                }) {
                  uuid
                  email
                  firstName
                  lastName
                  slug
                  createdAt
                  profile {
                    dob
                    gender
                    status
                  }
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn("For security purposes please log in again.");
                logOut();
              }
              if (error.errors[0].message.includes("PATIENT_LIMIT_REACHED")) {
                toast.warn("Patient limit reached, please upgrade");
                setIsSubmitSuccessful(true);
                resolve();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              fetchAllPatients();
              toast("Patient Added");
              setNewPatient(resData.data.addPatient);
              setIsSubmitSuccessful(true);
              resolve();
              ReactGA.event("patient_added_success", {
                user_id: user?.id,
                patient_id: resData.data.addPatient.uuid,
              });
              dispatch(addPatientAction(resData.data.addPatient));
              return history(
                `/patients/${resData.data.addPatient.slug}/workbook`
              );
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }, 100);
    });
  };

  return {
    submitAddPatient,
    isSubmitting,
    isSubmitSuccessful,
    newPatient,
  };
};

export default useAddPatient;
