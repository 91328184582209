import "./TableBody.styles.scss";
import React, { useState, useEffect, useRef } from "react";
import { flexRender } from "@tanstack/react-table";

// interface Row
type Props = {
  page: Array<any>;
  rowProps?: (row: any, rowRef: any) => void | undefined;
  cellProps?: ((cell: any, cellRef: any) => void | undefined) | undefined;
};

const TableBody = ({ page, rowProps, cellProps, ...props }: Props) => {
  const rowRefs = useRef<any>([]);
  const cellRefs = useRef<any>([]);
  const [currentRowRefs, setCurrentRowRefs] = useState([]);

  useEffect(() => {
    setCurrentRowRefs(rowRefs.current);
  }, [rowRefs, currentRowRefs]);
  return (
    <tbody {...props}>
      {
        // Loop over the table rows
        page.map((row, i) => {
          rowProps && rowProps(row, currentRowRefs[i]);
          return (
            // Apply the row props
            <tr
              className="data-table-row"
              role="row"
              ref={(ref) => (rowRefs.current[i] = ref)}
              id={`rowIndex-${i}`}
              key={`rowIndex-${i}`}
            >
              {
                // Loop over the rows cells
                row.getVisibleCells().map((cell: any, ind: number) => {
                  cellProps && cellProps(cell, cellRefs.current[`${i}-${ind}`]);
                  // Apply the cell props
                  return (
                    <td
                      key={ind}
                      className="data-table-cell"
                      ref={(ref) => (cellRefs.current[`${i}-${ind}`] = ref)}
                    >
                      {
                        // Render the cell contents
                        flexRender(
                          cell.column?.columnDef?.cell,
                          cell.getContext()
                        )
                      }
                    </td>
                  );
                })
              }
            </tr>
          );
        })
      }
    </tbody>
  );
};

export default TableBody;
