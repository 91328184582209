import { PURGE } from "redux-persist";
import { InviteQRCode } from "types";

const initialState = {
  inviteCodes: [],
  inviteCodeInfo: null,
};

export default (state = initialState, { type, payload }: any) => {
  switch (type) {
    case "FETCH_INVITE_CODES":
      return {
        ...state,
        inviteCodes: [...payload],
      };
    case "FETCH_INVITE_CODE_INFO":
      return {
        ...state,
        inviteCodeInfo: payload,
      };
    case "CREATE_INVITE_CODE":
      return {
        ...state,
        inviteCodes: [...state.inviteCodes, payload],
      };
    case "DELETE_INVITE_CODE":
      return {
        ...state,
        inviteCodes: state.inviteCodes.filter(
          (inviteCode: InviteQRCode) => inviteCode.uuid !== payload
        ),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
