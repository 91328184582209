import React from "react";
import { DataTable } from "components";
import useGroupMembersDataColumns from "./utils/useGroupMembersDataColumns";
import { useViewport } from "utils/hooks";
import { User } from "types";
import GroupMembersDataCards from "../GroupMembersDataCards/GroupMembersDataCards";

interface GroupMembersTableProps {
  groupId: string;
  data: User[];
}

const GroupMembersTable = ({ data, groupId }: GroupMembersTableProps) => {
  const groupMembersDataColumns = useGroupMembersDataColumns(
    data.length,
    groupId
  );
  const { isDesktop } = useViewport();

  return (
    <>
      {isDesktop ? (
        <DataTable
          data={data}
          columns={groupMembersDataColumns}
          dataType="groupMembers"
          id="group-members-table"
        />
      ) : (
        <GroupMembersDataCards groupMembers={data} groupId={groupId} />
      )}
    </>
  );
};

export default GroupMembersTable;
