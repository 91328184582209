import "./FileCardCondensed.styles.scss";
import React from "react";
import ClampLines from "react-clamp-lines";
import ReactTooltip from "react-tooltip";
import CheckIcon from "assets/icons/check.svg";
import { getFileTypeIconFromUrl } from "pages/AppPages/TherapistPages/Resources/components/Files/utils";
import { Icon } from "components";
import { openInNewTab } from "pages/AppPages/Library/utils";

interface FileCardCondensedProps {
  isSelected?: boolean;
  file: any;
  onCardClick?: (f?: any) => void;
  downloadOnClick?: boolean;
}

const FileCardCondensed = ({
  isSelected,
  file,
  onCardClick,
  downloadOnClick,
}: FileCardCondensedProps) => {
  if (!file || !file.title) {
    return null;
  }

  const onFileClick = (url: string) => {
    return openInNewTab(url);
  };

  return (
    <div
      className={`file-card-condensed ${
        isSelected ? "file-card__selected" : ""
      }`}
      key={file.uuid}
      title={file.description}
      data-tip
      data-for={`${file.uuid}-tip`}
      onClick={() =>
        downloadOnClick
          ? onFileClick(file.fileUrl)
          : onCardClick && onCardClick(file)
      }
      onKeyDown={() =>
        downloadOnClick
          ? onFileClick(file.fileUrl)
          : onCardClick && onCardClick(file)
      }
      role="button"
      tabIndex={0}
    >
      <div className="file-card-condensed__file-icon">
        <Icon
          src={isSelected ? CheckIcon : getFileTypeIconFromUrl(file.fileUrl)}
        />
      </div>
      <div className="file-card-condensed__title ">
        <ClampLines
          text={file?.title}
          id="file-clamped-title"
          lines={1}
          ellipsis="..."
          className="file-title"
          innerElement="h4"
          buttons={false}
        />
      </div>
      {file.description !== "" ? (
        <ReactTooltip id={`${file.uuid}-tip`} place="left">
          {file.description}
        </ReactTooltip>
      ) : undefined}
    </div>
  );
};

export default FileCardCondensed;
