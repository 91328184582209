import "./Collapse.styles.scss";
import React from "react";
import { Icon } from "components";
import AngleLeftThin from "assets/icons/arrow-left-thin.svg";
import AngleDownThin from "assets/icons/arrow-down-thin.svg";

interface CollapseProps {
  title: string;
  content: any;
  collapsed: boolean;
  onCollapsed: (value: boolean) => void;
}

const Collapse = ({
  title,
  content,
  collapsed = true,
  onCollapsed,
}: CollapseProps) => {
  /** @type {CollapseContentElement[]} */
  const contentList = Array.isArray(content) ? content : [content];

  return (
    <div className="collapse">
      <div>
        <div
          className="collapse-icon"
          onClick={() => !!onCollapsed && onCollapsed(!collapsed)}
          onKeyDown={() => !!onCollapsed && onCollapsed(!collapsed)}
          role="button"
          tabIndex={0}
        >
          <Icon src={collapsed ? AngleLeftThin : AngleDownThin} />
        </div>
        {collapsed && (
          <div className="collapse-collapsed">
            <div className="collapse-title">{title}</div>
          </div>
        )}
        {!collapsed && (
          <div className="collapse-expanded">
            {contentList.map((contentElement, index) => (
              <div key={index}>
                <div className="collapse-title">{contentElement.title}</div>
                <div className="collapse-content">{contentElement.content}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Collapse;
