import React from "react";
import { Input, Select } from "components";
import { credentialOptions, stateOptions } from "./options";
import { useGetTherapistProfile } from "utils/hooks";
import { useTranslation } from "react-i18next";

interface TherapistProfileProps {
  register: any;
  control: any;
}

const TherapistProfile = ({ register, control }: TherapistProfileProps) => {
  const { therapistProfile } = useGetTherapistProfile();
  const { t } = useTranslation(["common"]);

  return (
    <>
      <Select
        name="credentials"
        label={t("therapist_profile.credentials_label")}
        forwardRef={register()}
        options={credentialOptions}
        isMulti
        forwardControl={control}
        defaultValue={
          therapistProfile &&
          therapistProfile.profileCredentials &&
          therapistProfile.profileCredentials[0]
            ? therapistProfile.profileCredentials[0]
                ?.split(",")
                .map((cred: string) => {
                  if (cred !== "null") {
                    return {
                      label: cred,
                      value: cred,
                    };
                  }

                  return null;
                })
            : null
        }
        onChange={() => console.log("updated")}
      />
      <Input
        name="licenseNumber"
        label={t("therapist_profile.license_number_label")}
        forwardRef={register()}
        defaultValue={
          therapistProfile ? therapistProfile.profileLicenseNumber : null
        }
      />
      <Select
        name="licensingState"
        label={t("therapist_profile.licensing_state_label")}
        forwardRef={register()}
        forwardControl={control}
        options={stateOptions}
        defaultValue={
          therapistProfile
            ? {
                label: therapistProfile.profileLicensingState,
                value: therapistProfile.profileLicensingState,
              }
            : null
        }
        onChange={() => console.log("updated")}
      />
    </>
  );
};

export default TherapistProfile;
