import "./PageLoading.styles.scss";
import Loader from "assets/icons/logo-icon.svg";
import { Icon } from "components";
import React from "react";

const PageLoading = () => {
  return (
    <div className="page-loading__wrapper">
      <div className="page-loading__loader">
        <Icon src={Loader} />
      </div>
      <div className="page-loading__text">Loading...</div>
    </div>
  );
};

export default PageLoading;
