import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Toggle,
  TimeField,
  DaysOfWeekField,
  Input,
  IconButton,
} from "components";
import { useCurrentUser, useUpdatePatientReminderSettings } from "utils/hooks";
import { getDefaultNotificationTime } from "utils/helpers";
import TrashIcon from "assets/icons/trash.svg";
import "./PatientReminderSettings.styles.scss";

type Error = {
  notificationTime?: (string | null)[];
  notificationMessage?: string;
};

type ReminderValue = {
  field: "notificationTime" | "notificationMessage";
  value: Date | string;
  index?: number;
};

const PatientReminderSettings = () => {
  const { user } = useCurrentUser();
  const { t } = useTranslation(["common"]);
  const { updatePatientReminderSettings, isLoading } =
    useUpdatePatientReminderSettings();

  const {
    defaultReminder,
    notificationTimes,
    daysOfWeek,
    notificationMessage,
  } = user ?? {};

  const [currentDefaultReminder, setCurrentDefaultReminder] =
    useState(defaultReminder);

  const [currentNotificationTimes, setCurrentNotificationTimes] = useState<
    Date[]
  >(
    notificationTimes
      ? notificationTimes.map((time: string) => new Date(time))
      : [getDefaultNotificationTime()]
  );
  const [currentDaysOfWeek, setCurrentDaysOfWeek] = useState(
    daysOfWeek || [0, 1, 2, 3, 4, 5, 6]
  );
  const [currentNotificationMessage, setCurrentNotificationMessage] = useState(
    notificationMessage ||
      "Don't stop when you're tired, stop when you're done."
  );

  const [errors, setErrors] = useState<Error>({});

  const handleDefaultReminderChange = () => {
    setCurrentDefaultReminder(!currentDefaultReminder);
  };

  const handleValueChange = ({ index, field, value }: ReminderValue) => {
    if (field === "notificationTime") {
      const newTimes = [...currentNotificationTimes];
      newTimes[index as number] = value as Date;
      setCurrentNotificationTimes(newTimes);

      if (errors.notificationTime) {
        const newTimeErrors = [...errors.notificationTime];
        newTimeErrors[index as number] = null;
        setErrors({ ...errors, notificationTime: newTimeErrors });
      }
    } else if (field === "notificationMessage") {
      setCurrentNotificationMessage(value as string);

      if (errors.notificationMessage) {
        setErrors({ ...errors, notificationMessage: "" });
      }
    }
  };

  const validateFields = () => {
    const newErrors: Error = {};
    if (currentDefaultReminder) {
      const timeErrors = currentNotificationTimes.map((time) => {
        if (!time) {
          return "Notification time is required";
        }
        return null;
      });
      if (timeErrors.some((error) => error !== null)) {
        newErrors.notificationTime = timeErrors;
      }
      if (!currentNotificationMessage) {
        newErrors.notificationMessage = "Notification message is required.";
      }
    }
    return newErrors;
  };

  const addNotificationTime = () => {
    const newTimes = [
      ...currentNotificationTimes,
      getDefaultNotificationTime(),
    ];
    setCurrentNotificationTimes(newTimes);
  };

  const removeNotificationTime = (index: number) => {
    const newTimes = currentNotificationTimes.filter((_, i) => i !== index);
    setCurrentNotificationTimes(newTimes);

    if (errors.notificationTime) {
      const newTimeErrors = errors.notificationTime.filter(
        (_, i) => i !== index
      );
      setErrors({ ...errors, notificationTime: newTimeErrors });
    }
  };

  const handleSubmit = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      updatePatientReminderSettings({
        defaultReminder: currentDefaultReminder,
        notificationTimes: currentNotificationTimes,
        daysOfWeek: currentDaysOfWeek,
        notificationMessage: currentNotificationMessage,
      });
    }
  };

  return (
    <section className="settings-section" id="reminder-settings">
      <h2 className="section-title">
        {t("patient_reminder_settings.reminder_settings_label")}
      </h2>
      <hr className="divider" />
      <div className="settings-section__content">
        <h4 className="no-mt">
          {t("patient_reminder_settings.set_default_reminder_times_toggle")}
        </h4>
        <Toggle
          name="defaultReminder"
          id="defaultReminder"
          checked={currentDefaultReminder ? true : false}
          onChange={handleDefaultReminderChange}
          onText="Yes"
          offText="No"
          isSmall={false}
          extraClass="settings-section__toggle"
        />
        {currentDefaultReminder && (
          <>
            {currentNotificationTimes.map((time, index) => (
              <div key={index} className="time-field-wrapper">
                <TimeField
                  name={`patient_notification_time_${index}`}
                  label={index === 0 ? "Time" : null}
                  placeholder="Daily Reminder Time"
                  selected={time}
                  onChange={(value) =>
                    handleValueChange({
                      field: "notificationTime",
                      value,
                      index,
                    })
                  }
                  error={errors.notificationTime?.[index]}
                  extraClass="patient-reminder-time-field"
                />
                {index > 0 && (
                  <IconButton
                    icon={TrashIcon}
                    onClick={() => removeNotificationTime(index)}
                  />
                )}
              </div>
            ))}
            <button
              className="settings-section__add-reminder-btn"
              onClick={addNotificationTime}
            >
              {t("patient_reminder_settings.add_another_daily_reminder_btn")}
            </button>
            <DaysOfWeekField
              name="patient_days_of_week"
              label="Every"
              placeholder="Daily Reminder Time"
              defaultValue={currentDaysOfWeek}
              onChange={(value) => setCurrentDaysOfWeek(value)}
              mustHaveOneOrMore
            />
            <Input
              extraClass="light"
              name="patient_notification_message"
              type="text"
              label="Reminder Message"
              defaultValue={currentNotificationMessage}
              placeholder="E.g. Don't forget to fill in your thought record! You got this!"
              onChange={(e) =>
                handleValueChange({
                  field: "notificationMessage",
                  value: e.target.value,
                })
              }
              error={errors.notificationMessage}
            />
          </>
        )}
        <Button
          name="reminder_settings_button"
          value={t("common_labels.submit_changes")}
          extraClass="settings-section__save-reminder-btn"
          onClick={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </section>
  );
};

export default PatientReminderSettings;
