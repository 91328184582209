import { TabItem } from "components/elements/Tabs/Tabs";
import { useState } from "react";

const useAssignmentDetailsTabs = () => {
  const assignmentDetailTabs: TabItem[] = [
    {
      name: "Schedule",
      key: "schedule",
    },
    {
      name: "Configure",
      key: "configure",
    },
    {
      name: "Preview",
      key: "preview",
    },
  ];
  const [activeTab, setActiveTab] = useState(assignmentDetailTabs[1]);

  const handleTabChange = (tab: TabItem) => {
    setActiveTab(tab);
  };

  return {
    assignmentDetailTabs,
    activeTab,
    handleTabChange,
  };
};

export default useAssignmentDetailsTabs;
