import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Animate, Button, Input, Select, IconButton } from "components";
import {
  useAddUsersToGroup,
  useFetchGroupUsers,
  useFetchGroups,
} from "utils/hooks";
import { pluralize } from "utils/helpers";
import { Group } from "types";
import TrashIcon from "assets/icons/trash.svg";
import "./AddMemberForm.styles.scss";
import { v4 as uuid } from "uuid";
interface AddMemberFormProps {
  group: Group;
  onSuccess: () => void;
}

interface Member {
  email: string;
  role: { value: string; label: string } | null;
  error: string;
  id: string;
}
interface ApiError {
  email: string;
  message: string;
}
interface AddUsersToGroupResponse {
  success: boolean;
  addedEmails: string[];
  errors: ApiError[];
}

const AddMemberForm = ({ group, onSuccess }: AddMemberFormProps) => {
  const { register, handleSubmit, formState, control, errors } = useForm();
  const { isSubmitting } = formState;
  const { addUsersToGroup } = useAddUsersToGroup(group.uuid);
  const { fetchGroupUsers } = useFetchGroupUsers();
  const { fetchGroups } = useFetchGroups();

  const [members, setMembers] = useState<Member[]>([
    { email: "", role: null, error: "", id: uuid() },
  ]);

  const handleAddAnotherMember = () => {
    setMembers([
      ...members,
      {
        email: "",
        role: null,
        error: "",
        id: uuid(),
      },
    ]);
  };

  const handleRemoveAnotherMember = (id: string) => {
    const updatedMembers = members.filter((member) => member.id !== id);
    setMembers(updatedMembers);
  };

  const roles = [
    { value: "moderator", label: "Moderator" },
    { value: "member", label: "Member" },
  ];

  const onSubmit = async (data: { members: Member[] }) => {
    const {
      success,
      addedEmails,
      errors: apiErrors,
    }: AddUsersToGroupResponse = await addUsersToGroup(data);

    if (success) {
      toast(`${pluralize(members.length, "Member")} Added`);
      fetchGroups();
      fetchGroupUsers(group.uuid);
      onSuccess();
    } else if (addedEmails && addedEmails.length > 0) {
      fetchGroups();
      fetchGroupUsers(group.uuid);
      toast.warn(
        `Only the following ${pluralize(
          addedEmails.length,
          "member was",
          "members were"
        )} added: ${addedEmails.join(", ")}`
      );
    }

    if (apiErrors && apiErrors.length > 0) {
      const updatedMembers = data.members.map((member) => {
        const apiError = apiErrors.find(
          (error) => error.email === member.email
        );

        return apiError
          ? {
              ...member,
              error: apiError.message,
              id: uuid(),
            }
          : member;
      });

      setMembers(updatedMembers);
    }
  };

  return (
    <div id="add_member_form">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {members.map((member, i) => (
          <div key={member.id}>
            <Animate
              animation="fadeInLeft"
              delay=".15"
              style={{ paddingTop: "1em" }}
            >
              <div className="add-member-form-email-field">
                <Input
                  name={`members[${i}].email`}
                  type="email"
                  label="Email"
                  placeholder="name@email.com"
                  forwardRef={register({ required: "Email is required" })}
                  error={errors.members?.[i]?.email?.message || member.error}
                  defaultValue={member.email}
                />
                {i > 0 && (
                  <IconButton
                    icon={TrashIcon}
                    onClick={() => handleRemoveAnotherMember(member.id)}
                  />
                )}
              </div>
            </Animate>
            <Animate
              animation="fadeInLeft"
              delay=".25"
              style={{ position: "relative" }}
            >
              <Select
                name={`members[${i}].role`}
                label="Role"
                options={roles}
                rules={{ required: "Role is required" }}
                forwardControl={control}
                error={errors.members?.[i]?.role?.message}
                defaultValue={member.role}
              />
            </Animate>
          </div>
        ))}
        <button
          type="button"
          className="add-another-member-btn"
          onClick={handleAddAnotherMember}
        >
          Add another member
        </button>
        <Button
          type="submit"
          name="add_member_button"
          value={`Add ${pluralize(members.length, "member")}`}
          fullWidth
          isLoading={isSubmitting}
        />
      </form>
    </div>
  );
};

export default AddMemberForm;
