import React from "react";
import "./HintAndError.styles.scss";
import { useTranslation } from "react-i18next";

interface Props {
  error?: boolean;
  hint?: string;
}
const HintAndError = ({ error, hint }: Props) => {
  const { t } = useTranslation(["common"]);
  return (
    <div>
      {error && (
        <p className="field__error">
          <small>{t("patient_assignments.field_required_error")}</small>
        </p>
      )}
      {!error && hint && (
        <p className="field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default HintAndError;
