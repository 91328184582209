import "./TherapistProfileForPatientsEmptyState.styles.scss";
import React from "react";
import BirdTwo from "assets/images/bird-2.png";
import { BRAND_NAME } from "utils/constants";

const TherapistProfileForPatientsEmptyState = () => {
  return (
    <div className="therapist-profile-for-patients-empty-state">
      <h3>You&apos;re not connected to a therapist yet.</h3>
      <p>
        {BRAND_NAME} works best with a therapist. We recommend inviting yours!
      </p>
      <div className="therapist-profile-for-patients-empty-state__image-holder">
        <img
          className="therapist-profile-for-patients-empty-state__image"
          src={BirdTwo}
          alt="red bird"
        />
      </div>
    </div>
  );
};

export default TherapistProfileForPatientsEmptyState;
