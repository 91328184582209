import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { ASSIGNMENT_STATUSES } from "utils/constants";
import {
  useCurrentUser,
  useGetAssignments,
  useGetSubmissions,
} from "utils/hooks";

const AssignmentContext = React.createContext();

const useAssignmentContext = () => {
  // Current user and their assignments
  const { user } = useCurrentUser();
  const { assignments: allAssignments } = useGetAssignments({
    patientId: user?.id,
  });

  // Only handle visible assignments
  const assignments = useMemo(
    () =>
      allAssignments?.filter(
        (assignment) => assignment.status === ASSIGNMENT_STATUSES.VISIBLE
      ) || [],
    [allAssignments]
  );

  // Exclude Safety Plan from Assignments Menu
  const visibleAssignments =
    assignments?.filter((assignment) => assignment.type !== "SAFETY_PLAN") ||
    [];

  // Modify the UI if it is the Safety Plan
  const [isSafetyPlan, setIsSafetyPlan] = useState();
  const visibleSafetyPlan =
    assignments?.filter((assignment) => assignment.type === "SAFETY_PLAN") ||
    [];

  // Handle the current assignment
  const [assignment, setAssignment] = useState(null);

  // Handle assignment sections
  const [sections, setSections] = useState();

  // Handle the current section
  const [section, setSection] = useState(assignment?.sections[0]);

  // Set the current assignment and section based on URL
  const location = useLocation();
  const urlPaths = location.pathname.split("/");

  // Set the currently highlighted column
  const [currentColumn, setCurrentColumn] = useState();

  useEffect(() => {
    if (urlPaths) {
      const assignmentFromUrl =
        assignments?.filter((assignment) => assignment.slug === urlPaths[2]) ||
        [];

      const sectionFromUrl =
        assignmentFromUrl &&
        assignmentFromUrl[0]?.sections.filter(
          (section) => section.slug === urlPaths[3]
        );

      const isAssignmentFromUrlSafetyPlan =
        assignmentFromUrl && assignmentFromUrl[0]?.type === "SAFETY_PLAN";

      if (isAssignmentFromUrlSafetyPlan) {
        setIsSafetyPlan(true);
      }

      if (!isAssignmentFromUrlSafetyPlan) {
        setIsSafetyPlan(false);
      }

      if (assignmentFromUrl && assignmentFromUrl[0] !== assignment) {
        setAssignment(assignmentFromUrl && assignmentFromUrl[0]);
        setSection(sectionFromUrl && sectionFromUrl[0]);
        setSections(
          assignmentFromUrl &&
            assignmentFromUrl[0]?.sections
              .slice()
              .sort((a, b) => a.order - b.order)
        );
      }

      if (urlPaths.length === 2) {
        setCurrentColumn("assignments-focused");
      }
      if (
        urlPaths.length === 3 &&
        assignmentFromUrl &&
        assignmentFromUrl[0]?.sections.length > 1
      ) {
        setCurrentColumn("sections-focused");
      }
      if (
        urlPaths.length === 4 ||
        (urlPaths.length === 3 &&
          assignmentFromUrl &&
          assignmentFromUrl[0]?.sections.length === 1)
      ) {
        setCurrentColumn("fields-focused");
      }
    }
  }, [assignment, assignments, location, urlPaths]);

  // Submission for Assignment if there is one
  // Useful for editing an assignment

  const { submissions } = useGetSubmissions({ patientId: user?.id });

  const assignmentSubmission =
    submissions
      .filter((submission) => submission.assignmentId === assignment?.uuid)
      .slice(-1)[0] || [];

  // Starting a sort of edit mode
  const editModeOn =
    urlPaths &&
    urlPaths[urlPaths.length - 1] === "edit" &&
    assignment?.type === "SAFETY_PLAN";
  const [isEditMode, setIsEditMode] = useState(editModeOn);

  useEffect(() => {
    if (editModeOn) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [editModeOn]);

  const defaultValues = {
    assignments,
    currentAssignment: assignment,
    setAssignment,
    currentSection: section,
    setSection,
    sections,
    setSections,
    currentColumn,
    setCurrentColumn,
    visibleAssignments,
    visibleSafetyPlan,
    isSafetyPlan,
    setIsSafetyPlan,
    assignmentSubmission,
    isEditMode,
  };

  return {
    defaultValues,
  };
};

export { AssignmentContext, useAssignmentContext };
