import { useEffect } from "react";
import { useSelector } from "react-redux";
import useFetchFiles from "./useFetchFiles";

const useGetPublicFiles = () => {
  const { fetchPublicFiles } = useFetchFiles();

  const files = useSelector((state) => state.files && state.files);

  useEffect(() => {
    if (files?.files?.length === 0) {
      fetchPublicFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    publicFiles: files.files,
  };
};

export default useGetPublicFiles;
