import "./PatientOptions.styles.scss";
import { Avatar } from "components";
import { components } from "react-select";
import React from "react";

export const PatientOption = ({ showAvatar = false, ...props }) => {
  const { label, isSelected, isMulti } = props;
  return (
    <components.Option {...props}>
      <div className="patient-option">
        {isMulti && (
          <input type="checkbox" checked={isSelected} onChange={() => null} />
        )}{" "}
        {showAvatar && (
          <div className="patient-avatar-wrapper">
            <Avatar fullName={label} />
          </div>
        )}
        <p className="patient-option-label">{label}</p>
      </div>
    </components.Option>
  );
};

export const allPatientsOption = {
  label: "Select all patients",
  value: "*",
};

export const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some((val) => val.value === allPatientsOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

export const MultiValue = (props) => {
  let labelToBeDisplayed = `${props.data.label}`;
  if (props.data.value === allPatientsOption.value) {
    labelToBeDisplayed = "All patients";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};
