import React from "react";
import "./ImageDisplay.styles.scss";

type Props = {
  image: string;
  type?: "thumb" | "preview";
};
const ImageDisplay = ({ image, type = "preview" }: Props) => {
  return (
    <div className={`attached-image-display ${type}`}>
      <img className={`attached-image ${type}`} src={image} alt={image} />
    </div>
  );
};

export default ImageDisplay;
