import { lazy } from "react";

const SubmissionsForPatientsPage = lazy(
  () => import("../../../pages/AppPages/PatientPages/Submissions")
);
const TherapistProfileForPatientsPage = lazy(
  () =>
    import("../../../pages/AppPages/PatientPages/TherapistProfileForPatients")
);
const PatientOnboarding = lazy(
  () =>
    import("../../../pages/AuthenticationPages/Onboarding/PatientOnboarding")
);
const SafetyPlanForPatients = lazy(
  () => import("../../../pages/AppPages/PatientPages/SafetyPlanForPatients")
);

export {
  SubmissionsForPatientsPage,
  TherapistProfileForPatientsPage,
  PatientOnboarding,
  SafetyPlanForPatients,
};
