import React from "react";
import { Field } from "types";
import SelectField from "./SelectField";
import { NEGATIVE_EMOTIONS } from "utils/constants";

interface Props {
  field: Field;
  defaultValue: any;
  onChange: (value: any) => void;
  error?: string;
}
const NegativeEmotionsField = ({ field, defaultValue, onChange }: Props) => {
  field.options = NEGATIVE_EMOTIONS;
  return (
    <>
      <SelectField
        field={field}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </>
  );
};

export default NegativeEmotionsField;
