import "react-datepicker/dist/react-datepicker.css";
import "./DateField.styles.scss";
import React, { ComponentProps, useState } from "react";
import DatePicker from "react-datepicker";

interface DateFieldProps extends ComponentProps<"input"> {
  inputName: string | undefined;
  inputType?: string;
  inputLabel?: string;
  errorMessage?: string;
  forwardRef?: any;
  hint?: string;
  showMonthDropdown?: any;
  showYearDropdown?: any;
}

const DateField = ({
  inputName,
  inputType = "date",
  inputLabel,
  errorMessage,
  forwardRef,
  hint,
  showMonthDropdown,
  showYearDropdown,
  ...rest
}: DateFieldProps) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  return (
    <div className="date-field">
      <label className="date-field__label">{inputLabel}</label>
      <DatePicker
        aria-label={inputLabel}
        className={`date-field__field ${errorMessage ? "error" : ""}`}
        ref={forwardRef}
        selected={selectedDate}
        onChange={(date: any) => setSelectedDate(date)}
        peekNextMonth
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode="select"
        name={inputName}
        type={inputType}
        {...rest}
      />
      {errorMessage && (
        <p className="date-field__error">
          <small>{errorMessage}</small>
        </p>
      )}
      {!errorMessage && hint && hint !== "null" && (
        <p className="date-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default DateField;
