import React, { useMemo } from "react";
import { toAmericanDateWithTimeFromUnix, toAmericanDate } from "utils/helpers";

const useNotesDataColumns = () => {
  const notesDataColumns = useMemo(
    () => [
      {
        header: "Date",
        cell: (props) => {
          return (
            <>
              <b>{toAmericanDate(props.row.original.date)}</b>
            </>
          );
        },
      },
      {
        header: "Created",
        accessorKey: "createdAt",
        cell: (props) => {
          return (
            <>
              <span className="light-text">
                {toAmericanDateWithTimeFromUnix(props.row.original.createdAt)}
              </span>
            </>
          );
        },
      },
      {
        header: "Updated",
        accessorKey: "updatedAt",
        cell: (props) => {
          return (
            <>
              <span className="light-text">
                {toAmericanDateWithTimeFromUnix(props.row.original.updatedAt)}
              </span>
            </>
          );
        },
      },
    ],
    []
  );
  return notesDataColumns;
};

export default useNotesDataColumns;
