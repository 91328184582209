import { Icon } from "components";
import React from "react";
import AngleLeft from "assets/icons/circle-arrow-left.svg";
import ArrowRight from "assets/icons/circle-arrow-right.svg";

const AssignmentFieldsNavigation = ({
  onNext,
  onBack,
  sectionTitle,
  previousDisabled,
  nextDisabled,
}) => {
  return (
    <div className="assignment-fields-navigation">
      <div
        className={`assignment-fields-navigation__icon ${
          previousDisabled ? "disabled" : ""
        }`}
        role="button"
        tabIndex={0}
        onClick={onBack}
        onKeyDown={onBack}
      >
        <Icon src={AngleLeft} alt="Previous Section" />
      </div>
      <div className="assignment-fields-navigation__title">
        <h4 className="no-mt no-mb">{sectionTitle}</h4>
      </div>
      <div
        className={`assignment-fields-navigation__icon ${
          nextDisabled ? "disabled" : ""
        }`}
        role="button"
        tabIndex={0}
        onClick={onNext}
        onKeyDown={onNext}
      >
        <Icon src={ArrowRight} alt="Next Section" />
      </div>
    </div>
  );
};

export default AssignmentFieldsNavigation;
