import React from "react";
import "./GroupNameTag.styles.scss";

interface GroupNameTagProps {
  groupName: string;
}

const GroupNameTag = ({ groupName }: GroupNameTagProps) => {
  return <div className="group-name-tag">{groupName}</div>;
};

export default GroupNameTag;
