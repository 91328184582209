import { InviteQRCode, inviteCodeInfo } from "types";

export const fetchInviteCodesAction = (inviteCodes: InviteQRCode[]) => {
  return {
    type: "FETCH_INVITE_CODES",
    payload: inviteCodes,
  };
};

export const fetchInviteCodeInfoAction = (inviteCodeInfo: inviteCodeInfo) => {
  return {
    type: "FETCH_INVITE_CODE_INFO",
    payload: inviteCodeInfo,
  };
};

export const createInviteCodeAction = (inviteCode: InviteQRCode) => {
  return {
    type: "CREATE_INVITE_CODE",
    payload: inviteCode,
  };
};

export const deleteInviteCodeAction = (inviteCodeId: string) => {
  return {
    type: "DELETE_INVITE_CODE",
    payload: inviteCodeId,
  };
};
