export const fetchTherapistsAction = (therapists) => {
  return {
    type: "FETCH_THERAPISTS",
    payload: therapists,
  };
};

export const addTherapistProfile = (profile) => {
  return {
    type: "CREATE_THERAPIST_PROFILE",
    payload: profile,
  };
};

export const fetchTherapistProfileAction = (profile) => {
  return {
    type: "FETCH_THERAPIST_PROFILE",
    payload: profile,
  };
};

export const addTherapistPracticeInfo = (practiceInfo) => {
  return {
    type: "CREATE_THERAPIST_PRACTICE_INFO",
    payload: practiceInfo,
  };
};

export const fetchTherapistPracticeInfoAction = (practiceInfo) => {
  return {
    type: "FETCH_THERAPIST_PRACTICE_INFO",
    payload: practiceInfo,
  };
};
