import "./SubmissionsTableEmptyState.styles.scss";
import React, { useState } from "react";
import BalloonTwo from "assets/images/balloon-1.png";
import { BRAND_NAME } from "utils/constants";
import useSubmissionUtils from "../../utils";
import { SidePanel } from "components";
import AddPatientsForm from "pages/AppPages/TherapistPages/Patients/components/AddPatientsForm";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SubmissionsTableEmptyState = () => {
  const { t } = useTranslation(["common"]);
  const { firstName, hasAnyPatients, firstPatient, hasAssignments } =
    useSubmissionUtils();
  const [showAddPatient, setShowAddPatient] = useState(false);
  const history = useNavigate();

  const handleAddPatientLinkClick = () => {
    setShowAddPatient(true);
  };

  const handleAddAssignmentClick = () => {
    history(`/patients/${firstPatient.slug}/workbook`);
  };

  return (
    <>
      {hasAssignments ? (
        <div className="submissions-table-empty-state">
          <h2>
            <span role="img" aria-label="waving hand">
              👋
            </span>{" "}
            {t("dashboard.empty_state_greeting", { userName: firstName })}
          </h2>
          <h3>{t("dashboard.empty_state_header")}</h3>
          <p>
            {t("dashboard.empty_state_paragraph")}{" "}
            <span role="img" aria-label="happy face">
              😊
            </span>
          </p>
          <div className="submissions-table-empty-state__image-holder">
            <img
              className="submissions-table-empty-state__image"
              src={BalloonTwo}
              alt="green and purple balloon"
            />
          </div>
        </div>
      ) : (
        <>
          <div className="submissions-table-empty-state">
            <h2>
              <span role="img" aria-label="waving hand">
                👋
              </span>{" "}
              Welcome to {BRAND_NAME}, {firstName}!
            </h2>
            <h3>Getting started is easy:</h3>
            <div className="submissions-onboarding-list">
              {hasAnyPatients ? (
                <>
                  <p>
                    <span className="onboarding-number complete">1</span>{" "}
                    <span className="onboarding-link complete">
                      Add your first patient
                    </span>
                  </p>
                  <p>
                    <span className="onboarding-number">2</span>
                    <span
                      role="button"
                      tabIndex="0"
                      onClick={handleAddAssignmentClick}
                      onKeyDown={handleAddAssignmentClick}
                      className="onboarding-link"
                    >
                      Click here to give your patient an assignment
                    </span>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <span className="onboarding-number">1</span>{" "}
                    <span
                      role="button"
                      tabIndex="0"
                      onClick={handleAddPatientLinkClick}
                      onKeyDown={handleAddPatientLinkClick}
                      className="onboarding-link"
                    >
                      Click here to add your first patient
                    </span>
                  </p>
                  <p>
                    <span className="onboarding-number">2</span> Give your
                    patient an assignment
                  </p>
                </>
              )}
            </div>
            <p>
              As soon as a patient submits an assignment it will appear here for
              you to review.{" "}
              <span role="img" aria-label="happy face">
                😊
              </span>
            </p>
            <div className="submissions-table-empty-state__image-holder">
              <img
                className="submissions-table-empty-state__image"
                src={BalloonTwo}
                alt="green and purple balloon"
              />
            </div>
          </div>
          <SidePanel
            title="Add Patient"
            isVisible={showAddPatient}
            onClose={() => setShowAddPatient(false)}
          >
            <AddPatientsForm onSuccess={() => setShowAddPatient(false)} />
          </SidePanel>
        </>
      )}
    </>
  );
};

export default SubmissionsTableEmptyState;
