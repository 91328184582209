import "./Textarea.styles.scss";
import React, { useState } from "react";

interface TextareaProps {
  name?: string;
  label?: string;
  error?: string;
  forwardRef?: any;
  hint?: string;
  defaultValue?: string;
  onChange?: any;
  maxLength?: number;
  placeholder?: string;
  rows?: number | string;
}

const Textarea = ({
  label,
  error,
  forwardRef,
  hint,
  rows,
  ...props
}: TextareaProps) => {
  const [charCount, setCharCount] = useState(props.defaultValue?.length || 0);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCharCount(e.target.value.length);
  };
  return (
    <div className="textarea">
      <label className="textarea__label">{label}</label>
      <textarea
        aria-label={label}
        className={`textarea__field ${error ? "error" : ""}`}
        ref={forwardRef}
        {...props}
        rows={Number(rows)}
        onChange={(e) => {
          props.onChange && props.onChange(e);
          handleTextChange(e);
        }}
      />
      {error && (
        <p className="textarea__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && (
        <p className="textarea__hint">
          <small>{hint}</small>
        </p>
      )}
      {props.maxLength && (
        <p
          className={`textarea__hint ${
            charCount === props.maxLength ? "textarea__error" : ""
          }`}
        >
          <small>
            {charCount}/{props.maxLength}
          </small>
        </p>
      )}
    </div>
  );
};

export default Textarea;
