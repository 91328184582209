/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import "./MobileBottomNav.styles.scss";
import React from "react";
import MobileBottomNavItem from "./components/MobileBottomNavItem";
import {
  useTherapistNavItems,
  usePatientNavItems,
} from "../constants/navItems";
import { useCurrentUser, useSafetyPlan } from "utils/hooks";

const MobileBottomNav = () => {
  const { isPatient } = useCurrentUser();
  const { therapistMobileNavItems } = useTherapistNavItems();
  const { patientMobileNavItems } = usePatientNavItems();
  const { isSafetyPlanAvailable } = useSafetyPlan({});
  const availablePatientNavItems = patientMobileNavItems.filter(
    (item) => isSafetyPlanAvailable || item.label !== "Safety Plan"
  );
  const navItems = isPatient
    ? availablePatientNavItems
    : therapistMobileNavItems;
  return (
    <div className={`mobile-bottom-nav`}>
      <div className="mobile-bottom-nav__menu__wrapper">
        <ul className="mobile-bottom-nav__menu">
          {navItems.map((mobileBottomNavItem, i) => {
            return <MobileBottomNavItem key={i} item={mobileBottomNavItem} />;
          })}
        </ul>
      </div>
    </div>
  );
};

export default MobileBottomNav;
