import "./Button.styles.scss";
import React, { ReactElement } from "react";
import Loader from "assets/icons/spinner-third.svg";
import { Icon } from "components";

type ButtonType = "button" | "submit" | "reset";

interface ButtonProps {
  value: string | ReactElement;
  name: string;
  type?: ButtonType;
  disabled?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  extraClass?: string;
  pill?: boolean;
  onClick?: () => void | Promise<void>;
  icon?: string;
  size?: string;
}

const Button = ({
  value,
  name,
  type = "button",
  disabled = false,
  fullWidth = false,
  isLoading = false,
  extraClass = "",
  pill = false,
  onClick,
  icon,
  size,
}: ButtonProps) => {
  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick && e.preventDefault();
    onClick && e.stopPropagation();
    onClick && onClick();
  };

  return (
    <button
      onClick={onClickHandler}
      name={name}
      type={type}
      disabled={disabled || isLoading}
      className={`button transition ${fullWidth ? "full-width" : ""} ${
        extraClass ? extraClass : ""
      }${pill ? "pill" : ""} ${size ? size : ""}`}
    >
      {isLoading ? (
        <div className="button__loader">
          <Icon src={Loader} />
        </div>
      ) : (
        ""
      )}
      {icon && !isLoading ? (
        <div className="transition button__icon">
          <Icon src={icon} />
        </div>
      ) : (
        ""
      )}
      {value}
    </button>
  );
};

export default Button;
