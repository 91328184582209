// Animate.tsx
import "./Animate.styles.scss";
import React, { CSSProperties, ReactNode } from "react";

interface AnimateProps {
  animation?: string;
  delay?: string;
  width?: string;
  style?: CSSProperties;
  children: ReactNode;
  maxZindex?: boolean;
}

const Animate = ({
  animation = "fadeInDown",
  delay = "0",
  width = "auto",
  style,
  children,
}: AnimateProps) => {
  return (
    <div
      className={`animate ${animation}`}
      style={{ animationDelay: `${delay}s`, width: width, ...style }}
    >
      {children}
    </div>
  );
};

export default Animate;
