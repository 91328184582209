const useGetZoomToken = ({ meetingArgs }: any) => {
  const getZoomToken = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_REST_API_URL}/generate-video-session`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(meetingArgs),
        }
      );
      if (!res.ok) {
        throw res;
      }
      const signature = await res.text();
      return signature;
    } catch (err: any) {
      if (err.status >= 400 && err.status < 500) {
        err.text().then((errorData: any) => console.log(errorData));
      } else {
        console.error(err);
      }
    }
  };

  return {
    getZoomToken,
  };
};

export default useGetZoomToken;
