import JpgIcon from "assets/icons/filetypes/file-image.svg";
import PngIcon from "assets/icons/filetypes/file-image.svg";
import GifIcon from "assets/icons/filetypes/file-image.svg";
import DocIcon from "assets/icons/filetypes/file.svg";
import SheetIcon from "assets/icons/filetypes/file-spreadsheet.svg";
import PdfIcon from "assets/icons/filetypes/file-pdf.svg";
import TxtIcon from "assets/icons/filetypes/file.svg";

const fileTypeIcons: { [index: string]: string } = {
  jpeg: JpgIcon,
  jpg: JpgIcon,
  png: PngIcon,
  gif: GifIcon,
  pdf: PdfIcon,
  doc: DocIcon,
  docx: DocIcon,
  xls: SheetIcon,
  xlsx: SheetIcon,
  txt: TxtIcon,
  svg: JpgIcon,
};

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const getFileTypeIconFromUrl = (url: string): string | null => {
  const lastSegment = url?.substring(url.lastIndexOf("/") + 1);
  const splitSegment = lastSegment.split(".");
  const extension =
    splitSegment.length > 1
      ? splitSegment[splitSegment.length - 1].toLowerCase()
      : null;

  return extension ? fileTypeIcons[extension] || null : null;
};
