import React from "react";
import { Logo } from "components";
import "./InviteCodeFooter.styles.scss";

const InviteCodeFooter = () => {
  return (
    <div className="invite-code-footer">
      <Logo />
      <div className="invite-code-footer__links">
        <a
          href="https://www.reflectiveapp.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.reflectiveapp.com
        </a>
        <p>
          Need help signing up? Email{" "}
          <a href="mailto:support@reflectiveapp.com">
            support@reflectiveapp.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default InviteCodeFooter;
