import "./FlatButton.styles.scss";
import { Spinner } from "components";
import React from "react";

type ButtonType = "button" | "submit" | "reset";

interface FlatButtonProps {
  disabled: boolean;
  isLoading: boolean;
  children: string;
  type?: ButtonType;
}

const FlatButton = ({
  disabled = false,
  isLoading = false,
  children,
  type = "submit",
}: FlatButtonProps) => {
  return (
    <button type={type} disabled={disabled} className="flat-button">
      <Spinner isActive={isLoading} />
      {children}
    </button>
  );
};

export default FlatButton;
