import { useDispatch } from "react-redux";
import {
  fetchAllPublicTemplateTagsAction,
  fetchAllPrivateTemplateTagsAction,
} from "store/actions/templates";
import { toast } from "react-toastify";
import { useLogout } from "utils/hooks";

const useFetchTemplateTags = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();

  const fetchAllPublicTemplateTags = () => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getAllPublicTemplateTags {
                uuid
                title
                color
                type
                authorId
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchAllPublicTemplateTagsAction(
                resData.data.getAllPublicTemplateTags
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchAllPrivateTemplateTags = () => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getAllPrivateTemplateTags {
                uuid
                title
                color
                type
                authorId
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchAllPrivateTemplateTagsAction(
                resData.data.getAllPrivateTemplateTags
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return {
    fetchAllPublicTemplateTags,
    fetchAllPrivateTemplateTags,
  };
};

export default useFetchTemplateTags;
