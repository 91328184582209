import { useDispatch } from "react-redux";

import { fetchAllMoodScalesAction } from "store/actions/moodScale";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useLogout } from "../index";

const useFetchMoodScales = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();

  const fetchAllMoodScales = useCallback(() => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
              query {
                getMoodScalesByUser {
                  uuid
                  userId
                  label
                  description
                  emojis {
                    emoji
                    label
                  }
                }
              }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
          } else {
            dispatch(
              fetchAllMoodScalesAction(resData.data.getMoodScalesByUser)
            );
            Promise.resolve(resData.data.getMoodScalesByUser).then((r) => r);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }, [dispatch, logOut]);
  return { fetchAllMoodScales };
};

export default useFetchMoodScales;
