import { useSelector } from "react-redux";

const useGetTherapistProfile = () => {
  const therapistProfile = useSelector(
    (state) =>
      state.therapists.therapistProfile && state.therapists.therapistProfile
  );

  if (!therapistProfile) {
    return {
      therapistProfile: null,
      isTherapistProfileComplete: false,
    };
  }

  const isTherapistProfileComplete = () => {
    if (!therapistProfile) {
      return false;
    }
    if (
      therapistProfile &&
      therapistProfile.profileCredentials &&
      therapistProfile.profileLicenseNumber &&
      therapistProfile.profileLicensingState
    ) {
      return true;
    }
  };

  return {
    therapistProfile,
    isTherapistProfileComplete: isTherapistProfileComplete(),
  };
};

export default useGetTherapistProfile;
