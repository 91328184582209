import "./RecentSubmissionsCard.styles.scss";
import React from "react";
import { SubmissionListItem } from "components";
import { AssignmentSubmission } from "types";

interface RecentSubmissionsCardProps {
  data: any;
  color: string;
}

const RecentSubmissionsCard = ({ data, color }: RecentSubmissionsCardProps) => {
  if (!data) {
    return null;
  }

  const sortedSubmissions = data?.sort(function (
    a: { createdAt: number },
    b: { createdAt: number }
  ) {
    return b.createdAt - a.createdAt;
  });
  return (
    <div className={`recent-submissions-card ${color}`}>
      <div className="recent-submissions-card__list">
        <div className="recent-submissions-card__stats-title">
          Recent Submissions
        </div>
        <ul className="recent-submissions-card__list-list">
          {sortedSubmissions.slice(0, 5).map((item: AssignmentSubmission) => {
            return (
              <li
                className="recent-submissions-card__list-list--item"
                key={item.slug}
                id={item.slug}
              >
                <SubmissionListItem submission={item} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default RecentSubmissionsCard;
