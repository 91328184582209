import "./LibraryEmptyState.styles.scss";
import React from "react";
import BirdTwo from "assets/images/bird-2.png";
import { useCurrentUser } from "utils/hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const LibraryEmptyState = () => {
  const { t } = useTranslation(["common"]);
  const { isPatient } = useCurrentUser();
  const { uuid } = useParams();

  const isGroup = uuid?.includes("group");

  let paragraph;

  if (isGroup) {
    paragraph = t("groups.library_empty_group_paragraph");
  } else if (isPatient) {
    paragraph = t("patient_library_page.library_empty_patient_paragraph");
  } else {
    paragraph = t("patient_library_page.library_empty_therapist_paragraph");
  }

  return (
    <div className="library-empty-state">
      <h3>{t("patient_library_page.library_empty_header")}</h3>
      <p>{paragraph}</p>
      <div className="library-empty-state__image-holder">
        <img
          className="library-empty-state__image"
          src={BirdTwo}
          alt="small bird"
        />
      </div>
    </div>
  );
};

export default LibraryEmptyState;
