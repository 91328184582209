import React from "react";
import "./ReminderAlertContent.styles.scss";
import { Icon } from "components";
import BellIcon from "assets/icons/bell-on.svg";

type Props = {
  isDisabled: boolean;
};

const ReminderAlertContent = ({ isDisabled }: Props) => {
  return (
    <div className="flex justify-between align-center">
      <div className="reminder-icon-wrapper">
        <Icon src={BellIcon} />
      </div>
      <div>
        {isDisabled
          ? "Group members who receive reminders have a significantly higher chance of doing their assignments."
          : "Set a schedule with or without reminders. Groups can customize their reminder settings."}
      </div>
    </div>
  );
};

export default ReminderAlertContent;
