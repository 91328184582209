import "./UserMenu.styles.scss";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useLogout,
  useClickOutside,
  useGetTherapistPracticeInfo,
  useGetTherapistProfile,
  useCurrentUser,
} from "utils/hooks";
import { Avatar, Divider, Icon } from "components";
import DownArrowIcon from "assets/icons/arrow-down-thin.svg";
import SignOutIcon from "assets/icons/sign-out.svg";
import SettingsIcon from "assets/icons/cog.svg";
import { User } from "types";
import { useNavigate } from "react-router-dom";

interface UserMenuProps {
  user: User;
}

interface UserMenuBodyProps {
  isTherapist: boolean;
  closeMenu: () => void;
}

const UserMenuTitle: React.FC<UserMenuProps> = ({ user }) => {
  const { t } = useTranslation(["common"]);
  return (
    <div className="user-menu__title">
      <div className="user-menu__avatar">
        <Avatar />
      </div>
      {t("dashboard.calm_greeting", {
        userName: user?.firstName ?? user.email,
      })}
      <Icon src={DownArrowIcon} />
    </div>
  );
};

const UserMenuBody: React.FC<UserMenuBodyProps> = ({
  isTherapist,
  closeMenu,
}) => {
  const { logOut } = useLogout();
  const { t } = useTranslation(["common"]);
  const { isTherapistProfileComplete } = useGetTherapistProfile();
  const { isTherapistPracticeInfoComplete } = useGetTherapistPracticeInfo();
  const showTherapistProfileAlert =
    isTherapist &&
    (!isTherapistProfileComplete || !isTherapistPracticeInfoComplete);

  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
    closeMenu();
  };

  const handleLogout = () => {
    logOut();
    closeMenu();
  };

  return (
    <ul className="user-menu__list">
      <li className="user-menu__list-item">
        <button
          className="user-menu__button"
          onClick={() => handleNavigation("/settings")}
        >
          {showTherapistProfileAlert && <div className="user-menu-item__dot" />}
          <div className="user-menu-item__title">
            {t("page_titles.settings_title")}
          </div>
          <div className="user-menu-item__icon">
            <Icon src={SettingsIcon} />
          </div>
        </button>
      </li>
      <Divider small />
      <li className="user-menu__list-item">
        <button className="user-menu__button" onClick={handleLogout}>
          <div className="user-menu-item__title">
            {t("page_titles.logout_title")}
          </div>
          <div className="user-menu-item__icon">
            <Icon src={SignOutIcon} />
          </div>
        </button>
      </li>
    </ul>
  );
};

const UserMenu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { node, isNodeVisible, setIsNodeVisible } = useClickOutside();
  const { user, isTherapist } = useCurrentUser();

  const toggleMenu = () => {
    const nextState = !isMenuOpen;
    setIsNodeVisible(nextState);
    setIsMenuOpen(nextState);
  };

  const closeMenu = () => {
    setIsNodeVisible(false);
    setIsMenuOpen(false);
  };

  // Handle closing the menu when clicking outside
  React.useEffect(() => {
    if (!isNodeVisible && isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [isMenuOpen, isNodeVisible]);

  if (!user) {
    return null;
  }

  return (
    <div className="user-menu" ref={node}>
      <button onClick={toggleMenu} className="user-menu__header">
        <UserMenuTitle user={user} />
      </button>
      {isMenuOpen && (
        <div className="user-menu__dropdown">
          <UserMenuBody isTherapist={isTherapist} closeMenu={closeMenu} />
        </div>
      )}
    </div>
  );
};

export default UserMenu;
