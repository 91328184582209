import React, { useState } from "react";
import { Input, Select } from "components";
import {
  treatmentPreferencesOptions,
  stateOptions,
  practiceSizeOptions,
} from "./options";
import { useViewport, useGetTherapistPracticeInfo } from "utils/hooks";
import { formatPhoneNumber } from "utils/helpers";
import { useTranslation } from "react-i18next";
import { UseFormMethods } from "react-hook-form";

interface PracticeInfoProps {
  register: UseFormMethods["register"];
  control: UseFormMethods["control"];
}

const PracticeInfo = ({ register, control }: PracticeInfoProps) => {
  const { isMobile } = useViewport();
  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneNumberChange = (phone: string) => {
    setPhoneNumber(formatPhoneNumber(phone));
  };

  const { therapistPracticeInfo } = useGetTherapistPracticeInfo();

  const { t } = useTranslation(["common"]);

  return (
    <>
      <Input
        name="practiceName"
        label={t("practice_information.practice_name_label")}
        forwardRef={register()}
        placeholder={t("practice_information.practice_name_placeholder")}
        defaultValue={
          therapistPracticeInfo ? therapistPracticeInfo.practiceName : null
        }
      />
      <Select
        name="treatmentType"
        label={t("practice_information.care_type_label")}
        forwardRef={register()}
        options={treatmentPreferencesOptions}
        isMulti
        forwardControl={control}
        defaultValue={
          therapistPracticeInfo &&
          therapistPracticeInfo.treatmentType &&
          therapistPracticeInfo.treatmentType[0]
            ? therapistPracticeInfo.treatmentType[0]
                ?.split(",")
                .map((type: string) => {
                  if (type !== "null") {
                    return {
                      label: type,
                      value: type,
                    };
                  }

                  return null;
                })
            : null
        }
      />
      <Select
        name="practiceSize"
        label={t("practice_information.how_many_providers_label")}
        forwardRef={register()}
        options={practiceSizeOptions}
        forwardControl={control}
        defaultValue={
          therapistPracticeInfo &&
          therapistPracticeInfo.practiceSize !== undefined
            ? {
                label: practiceSizeOptions.find(
                  (o) => o.value === therapistPracticeInfo.practiceSize
                )?.label,
                value: therapistPracticeInfo.practiceSize,
              }
            : null
        }
      />
      <Input
        name="address1"
        label={t("common_labels.address_1")}
        forwardRef={register()}
        defaultValue={
          therapistPracticeInfo ? therapistPracticeInfo.address1 : null
        }
      />
      <Input
        name="address2"
        label={t("common_labels.address_2")}
        forwardRef={register()}
        defaultValue={
          therapistPracticeInfo ? therapistPracticeInfo.address2 : null
        }
      />
      <div className="flex justify-between mobile-column">
        <div style={{ flex: "1", maxWidth: `${isMobile ? "100%" : "48%"}` }}>
          <Select
            name="state"
            label={t("common_labels.state")}
            forwardRef={register()}
            forwardControl={control}
            options={stateOptions}
            defaultValue={
              therapistPracticeInfo && therapistPracticeInfo.state !== undefined
                ? {
                    label: therapistPracticeInfo.state,
                    value: therapistPracticeInfo.state,
                  }
                : null
            }
          />
        </div>
        <div style={{ flex: "1", maxWidth: `${isMobile ? "100%" : "48%"}` }}>
          <Input
            name="zipcode"
            label={t("common_labels.zip_code")}
            forwardRef={register()}
            style={{ height: "21px" }}
            defaultValue={
              therapistPracticeInfo ? therapistPracticeInfo.zipcode : null
            }
          />
        </div>
      </div>
      <Input
        name="phone"
        label={t("common_labels.phone_number")}
        value={phoneNumber}
        onChange={(e) => handlePhoneNumberChange(e.target.value)}
        forwardRef={register()}
        type="tel"
        placeholder="xxx xxx xxxx"
        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        maxLength={12}
        autoComplete="off"
        defaultValue={
          therapistPracticeInfo ? therapistPracticeInfo.phone : null
        }
      />
    </>
  );
};

export default PracticeInfo;
