import React, { useState, ReactElement, useEffect } from "react";
import { Portal } from "react-portal";
import SlidingPane from "react-sliding-pane";
import "./BottomModal.styles.scss";
import { Button } from "components";

interface BottomModalProps {
  children: ReactElement;
  isVisible: boolean;
  extraClass?: string;
  overlayExtraClass?: string;
  title?: string;
  handleClose: () => void;
  ref?: any;
}
interface BottomModalHeaderProps {
  headerComponent?: (f: () => void) => ReactElement;
  title?: string;
  onClose: () => void;
  ref?: any;
}

const BottomModalHeader = ({
  headerComponent,
  title,
  onClose,
  ref,
}: BottomModalHeaderProps) => {
  return headerComponent ? (
    headerComponent(onClose)
  ) : (
    <div className="bottom-modal_header flex align-center" ref={ref}>
      <Button
        value={""}
        name="bottom-modal_button-back"
        icon="ArrowBack"
        extraClass="bottom-modal_button-back transparent"
        onClick={onClose}
      />
      {title && <p className="bottom-modal_title">{title}</p>}
      <Button
        value={""}
        name="bottom-modal_button-close"
        icon="CloseCross"
        extraClass="bottom-modal_button-close transparent"
        onClick={onClose}
      />
    </div>
  );
};

const BottomModal = ({
  children,
  isVisible,
  extraClass,
  overlayExtraClass,
  title,
  handleClose,
  ref,
}: BottomModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(isVisible);

  useEffect(() => {
    setIsModalVisible(isVisible);
  }, [isVisible]);

  return (
    <Portal>
      <SlidingPane
        from="bottom"
        isOpen={isModalVisible}
        overlayClassName={`bottom-modal_overlay ${overlayExtraClass}`}
        className={`bottom-modal ${extraClass}`}
        width="100%"
        onRequestClose={handleClose}
        shouldCloseOnEsc
        hideHeader
      >
        <BottomModalHeader title={title} onClose={handleClose} ref={ref} />
        {children}
      </SlidingPane>
    </Portal>
  );
};

export default BottomModal;
