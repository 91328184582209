import { lazy } from "react";

const DashboardPage = lazy(
  () => import("../../../pages/AppPages/TherapistPages/Dashboard")
);
const PatientsPage = lazy(
  () => import("../../../pages/AppPages/TherapistPages/Patients")
);
const SinglePatientPage = lazy(
  () => import("../../../pages/AppPages/TherapistPages/SinglePatient")
);
const SubmissionsPage = lazy(
  () => import("../../../pages/AppPages/TherapistPages/Submissions")
);

const AssignmentsBuilderPage = lazy(
  () => import("../../../pages/AppPages/TherapistPages/AssignmentBuilder")
);

const ResourcesPage = lazy(
  () => import("../../../pages/AppPages/TherapistPages/Resources")
);

const GroupsPage = lazy(
  () => import("../../../pages/AppPages/TherapistPages/Groups")
);

const AssignmentSubmissionsPage = lazy(
  () =>
    import("../../../pages/AppPages/TherapistPages/AssignmentSubmissionsPage")
);

import OnlineSession from "pages/AppPages/TherapistPages/OnlineSession";

import VideoSession from "pages/AppPages/Shared/VideoSession/VideoSession";

export {
  DashboardPage,
  PatientsPage,
  SinglePatientPage,
  SubmissionsPage,
  AssignmentsBuilderPage,
  ResourcesPage,
  OnlineSession,
  VideoSession,
  AssignmentSubmissionsPage,
  GroupsPage,
};
