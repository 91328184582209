import React from "react";
import { Field } from "types";

interface Props {
  field: Field;
  defaultValue: any;
  onChange: (value: any) => void;
  error?: string;
}
const StringField = ({ field, defaultValue, onChange }: Props) => {
  return (
    <div className="string-field-new">
      <label className="field__label">{field.label}</label>
      <input
        aria-label={field.label}
        className={`field__field ${field.error ? "error" : ""}`}
        defaultValue={defaultValue}
        placeholder={field.placeholder || ""}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default StringField;
