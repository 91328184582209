import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Animate, Button, Input, PatientsDropdown, Textarea } from "components";
import {
  useAddLibraryResource,
  useUpdateLibraryResource,
  useFetchMetadata,
} from "utils/hooks";
import { Patient, LibraryResource } from "types";
import "./AddLibraryResourceForm.styles.scss";

interface AddLibraryResourceFormProps {
  onSuccess: () => void;
  resource: any;
  showPatients?: boolean;
  defaultSharedWith?: any;
  groupId?: string;
  title?: string;
  shareInput?: boolean;
  submitButtonText?: string;
}

const AddLibraryResourceForm = ({
  onSuccess,
  resource,
  showPatients = true,
  defaultSharedWith = null,
  groupId,
  title,
  shareInput,
  submitButtonText,
}: AddLibraryResourceFormProps) => {
  const [selectedPatients, setSelectedPatients] = useState(
    resource?.sharedWith || null
  );
  const onPatientSelect = (patients: Patient[]) => {
    setSelectedPatients(patients);
  };

  const isFullNameNullable =
    defaultSharedWith?.firstName === null &&
    defaultSharedWith?.lastName === null;

  const formattedDefaultSharedWith = defaultSharedWith
    ? {
        value: defaultSharedWith.uuid,
        label: isFullNameNullable
          ? defaultSharedWith.email
          : `${defaultSharedWith.firstName} ${defaultSharedWith.lastName}`,
      }
    : null;

  const { register, handleSubmit, errors, formState } = useForm();
  const {
    submitAddLibraryResource,
    isSubmitSuccessful,
    submitAddGroupLibraryResource,
  } = useAddLibraryResource({
    selectedPatients: formattedDefaultSharedWith
      ? [formattedDefaultSharedWith]
      : selectedPatients,
    groupId,
  });
  const {
    submitUpdateLibraryResource,
    submitUpdateGroupLibraryResource,
    isUpdateSuccessful,
  } = useUpdateLibraryResource({
    resources: [resource],
    selectedPatients,
    groupId,
  });
  const { isSubmitting } = formState;

  const { fetchMetadata } = useFetchMetadata();
  const [currentUrl, setCurrentUrl] = useState(resource?.resourceUrl || "");
  const [currentTitle, setCurrentTitle] = useState(resource?.title || "");
  const [currentImage, setCurrentImage] = useState(resource?.imageUrl || "");

  useEffect(() => {
    if (isSubmitSuccessful || isUpdateSuccessful) {
      onSuccess();
    }
  }, [isSubmitSuccessful, onSuccess, isUpdateSuccessful]);

  const handleURLBlur = async () => {
    if (
      currentUrl &&
      (currentUrl.startsWith("http://") || currentUrl.startsWith("https://"))
    ) {
      const metadata = await fetchMetadata(currentUrl);
      if (metadata) {
        if (metadata.title) {
          setCurrentTitle(metadata.title);
        }
        if (metadata.image) {
          setCurrentImage(metadata.image);
        }
      }
    }
  };

  const handleURLPaste = async (e: React.ClipboardEvent) => {
    const pastedText = e.clipboardData.getData("text").trim();
    if (pastedText.startsWith("http://") || pastedText.startsWith("https://")) {
      const metadata = await fetchMetadata(pastedText);
      if (metadata) {
        if (metadata.title) {
          setCurrentTitle(metadata.title);
        }
        if (metadata.image) {
          setCurrentImage(metadata.image);
        }
      }
    }
  };

  const submitBtnValue = () => {
    if (submitButtonText) {
      return submitButtonText;
    } else if (resource) {
      return "Save Resource";
    } else {
      return "Add Resource";
    }
  };

  const addHandler = groupId
    ? submitAddGroupLibraryResource
    : submitAddLibraryResource;

  const updateHandler = groupId
    ? submitUpdateGroupLibraryResource
    : submitUpdateLibraryResource;

  const onUpdateData = (data: LibraryResource) => {
    return updateHandler([data]);
  };

  return (
    <div id="add_link_form" className="add_link_form">
      {title && <h1 className="add_link_form_title">{title}</h1>}
      <form
        onSubmit={handleSubmit(resource ? onUpdateData : addHandler)}
        autoComplete="off"
      >
        <Animate animation="fadeInLeft" delay=".15">
          <Input
            name="resourceUrl"
            type="url"
            label="URL"
            placeholder="e.g. https://www.youtube.com/watch?v=q6aAQgXauQw"
            forwardRef={register({ required: true })}
            error={errors.resourceUrl && "URL is required"}
            defaultValue={currentUrl}
            onBlur={handleURLBlur}
            onChange={(e) => setCurrentUrl(e.target.value)}
            onPaste={handleURLPaste}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".25">
          <Input
            name="imageUrl"
            type="url"
            label="Image URL"
            placeholder="e.g. https://img.youtube.com/vi/q6aAQgXauQw/maxresdefault.jpg"
            forwardRef={register()}
            optionalField
            defaultValue={currentImage}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".35">
          <Input
            name="title"
            type="text"
            label="Title"
            placeholder="e.g. What is Cognitive Behavioral Therapy?"
            forwardRef={register({ required: true })}
            error={errors.title && "Title field is required"}
            defaultValue={currentTitle}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".45">
          <Textarea
            name="description"
            label="Description"
            placeholder={
              "e.g CBT is an evidence-based treatment that can help people with depression, anxiety, panic attacks, hard relationships, and many other problems."
            }
            forwardRef={register({ required: true })}
            error={errors.description && "Description field is required"}
            rows="5"
            maxLength={240}
            defaultValue={resource && resource.description}
          />
        </Animate>
        {showPatients && shareInput && (
          <PatientsDropdown
            name="selectedPatients"
            isMulti
            label="Share with"
            includeEmail={false}
            placeholder="Choose patients"
            defaultPatient={null}
            onChange={onPatientSelect}
            allowSelectAll
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={formattedDefaultSharedWith || selectedPatients}
          />
        )}
        <Button
          type="submit"
          name="add_or_edit_resource_button"
          value={submitBtnValue()}
          fullWidth
          isLoading={isSubmitting}
          extraClass="add_link_form_button pill"
        />
      </form>
    </div>
  );
};

export default AddLibraryResourceForm;
