import "./RemoveResourceConfirmationModal.styles.scss";
import React from "react";
import { Icon, Button } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";
import { useDeleteLibraryResource } from "utils/hooks";

const RemoveResourceConfirmationModal = ({ resource, onCancel, group }) => {
  const { deleteGroupLibraryResource } = useDeleteLibraryResource();

  return (
    <div className="resource-removal-confirmation">
      <div className="resource-removal-confirmation__icon">
        <Icon src={WarningIcon} alt="Warning Icon" />
      </div>
      <div className="resource-removal-confirmation__title">
        Are you sure you want to remove this resource?
      </div>
      <div className="resource-removal-confirmation__body">
        {group.name} will no longer have access.
      </div>
      <div className="resource-removal-confirmation__buttons">
        <Button
          onClick={onCancel}
          name={`cancel_remove_resource_button`}
          value="Never mind"
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => {
            deleteGroupLibraryResource(resource);
            onCancel();
          }}
          name={`confirm_remove_resource_button`}
          value="Yes, remove it"
          extraClass="danger"
          size="small"
        />
      </div>
    </div>
  );
};

export default RemoveResourceConfirmationModal;
