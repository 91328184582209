import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { fetchInviteCodeInfoAction } from "store/actions/inviteCodes";

interface useFetchInviteCodeInfoProps {
  therapistSlug: string;
  codeSlug: string;
  groupSlug: string;
}

const useFetchInviteCodeInfo = ({
  therapistSlug,
  codeSlug,
  groupSlug,
}: useFetchInviteCodeInfoProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const fetchInviteCodeInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_REST_API_URL}/invite-code/${therapistSlug}/${codeSlug}/${groupSlug}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const resData = await res.json();

      if (res.ok) {
        dispatch(fetchInviteCodeInfoAction(resData));
      }

      if (!res.ok) {
        navigate("/not-found");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [therapistSlug, codeSlug, groupSlug, dispatch, navigate]);

  const inviteCodeInfo = useSelector((state: RootStateOrAny) => {
    return state.inviteCodes && state.inviteCodes.inviteCodeInfo;
  });

  return {
    fetchInviteCodeInfo,
    inviteCodeInfo,
    isLoading,
  };
};

export default useFetchInviteCodeInfo;
