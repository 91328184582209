import { useEffect, useState } from "react";
import { BREAKPOINTS } from "utils/constants";

const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);
  // eslint-disable-next-line prettier/prettier
  const [isMobile, setIsMobile] = useState(
    width <= parseInt(BREAKPOINTS.viewport7)
  );
  // eslint-disable-next-line prettier/prettier
  const [isDesktop, setIsDesktop] = useState(
    width > parseInt(BREAKPOINTS.viewport7)
  );

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setIsMobile(window.innerWidth <= parseInt(BREAKPOINTS.viewport7));
      setIsDesktop(window.innerWidth > parseInt(BREAKPOINTS.viewport7));
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [width]);

  return {
    width,
    isMobile,
    isDesktop,
  };
};

export default useViewport;
