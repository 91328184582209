import React from "react";
import { AssignmentCard, MobilePageHeader } from "components";
import { useAssignmentStore } from "../../assignmentStore";
import { Section } from "types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// import { ASSIGNMENT_ENTITY_STATUSES } from "utils/constants";

const AssignmentsForPatientsSectionsColumn = () => {
  const { state, dispatch } = useAssignmentStore();
  const history = useNavigate();
  const handleActionClick = (section: Section) => {
    if (!state.activeSection) {
      return;
    }
    if (section.order < state.activeSection.order) {
      history(
        `/your-assignments/${state.activeAssignment?.slug}/${section.slug}`
      );
      return;
    }
    if (section.order > state.activeSection.order + 1) {
      toast.warning("Please complete the previous section before proceeding");
      return;
    }
    const { validatedSection, isValid } = state.validateSection(
      state.activeSection
    );
    dispatch({ activeSection: validatedSection, isSectionValid: isValid });
    if (!isValid) {
      toast.warning("Please fill out all required fields before proceeding");
      return;
    }
    history(
      `/your-assignments/${state.activeAssignment?.slug}/${section.slug}`
    );
  };
  const handleBackClick = () => {
    history("/your-assignments");
  };
  return (
    <>
      <MobilePageHeader
        title={state.activeAssignment?.label}
        leftAction={handleBackClick}
      />
      {state.activeAssignment?.sections?.map((section, index) => {
        return (
          <AssignmentCard
            showSubtitle
            subtitle={`${index + 1} of ${
              state.activeAssignment?.sections.length
            }`}
            isActive={state.activeSection?.uuid === section.uuid}
            key={section.uuid}
            assignment={section}
            onCardClick={() => handleActionClick(section)}
            hasActions={false}
          />
        );
      })}
    </>
  );
};

export default AssignmentsForPatientsSectionsColumn;
