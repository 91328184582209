import { useState } from "react";
import ReactGA from "react-ga4";
import {
  updateLibraryResource,
  updateGroupLibraryResource,
} from "store/actions/library";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useFetchLibrary,
  useLogout,
  useCurrentUser,
  useGetTherapistLibrary,
  useGetGroupLibrary,
  useGetPatients,
} from "utils/hooks";
import { formatInput } from "utils/helpers";
import { formattedSharedWith } from "./libraryUtils";
import { LibraryResource } from "types";
import { useParams } from "react-router-dom";

interface Props {
  resources: any;
  selectedPatients: any;
  groupId?: string;
}

const useUpdateLibraryResource = ({
  resources,
  selectedPatients,
  groupId,
}: Props) => {
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const {
    fetchTherapistLibrary,
    fetchPublicPatientLibrary,
    fetchGroupLibrary,
  } = useFetchLibrary();

  const { user } = useCurrentUser();
  const { therapistResources } = useGetTherapistLibrary({});
  const { groupResources } = useGetGroupLibrary({ groupId });
  const { uuid } = useParams();
  const { patient } = useGetPatients({ slug: uuid });

  const formatResources = (args: LibraryResource[]) =>
    args.map((arg) => {
      return `{
      uuid: "${arg.uuid ? arg.uuid : resources[0].uuid}",
      resourceUrl: "${formatInput(arg.resourceUrl)}",
      title: "${formatInput(arg.title)}",
      description: "${formatInput(arg.description)}",
      imageUrl: "${formatInput(arg.imageUrl)}",
      sharedWith: [${formattedSharedWith(selectedPatients)}]
    }`;
    });

  const submitUpdateLibraryResource = (args: LibraryResource[]) => {
    if (!args || args.length === 0) {
      return null;
    }

    if (resources?.length === 1 && args.length === 1) {
      const filteredResources = therapistResources.filter(
        (therapistResource: LibraryResource) =>
          therapistResource.uuid !== resources[0].uuid
      );

      const existingUrl = filteredResources.find(
        (therapistResource: LibraryResource) =>
          therapistResource.resourceUrl === args[0].resourceUrl
      );

      if (existingUrl) {
        toast.warn(`A link with this url already exists in your library :)`);
        return null;
      }
    }

    return new Promise((resolve: any) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                updateLibraryResource(resources: [${formatResources(args)}]) {
                  resourceUrl
                  title
                  description
                  imageUrl
                  uuid
                  addedBy
                  updatedAt
                  groupId
                  sharedWith {
                    label
                    value
                  }
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              fetchTherapistLibrary();
              if (patient) {
                fetchPublicPatientLibrary(patient.uuid);
              }
              toast("Resource Updated");
              setIsUpdateSuccessful(true);
              resolve();
              ReactGA.event("library_resource_added_success", {
                user_id: user?.id,
                group_id: resData.data.updateLibraryResource.groupId,
              });
              return dispatch(
                updateLibraryResource(resData.data.updateLibraryResource)
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  const submitUpdateGroupLibraryResource = (args: LibraryResource[]) => {
    if (!args || args.length === 0) {
      return null;
    }

    if (resources?.length === 1 && args.length === 1) {
      const filteredResources = groupResources.filter(
        (groupResource: LibraryResource) =>
          groupResource.uuid !== resources[0].uuid
      );

      const existingUrl = filteredResources.find(
        (groupResource: LibraryResource) =>
          groupResource.resourceUrl === args[0].resourceUrl
      );

      if (existingUrl) {
        toast.warn(`A link with this url already exists in your library :)`);
        return null;
      }
    }

    return new Promise((resolve: any) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                updateLibraryResource(resources: [${formatResources(args)}]) {
                  resourceUrl
                  title
                  description
                  imageUrl
                  uuid
                  addedBy
                  updatedAt
                  groupId
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              fetchGroupLibrary(groupId);
              if (patient) {
                fetchPublicPatientLibrary(patient.uuid);
              }
              toast("Resource Updated");
              setIsUpdateSuccessful(true);
              resolve();
              ReactGA.event("library_resource_added_success", {
                user_id: user?.id,
                group_id: groupId,
              });
              return dispatch(
                updateGroupLibraryResource(resData.data.updateLibraryResource)
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitUpdateLibraryResource,
    submitUpdateGroupLibraryResource,
    isUpdateSuccessful,
  };
};

export default useUpdateLibraryResource;
