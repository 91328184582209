export const defaultIcons = [
  { name: "Puzzle", src: "Puzzle" },
  { name: "Rook", src: "Rook" },
  { name: "Gamer", src: "Gamer" },
  { name: "Dice", src: "Dice" },
  { name: "DandD", src: "DandD" },
  { name: "Robot", src: "Robot" },
  { name: "Clover", src: "Clover" },
  { name: "Gameboy", src: "Gameboy" },
  { name: "Castle", src: "Castle" },
  { name: "Ghost", src: "Ghost" },
  { name: "Ice Cream", src: "Icecream" },
  { name: "Apple", src: "Apple" },
  { name: "Snowman", src: "Snowman" },
  { name: "Unicorn", src: "Unicorn" },
  { name: "Whale", src: "Whale" },
  { name: "Mitten", src: "Mitten" },
  { name: "Truck", src: "Truck" },
  { name: "Car", src: "Car" },
  { name: "Bus", src: "Bus" },
  { name: "Cactus", src: "Cactus" },
  { name: "Football", src: "Football" },
  { name: "Bowling", src: "Bowling" },
  { name: "Helmet", src: "Helmet" },
  { name: "Flag", src: "Flag" },
  { name: "Rocket", src: "Rocket" },
  { name: "Bulb", src: "Bulb" },
  { name: "Guitar", src: "Guitar" },
  { name: "Candy Cane", src: "Candycane" },
  { name: "Carrot", src: "Carrot" },
  { name: "Glasses", src: "Glasses" },
  { name: "Cowboy", src: "Cowboy" },
  { name: "Television", src: "Television" },
];
