import Smile from "assets/icons/custom-assignments/face-smile.svg";
import Frown from "assets/icons/custom-assignments/face-frown.svg";
import Meh from "assets/icons/custom-assignments/face-meh.svg";
import GrinBeam from "assets/icons/custom-assignments/face-grin-beam.svg";
import GrinTears from "assets/icons/custom-assignments/face-grin-tears.svg";
import GrinHearts from "assets/icons/custom-assignments/face-grin-hearts.svg";
import Heart from "assets/icons/custom-assignments/heart.svg";
import Sun from "assets/icons/custom-assignments/sun.svg";
import Moon from "assets/icons/custom-assignments/moon.svg";
import Cloud from "assets/icons/custom-assignments/cloud.svg";
import Umbrella from "assets/icons/custom-assignments/umbrella.svg";
import Star from "assets/icons/custom-assignments/star.svg";
import Leaf from "assets/icons/custom-assignments/leaf.svg";
import Running from "assets/icons/custom-assignments/person-running.svg";
import Hiking from "assets/icons/custom-assignments/person-hiking.svg";
import Yoga from "assets/icons/custom-assignments/person-praying.svg";
import Stethoscope from "assets/icons/custom-assignments/stethoscope.svg";
import Brain from "assets/icons/custom-assignments/brain.svg";
import Comments from "assets/icons/custom-assignments/comments.svg";
import BookOpen from "assets/icons/custom-assignments/book-open.svg";
import Music from "assets/icons/custom-assignments/music.svg";
import Pen from "assets/icons/custom-assignments/pen.svg";
import PaintBrush from "assets/icons/custom-assignments/paintbrush.svg";
import Lightbulb from "assets/icons/custom-assignments/lightbulb.svg";
import PuzzlePiece from "assets/icons/custom-assignments/puzzle-piece.svg";
import Mountain from "assets/icons/custom-assignments/mountain.svg";
import HandsHelping from "assets/icons/custom-assignments/hands-holding-circle.svg";
import ThumbsUp from "assets/icons/custom-assignments/thumbs-up.svg";
import ThumbsDown from "assets/icons/custom-assignments/thumbs-down.svg";
import LifeRing from "assets/icons/custom-assignments/life-ring.svg";

import ThoughtRecord from "assets/icons/waveform-path.svg";
import MoodTracker from "assets/icons/cloud-rainbow.svg";
import JournalIcon from "assets/icons/book-spells.svg";
import SafetyPlanIcon from "assets/icons/lifebuoy.svg";
import GratitudeJournalIcon from "assets/icons/book-heart.svg";
import SocraticQuestionsIcon from "assets/icons/map-marker-question.svg";
import CognitiveRestructuringIcon from "assets/icons/brain.svg";
import CognitiveTriangleIcon from "assets/icons/recycle.svg";
import GoalJournalIcon from "assets/icons/atlas.svg";
import WorryJournalIcon from "assets/icons/temperature-high.svg";
import UpcomingSessionIcon from "assets/icons/calendar-circle-user.svg";
import PostSessionIcon from "assets/icons/memo-pad.svg";
import SensesExerciseIcon from "assets/icons/5.svg";
import ArrowDown from "assets/icons/angle-down.svg";

import DraftIcon from "assets/icons/pen-ruler.svg";

import Schedule from "assets/icons/workbook-buttons/schedule.svg";
import Configure from "assets/icons/workbook-buttons/configure.svg";
import Preview from "assets/icons/workbook-buttons/preview.svg";
import Remove from "assets/icons/workbook-buttons/remove.svg";
import ChangeIcon from "assets/icons/workbook-buttons/change-icon.svg";
import CancelArrow from "assets/icons/workbook-buttons/cancel-arrow.svg";
import HideIcon from "assets/icons/workbook-buttons/hide-icon.svg";

import ArrowBack from "assets/icons/arrow-back.svg";

import Bell from "assets/icons/bell.svg";

import Puzzle from "assets/icons/puzzle-piece.svg";
import Rook from "assets/icons/chess-rook-piece.svg";
import Gamer from "assets/icons/gamepad-modern.svg";
import Dice from "assets/icons/dice.svg";
import DandD from "assets/icons/dice-d20.svg";
import Robot from "assets/icons/robot.svg";
import Clover from "assets/icons/clover.svg";
import Gameboy from "assets/icons/game-console-handheld.svg";
import Castle from "assets/icons/castle.svg";
import Ghost from "assets/icons/ghost.svg";
import Icecream from "assets/icons/ice-cream.svg";
import Apple from "assets/icons/apple-whole.svg";
import Snowman from "assets/icons/snowman.svg";
import Unicorn from "assets/icons/unicorn.svg";
import Whale from "assets/icons/whale.svg";
import Mitten from "assets/icons/mitten.svg";
import Truck from "assets/icons/truck.svg";
import Car from "assets/icons/car.svg";
import Bus from "assets/icons/bus-simple.svg";
import Cactus from "assets/icons/cactus.svg";
import Football from "assets/icons/football.svg";
import Bowling from "assets/icons/bowling-ball.svg";
import Helmet from "assets/icons/football-helmet.svg";
import Flag from "assets/icons/flag.svg";
import Rocket from "assets/icons/rocket.svg";
import Bulb from "assets/icons/lightbulb.svg";
import Guitar from "assets/icons/guitar.svg";
import Candycane from "assets/icons/candy-cane.svg";
import Carrot from "assets/icons/carrot.svg";
import Glasses from "assets/icons/glasses-round.svg";
import Cowboy from "assets/icons/hat-cowboy-side.svg";
import Television from "assets/icons/tv-retro.svg";

import Trash from "assets/icons/trash.svg";

import Link from "assets/icons/link.svg";
import Link2 from "assets/icons/link2.svg";
import Copy2 from "assets/icons/copy2.svg";
import Print2 from "assets/icons/print2.svg";

import Envelope from "assets/icons/envelope.svg";
import IdCard from "assets/icons/id-card.svg";

import FileBold from "assets/icons/filetypes/file-bold.svg";

import CloseCross from "assets/icons/close-cross.svg";
import CloseCrossBold from "assets/icons/crossBold.svg";

import Check from "assets/icons/check.svg";
import CheckBold from "assets/icons/check2.svg";
import Share from "assets/icons/share.svg";

const iconMap: any = {
  Smile,
  Frown,
  Meh,
  GrinBeam,
  GrinTears,
  GrinHearts,
  Heart,
  Sun,
  Moon,
  Cloud,
  Umbrella,
  Star,
  Leaf,
  Running,
  Hiking,
  Yoga,
  Stethoscope,
  Brain,
  Comments,
  BookOpen,
  Music,
  Pen,
  PaintBrush,
  Lightbulb,
  PuzzlePiece,
  Mountain,
  HandsHelping,
  ThumbsUp,
  ThumbsDown,
  LifeRing,
  ThoughtRecord,
  MoodTracker,
  JournalIcon,
  SafetyPlanIcon,
  GratitudeJournalIcon,
  SocraticQuestionsIcon,
  CognitiveRestructuringIcon,
  CognitiveTriangleIcon,
  GoalJournalIcon,
  WorryJournalIcon,
  UpcomingSessionIcon,
  PostSessionIcon,
  SensesExerciseIcon,
  DraftIcon,
  ArrowDown,
  Schedule,
  Configure,
  Preview,
  Remove,
  ChangeIcon,
  CancelArrow,
  HideIcon,
  ArrowBack,
  Bell,
  Puzzle,
  Rook,
  Gamer,
  Dice,
  DandD,
  Robot,
  Clover,
  Gameboy,
  Castle,
  Ghost,
  Icecream,
  Apple,
  Snowman,
  Unicorn,
  Whale,
  Mitten,
  Truck,
  Car,
  Bus,
  Cactus,
  Football,
  Bowling,
  Helmet,
  Flag,
  Rocket,
  Bulb,
  Guitar,
  Candycane,
  Carrot,
  Glasses,
  Cowboy,
  Television,
  Trash,
  Link,
  Link2,
  Copy2,
  Print2,
  Envelope,
  IdCard,
  FileBold,
  CloseCross,
  Check,
  CheckBold,
  CloseCrossBold,
  Share,
};

export default iconMap;
