import "./styles.scss";
import React from "react";

interface AuthenticationHeaderProps {
  headline?: string;
  subline: string;
}

const AuthenticationHeader = ({
  headline,
  subline,
}: AuthenticationHeaderProps) => {
  return (
    <section id="header" className="authentication-header" role="banner">
      {headline && (
        <h1 className="h1-1 authentication-header__headline" aria-level={1}>
          {headline}
        </h1>
      )}
      <h2 className="h2-1 authentication-header__subline" aria-level={2}>
        {subline}
      </h2>
    </section>
  );
};

export default AuthenticationHeader;
