import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useFetchLibrary } from "utils/hooks";

interface UseGetTherapistLibraryProps {
  groupId?: string;
}

const useGetGroupLibrary = ({ groupId }: UseGetTherapistLibraryProps) => {
  const { fetchGroupLibrary } = useFetchLibrary();

  const resources = useSelector(
    (state: RootStateOrAny) => state.resources && state.resources
  );

  useEffect(() => {
    fetchGroupLibrary(groupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  return {
    groupResources: resources?.groupResources || [],
  };
};

export default useGetGroupLibrary;
