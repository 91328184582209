import "./DaysOfWeekField.styles.scss";
import React, { useEffect, useState } from "react";
import { daysOfWeekFormatter, getDayDisplay } from "utils/helpers";
import { useTranslation } from "react-i18next";

interface DaysOfWeekFieldProps {
  name: string;
  label: string;
  error?: string;
  onChange: (value: number[]) => void;
  hint?: string;
  placeholder: string;
  defaultValue: string[] | number[];
  extraClass?: string;
  disabled?: boolean;
  mustHaveOneOrMore: boolean;
}

const DaysOfWeekField = ({
  name,
  label,
  error,
  onChange,
  hint,
  placeholder,
  defaultValue,
  extraClass,
  disabled,
  mustHaveOneOrMore,
}: DaysOfWeekFieldProps) => {
  const { t } = useTranslation(["common"]);
  const defaultDays = [0, 1, 2, 3, 4, 5, 6];
  const defaultValueAsNumbers = daysOfWeekFormatter(defaultValue);
  const [selectedDays, setSelectedDays] = useState(
    defaultValueAsNumbers || defaultDays
  );
  const [noDaysError, setNoDaysError] = useState(false);

  const handleDayClick = (selected: boolean, day: number) => {
    if (!disabled) {
      const currentSelectedDays = [...selectedDays];
      if (selected) {
        const index = currentSelectedDays.indexOf(day);
        if (index > -1) {
          currentSelectedDays.splice(index, 1);
        }
      }

      if (!selected) {
        currentSelectedDays.push(day);
      }
      setSelectedDays(currentSelectedDays);
      if (onChange) {
        onChange(currentSelectedDays);
      }
    }
  };

  useEffect(() => {
    if (selectedDays.length === 0) {
      setNoDaysError(true);
    } else {
      setNoDaysError(false);
    }
  }, [selectedDays]);
  return (
    <div className={`days_of_week-field ${disabled ? "disabled" : ""}`}>
      <label className={`days_of_week-field__label ${extraClass}`}>
        {label}
      </label>
      <div className="day-chooser__wrapper">
        {defaultDays.map((day) => {
          const selected = selectedDays.includes(day);
          return (
            <div
              key={day}
              onClick={() => handleDayClick(selected, day)}
              onKeyDown={() => handleDayClick(selected, day)}
              title={getDayDisplay(day).full}
              role="button"
              tabIndex={0}
              className={`day-chooser__option ${selected ? "selected" : ""}`}
            >
              {getDayDisplay(day).initials}
            </div>
          );
        })}
      </div>
      <input
        name={name}
        aria-label={label}
        className={`days_of_week-field__field ${error ? "error" : ""}`}
        value={selectedDays[0]}
        hidden
        readOnly
        placeholder={placeholder}
      />
      {error && (
        <p className="days_of_week-field__error">
          <small>{error}</small>
        </p>
      )}
      {noDaysError && mustHaveOneOrMore && (
        <p className="days_of_week-field__error">
          <small>{t("calls_to_action.one_or_more_days")}</small>
        </p>
      )}
      {!error && hint && (
        <p className="days_of_week-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default DaysOfWeekField;
