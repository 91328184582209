import React from "react";
import { Field } from "types";

interface Props {
  field: Field;
  defaultValue: any;
  onChange: (value: any) => void;
  error?: string;
}
const TextAreaField = ({ field, defaultValue, onChange }: Props) => {
  return (
    <div className="textarea-field-new">
      <label className="field__label">
        {field.label} {field.required ? "" : "(Optional)"}
      </label>
      <textarea
        rows={6}
        aria-label={field.label}
        placeholder={field.placeholder || ""}
        className={`field__field ${field.error ? "error" : ""}`}
        defaultValue={defaultValue}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default TextAreaField;
