// IconPicker.tsx
import React from "react";
import { Icon } from "components";
import "./IconPicker.styles.scss";
import { defaultIcons } from "./DefaultIcons";

export interface IconType {
  name?: string;
  src?: string;
  label?: JSX.Element;
  value?: string;
}

interface IconPickerProps {
  selectedIcon?: { name?: string; src?: string };
  onIconChange: (icon: IconType) => void;
  icons?: IconType[];
  hint?: string;
}

const IconPicker: React.FC<IconPickerProps> = ({
  selectedIcon,
  onIconChange,
  icons,
  hint,
}) => {
  const iconChoices = icons || defaultIcons;

  const handleIconChange = (icon: IconType) => {
    onIconChange(icon);
  };

  return (
    <div className="icon-picker-wrapper">
      {/* Display Current Icon */}
      <div className="current-icon-display">
        <label className="color-icon-label">Select an icon:</label>
        {hint && <span className="icon-hint-text">{hint}</span>}
        {/* <div className="current-icon">
          {selectedIcon && <Icon src={selectedIcon.src} />}
          <span>{selectedIcon?.name}</span>
        </div> */}
      </div>

      {/* Icon Gallery */}
      <div className="icon-gallery">
        {iconChoices.map((icon, index) => (
          <div
            key={index}
            className={`icon-item ${
              selectedIcon?.name === icon.name ? "selected" : ""
            }`}
            onClick={() => handleIconChange(icon)}
            onKeyDown={() => handleIconChange(icon)}
            tabIndex={0}
            role="button"
          >
            <Icon src={icon.src} />
            {/* <span className="icon-name">{icon.name}</span> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IconPicker;
