import "./RemoveMemberConfirmationModal.styles.scss";
import React, { useState, useEffect } from "react";
import { Icon, Button, Checkbox } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";
import { useRemoveUserGromGroup } from "utils/hooks";
import { User } from "types";
import { useTranslation } from "react-i18next";

interface RemoveMemberConfirmationModalProps {
  groupId: string;
  member: User;
  onCancel: () => void;
}

const RemoveMemberConfirmationModal = ({
  groupId,
  member,
  onCancel,
}: RemoveMemberConfirmationModalProps) => {
  const { removeUserFromGroup } = useRemoveUserGromGroup();
  const { t } = useTranslation(["common"]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isUnderstood, setIsUnderstood] = useState(false);

  const isMember = member.role === "member";
  const isModerator = member.role === "moderator";

  useEffect(() => {
    if (isMember && isConfirmed && isUnderstood) {
      setIsDisabled(false);
    } else if (isModerator && isUnderstood) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [isConfirmed, isMember, isModerator, isUnderstood]);

  return (
    <div className="member-removal-confirmation">
      <div className="member-removal-confirmation__icon">
        <Icon src={WarningIcon} />
      </div>
      <div className="member-removal-confirmation__title">
        Are you sure you want to remove this {member.role} from group?
      </div>
      <div className="member-removal-confirmation__body">
        {isMember && (
          <span>
            {member?.firstName} will no longer be connected to group and you
            will no longer see submissions from them. <br />
            They can still access their group assignments.
          </span>
        )}
        {isModerator && (
          <span>
            They will no longer be connected to group and will not be able to
            moderate it.
          </span>
        )}
      </div>
      <div className="member-removal-confirmation__checkboxes">
        <Checkbox
          name="understand"
          label={t("confirmations.confirm_delete_checkbox1")}
          ariaLabel={t("confirmations.confirm_delete_checkbox1")}
          onChange={(e) => setIsUnderstood(e.target.checked)}
        />
        {isMember && (
          <Checkbox
            name="confirm"
            label={t("confirmations.confirm_delete_checkbox2", {
              label: t("groups.member_label"),
            })}
            ariaLabel={t("confirmations.confirm_delete_checkbox2", {
              label: t("groups.member_label"),
            })}
            onChange={(e) => setIsConfirmed(e.target.checked)}
          />
        )}
      </div>
      <div className="member-removal-confirmation__buttons">
        <Button
          onClick={onCancel}
          name="cancel_remove_member_btn"
          value="Never mind"
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => {
            removeUserFromGroup({
              member,
              groupId,
            });
            onCancel();
          }}
          name="confirm_remove_member_btn"
          value="Yes, remove them"
          extraClass="danger"
          size="small"
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default RemoveMemberConfirmationModal;
