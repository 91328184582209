import "./styles.scss";
import React, { useEffect } from "react";
import { useCurrentUser } from "utils/hooks";
import { persistor } from "store";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const PaymentRedirect = () => {
  const { user, isTherapist, isSubscribed, isAuthenticated } = useCurrentUser();
  const history = useNavigate();

  useEffect(() => {
    if (isTherapist && !isSubscribed) {
      ReactGA.event("navigating_to_stripe_payment", {
        user_id: isAuthenticated ? user?.id : "action_when_unauthenticated",
      });
      localStorage.clear();
      persistor.purge();
      window.location.href = `${process.env.REACT_APP_STRIPE_PLAN_URL}?prefilled_email=${user?.email}`;
    } else {
      history("/dashboard");
    }
  }, [isTherapist, isSubscribed, user, history, isAuthenticated]);

  return (
    <div className="flex align-center justify-center col not-found-page">
      <h2 className="h2-2 headline">Please wait...</h2>
    </div>
  );
};

export default PaymentRedirect;
