import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./ReminderSettings.styles.scss";
import {
  Button,
  DateField,
  DaysOfWeekField,
  TimeField,
  Toggle,
  AlertBar,
  Input,
} from "components";
import ReminderAlertContent from "../../../ReminderAlertContent";
import { isValidDate } from "utils/helpers";
import { useGetAssignments, useUpdateNotification } from "utils/hooks";

interface ReminderSettingProps {
  groupName?: string;
  onChange?: (values: any) => void;
  entityId: string;
  canSaveSettings?: boolean;
  setCanSaveSettings?: (data: boolean) => void;
}
interface ValueChangeProps {
  newValue: string | number | string[] | number[] | Date;
  fieldName: string;
}
interface ValidationErrors {
  startDate?: string;
  notificationTime?: string;
  notificationMessage?: string;
}

const ReminderSettings = ({
  groupName,
  onChange,
  entityId,
  canSaveSettings,
  setCanSaveSettings,
}: ReminderSettingProps) => {
  const { assignment } = useGetAssignments({ assignmentId: entityId });
  const {
    daysOfWeek,
    notificationTime,
    startDate,
    endDate,
    status,
    notificationMessage,
  } = assignment.notification;

  const formattedNotificationTime = Array.isArray(notificationTime)
    ? notificationTime.map((time: string) => new Date(time))
    : [notificationTime].map((time: string) => new Date(time));
  const formattedStartDate = useMemo(() => new Date(startDate), [startDate]);
  const formattedEndDate = useMemo(() => new Date(endDate), [endDate]);

  const [currentNotificationTime, setCurrentNotificationTime] = useState(
    formattedNotificationTime
  );
  const [currentDaysOfWeek, setCurrentDaysOfWeek] = useState(daysOfWeek);
  const [currentStartDate, setCurrentStartDate] = useState(formattedStartDate);
  const [currentEndDate, setCurrentEndDate] = useState(formattedEndDate);
  const [currentStatus, setCurrentStatus] = useState(status);
  const [currentNotificationMessage, setCurrentNotificationMessage] =
    useState(notificationMessage);
  const [errors, setErrors] = useState<any>({});
  const {
    updateGroupNotification,
    unsavedReminderSettings,
    updateUnsavedRemindSettings,
  } = useUpdateNotification();

  const isDisabled = currentStatus === "DISABLED";

  const handleStatusChange = () => {
    const newStatus = currentStatus === "LIVE" ? "DISABLED" : "LIVE";
    setCurrentStatus(newStatus);
    handleValueChange({ newValue: newStatus, fieldName: "status" });
  };

  const getUnsavedChanges = useCallback(() => {
    [
      "daysOfWeek",
      "notificationTime",
      "startDate",
      "endDate",
      "status",
      "notificationMessage",
    ].map((fieldName) => {
      let oldData;
      let newData;
      switch (fieldName) {
        case "daysOfWeek":
          oldData = String(
            [...daysOfWeek][0]
              .split(",")
              .map((el: string) => Number(el))
              .sort()
          );
          newData = String((currentDaysOfWeek as number[]).sort());
          break;
        case "notificationTime":
          oldData = JSON.stringify(
            [...formattedNotificationTime].sort().map((time) => {
              return time.toLocaleTimeString("en-US", {
                timeZone: "America/New_York",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              });
            })
          );
          newData = JSON.stringify(
            [...currentNotificationTime].sort().map((time) => {
              return time.toLocaleTimeString("en-US", {
                timeZone: "America/New_York",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              });
            })
          );
          break;
        case "startDate":
          oldData = `${formattedStartDate.toDateString()}`;
          newData = `${currentStartDate.toDateString()}`;
          break;
        case "endDate":
          oldData = `${formattedEndDate.toDateString()}`;
          newData = `${currentEndDate.toDateString()}`;
          break;
        case "status":
          oldData = status;
          newData = currentStatus;
          break;
        case "notificationMessage":
          oldData = notificationMessage;
          newData = currentNotificationMessage;
          break;
      }
      const includesCurrentChange = [...unsavedReminderSettings].includes(
        fieldName
      );
      if (oldData !== newData) {
        !includesCurrentChange &&
          updateUnsavedRemindSettings([...unsavedReminderSettings, fieldName]);
      } else {
        includesCurrentChange &&
          updateUnsavedRemindSettings(
            [...unsavedReminderSettings].filter((el) => el !== fieldName)
          );
      }
    });
  }, [
    daysOfWeek,
    currentDaysOfWeek,
    formattedNotificationTime,
    currentNotificationTime,
    formattedStartDate,
    currentStartDate,
    formattedEndDate,
    currentEndDate,
    status,
    currentStatus,
    notificationMessage,
    currentNotificationMessage,
    unsavedReminderSettings,
    updateUnsavedRemindSettings,
  ]);

  useEffect(() => {
    getUnsavedChanges();
  }, [
    daysOfWeek,
    notificationTime,
    startDate,
    endDate,
    status,
    notificationMessage,
    getUnsavedChanges,
  ]);

  const handleValueChange = ({ newValue, fieldName }: ValueChangeProps) => {
    if (!entityId) {
      onChange &&
        onChange({
          daysOfWeek: fieldName === "daysOfWeek" ? newValue : currentDaysOfWeek,
          notificationTime:
            fieldName === "notificationTime"
              ? currentNotificationTime
              : currentNotificationTime,
          startDate: fieldName === "startDate" ? newValue : currentStartDate,
          endDate: fieldName === "endDate" ? newValue : currentEndDate,
          status: fieldName === "status" ? newValue : currentStatus,
          notificationMessage:
            fieldName === "notificationMessage"
              ? newValue
              : currentNotificationMessage,
        });
    }
    if (errors[fieldName]) {
      setErrors({ ...errors, [fieldName]: "" });
    }
  };

  const validateFields = useCallback((): ValidationErrors => {
    const newErrors: ValidationErrors = {};
    if (!currentStartDate) {
      newErrors.startDate = "Start date is required";
    }
    if (!currentNotificationTime) {
      newErrors.notificationTime = "Notification time is required.";
    }
    if (!currentNotificationMessage) {
      newErrors.notificationMessage = "Notification message is required.";
    }
    return newErrors;
  }, [currentStartDate, currentNotificationTime, currentNotificationMessage]);

  const handleSubmit = useCallback(() => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      updateGroupNotification({
        entityId: entityId,
        daysOfWeek: currentDaysOfWeek,
        notificationTime: currentNotificationTime,
        startDate: currentStartDate,
        endDate: currentEndDate,
        status: currentStatus,
        notificationMessage: currentNotificationMessage,
        groupId: assignment.groupId,
      });
    }
  }, [
    validateFields,
    updateGroupNotification,
    entityId,
    currentDaysOfWeek,
    currentNotificationTime,
    currentStartDate,
    currentEndDate,
    currentStatus,
    currentNotificationMessage,
    assignment.groupId,
  ]);

  useEffect(() => {
    if (canSaveSettings) {
      setCanSaveSettings && setCanSaveSettings(false);
      handleSubmit();
    }
  }, [canSaveSettings, setCanSaveSettings, handleSubmit]);

  const handleChangeNotifTime = (
    action: string,
    index?: number,
    value?: Date
  ) => {
    let newTimeArr: Array<Date> | [] = [];
    switch (action) {
      case "ADD":
        newTimeArr = [...currentNotificationTime, new Date()];
        break;
      case "DELETE":
        newTimeArr = currentNotificationTime.filter(
          (_: any, i: number) => i !== index
        );
        break;
      case "UPDATE":
        newTimeArr = [...currentNotificationTime];
        newTimeArr[index as number] = value as Date;
        break;
    }
    setCurrentNotificationTime(newTimeArr);
  };

  return (
    <div>
      <div className="flex justify-between align-center mb-2">
        <h4 className="no-mt no-mb">Reminders</h4>
        <Toggle
          name="status"
          checked={currentStatus === "LIVE"}
          onChange={handleStatusChange}
        />
      </div>
      {isDisabled && (
        <div className="mt-3">
          <AlertBar
            content={<ReminderAlertContent isDisabled={isDisabled} />}
            type="info"
            rounded
            padded
          />
        </div>
      )}
      <div>
        <DateField
          name="start_date"
          dateFormat="MM/dd/yyyy"
          label="Start Date"
          placeholder="Start Date"
          selected={currentStartDate}
          onChange={(value: any) => {
            setCurrentStartDate(value);
            handleValueChange({
              newValue: value,
              fieldName: "startDate",
            });
          }}
          minDate={new Date()}
          hint={`When should ${
            groupName ? `${groupName} begin.` : "this assignment begin."
          }`}
          disabled={isDisabled}
          error={errors.startDate}
        />
        <DateField
          name="end_date"
          dateFormat="MM/dd/yyyy"
          label="End Date"
          placeholder="End Date"
          selected={isValidDate(currentEndDate)}
          onChange={(value: any) => {
            setCurrentEndDate(value);
            handleValueChange({
              newValue: value,
              fieldName: "endDate",
            });
          }}
          minDate={new Date()}
          hint={`Optional: When should ${
            groupName ? `${groupName} finish.` : "this assignment end."
          }`}
          disabled={isDisabled}
        />
        <h3 className="time-title">How often should this be done?</h3>
        {currentNotificationTime &&
          currentNotificationTime.map((time: Date, index: number) => (
            <div className="flex align-start" key={index}>
              <TimeField
                key={String(time)}
                name="notification_time"
                placeholder="Daily Reminder Time"
                extraClass="time-field__reminders"
                selected={time}
                onChange={(value) => {
                  handleChangeNotifTime("UPDATE", index, value);
                  handleValueChange({
                    newValue: value,
                    fieldName: "notificationTime",
                  });
                }}
                disabled={isDisabled}
                error={errors.notificationTime}
              />
              {index !== 0 && (
                <Button
                  name="remove_notification_time_button"
                  value={""}
                  icon={"Trash"}
                  extraClass="button-icon regular-size"
                  onClick={() => handleChangeNotifTime("DELETE", index)}
                />
              )}
            </div>
          ))}
        <button
          className="button_add-time-input"
          onClick={() => handleChangeNotifTime("ADD")}
          disabled={isDisabled}
          style={{ opacity: isDisabled ? 0.5 : 1 }}
        >
          Add another daily time
        </button>
        <DaysOfWeekField
          name="days_of_week"
          label="Every"
          placeholder="Daily Reminder Time"
          defaultValue={currentDaysOfWeek}
          onChange={(value) => {
            setCurrentDaysOfWeek(value);
            handleValueChange({
              newValue: value,
              fieldName: "daysOfWeek",
            });
          }}
          disabled={isDisabled}
          mustHaveOneOrMore
        />
        <Input
          extraClass="light"
          name="notification_message"
          type="text"
          label="Reminder Message"
          defaultValue={currentNotificationMessage}
          placeholder="E.g. Don't forget to fill in your thought record! You got this!"
          onChange={(e) => {
            setCurrentNotificationMessage(e.target.value);
            handleValueChange({
              newValue: e.target.value,
              fieldName: "notificationMessage",
            });
          }}
          error={errors.notificationMessage}
          disabled={isDisabled}
        />
        {entityId && unsavedReminderSettings?.length !== 0 && (
          <Button
            onClick={handleSubmit}
            name={`save_assignment_notifications_button`}
            value="Save Reminder Settings"
            extraClass="save-settings"
            size="big"
          />
        )}
      </div>
    </div>
  );
};

export default ReminderSettings;
