import "./Input.styles.scss";
import React, { ReactNode } from "react";
import { InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  type?: string;
  placeholder?: string;
  label?: string;
  error?: string | any;
  forwardRef?: any;
  hint?: string | ReactNode;
  videoValidation?: boolean;
  defaultValue?: string | number;
  optionalField?: boolean;
  extraClass?: string;
  inputExtraClass?: string;
}

const Input = ({
  label,
  error,
  forwardRef,
  hint,
  type = "text",
  videoValidation,
  optionalField = false,
  extraClass,
  inputExtraClass,
  ...props
}: InputProps) => {
  const validateVideoUrl = (value: any): string | undefined => {
    if (videoValidation && value) {
      const youtubeRegex =
        /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
      const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/.+$/;
      const facebookRegex = /^(https?:\/\/)?(www\.)?(facebook\.com)\/.+$/;
      const soundcloudRegex = /^(https?:\/\/)?(www\.)?(soundcloud\.com)\/.+$/;
      const wistiaRegex = /^(https?:\/\/)?(www\.)?(wistia\.com|wi\.st)\/.+$/;
      const mixcloudRegex = /^(https?:\/\/)?(www\.)?(mixcloud\.com)\/.+$/;
      const dailymotionRegex = /^(https?:\/\/)?(www\.)?(dailymotion\.com)\/.+$/;
      const twitchRegex = /^(https?:\/\/)?(www\.)?(twitch\.tv)\/.+$/;

      if (
        !youtubeRegex.test(value) &&
        !vimeoRegex.test(value) &&
        !facebookRegex.test(value) &&
        !soundcloudRegex.test(value) &&
        !wistiaRegex.test(value) &&
        !mixcloudRegex.test(value) &&
        !dailymotionRegex.test(value) &&
        !twitchRegex.test(value)
      ) {
        return "Please enter a valid URL from YouTube, Facebook, SoundCloud, Vimeo, Wistia, Mixcloud, Dailymotion, or Twitch";
      }
    }
    return undefined;
  };

  const currentError = error || validateVideoUrl(props.defaultValue);

  return (
    <div className={`input ${extraClass ? extraClass : ""}`}>
      <label className="input__label">
        {label}
        {optionalField && (
          <span className="input__optional-label"> (optional)</span>
        )}
      </label>
      <input
        aria-label={label}
        className={`input__field ${currentError ? "error" : ""} ${
          inputExtraClass ? inputExtraClass : ""
        }`}
        ref={forwardRef}
        type={type}
        {...props}
      />
      {currentError && (
        <p className="input__error">
          <small>{currentError}</small>
        </p>
      )}
      {!currentError && hint && (
        <p className="input__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default Input;
