import "./TurnOff2FASettingConfirmation.styles.scss";
import React from "react";
import { Icon, Button } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";
import { useTranslation } from "react-i18next";

interface TurnOff2FASettingConfirmationProps {
  onTurnOff2FASetting: () => void;
  onCancel: () => void;
}

const TurnOff2FASettingConfirmation = ({
  onTurnOff2FASetting,
  onCancel,
}: TurnOff2FASettingConfirmationProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="turn-off-2fa-confirmation">
      <div className="turn-off-2fa-confirmation__icon">
        <Icon src={WarningIcon} />
      </div>
      <div className="turn-off-2fa-confirmation__title">
        {t("profile.2fa_confirmation_modal.confirm_turn_off")}
      </div>
      <div className="turn-off-2fa-confirmation__body">
        <p>{t("profile.2fa_confirmation_modal.2fa_security")}</p>
        <p>{t("profile.2fa_confirmation_modal.turn_off_warning")}</p>
        <p>{t("profile.2fa_confirmation_modal.proceed_turn_off")}</p>
      </div>
      <div className="turn-off-2fa-confirmation__buttons">
        <Button
          onClick={onCancel}
          name="cancel_turn_off_2fa_button"
          value={t("profile.2fa_confirmation_modal.keep_2fa_on_button")}
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => {
            onTurnOff2FASetting();
            onCancel();
          }}
          name="confirm_turn_off_2fa_button"
          value={t("profile.2fa_confirmation_modal.turn_off_2fa_button")}
          extraClass="danger"
          size="small"
        />
      </div>
    </div>
  );
};

export default TurnOff2FASettingConfirmation;
