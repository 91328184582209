import "./Divider.styles.scss";
import React from "react";

interface DividerProps {
  small?: boolean;
  vertical?: boolean;
}

const Divider: React.FC<DividerProps> = ({ small, vertical }) => {
  return (
    <div
      className={`divider ${small ? "small" : ""} ${
        vertical ? "vertical" : "horizontal"
      }`}
    />
  );
};

export default Divider;
