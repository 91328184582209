/* eslint-disable react-hooks/exhaustive-deps */
import "./SubmissionsPage.styles.scss";
import React, { useEffect } from "react";
import { PageActionBar, PageTitle, TopNav } from "components";
import SubmissionsTable from "./components/SubmissionsTable";
import SubmissionsTableSearch from "./components/SubmissionsTableSearch";
import SubmissionsTableEmptyState from "./components/SubmissionsTableEmptyState";
import { useCurrentUser, useViewport, useRefreshUser } from "utils/hooks";
import { useParams } from "react-router-dom";
import SubmissionsFilter from "./components/SubmissionsFilter";
import useSubmissionUtils from "./utils";
import SubmissionsTablePatientEmptyState from "./components/SubmissionsTablePatientEmptyState";

interface SubmissionPageProps {
  title: string;
  submissionDataColumns: any;
}

const SubmissionsPage = ({
  title,
  submissionDataColumns,
}: SubmissionPageProps) => {
  const { slug } = useParams();
  const { isDesktop } = useViewport();
  const { isTherapist } = useCurrentUser();

  const { submitRefreshRequest } = useRefreshUser();

  useEffect(() => {
    submitRefreshRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    submissions,
    currentPatient,
    handleSearch,
    handlePatientChange,
    sortedSubmissions,
    showFilter,
    showTableElements,
  } = useSubmissionUtils();

  return (
    <>
      <TopNav
        topL={
          <div className="flex align-center">
            <PageTitle text={title} />
          </div>
        }
      />
      <div className="submissions-page">
        {isDesktop && isTherapist && (
          <PageActionBar>
            <div id="submissions-page-left" className="flex">
              {showTableElements && (
                <div>
                  <SubmissionsTableSearch
                    data={submissions}
                    patientId={currentPatient?.value}
                    onChange={handleSearch}
                  />
                </div>
              )}
            </div>
            {showFilter && (
              <SubmissionsFilter
                currentPatient={currentPatient}
                onChange={(e: any) => {
                  handlePatientChange(e);
                }}
              />
            )}
          </PageActionBar>
        )}
        {showTableElements ? (
          <SubmissionsTable
            data={sortedSubmissions}
            submissionDataColumns={submissionDataColumns}
            slug={slug}
            currentPatient={currentPatient?.value}
          />
        ) : (
          <>
            {isTherapist ? (
              <SubmissionsTableEmptyState />
            ) : (
              <SubmissionsTablePatientEmptyState />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SubmissionsPage;
