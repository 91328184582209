import React from "react";

interface ParagraphFieldProps {
  uuid?: string;
  label: string;
  inlineValue: string | undefined;
  showLabelForBuilder?: boolean;
}

const ParagraphField = ({
  label,
  inlineValue,
  showLabelForBuilder,
}: ParagraphFieldProps) => {
  return (
    <div>
      {showLabelForBuilder && <p>{label}</p>}
      <p>{inlineValue}</p>
      {!inlineValue || inlineValue === "" ? (
        <small>
          <i>Add content in field settings</i>
        </small>
      ) : undefined}
    </div>
  );
};

export default ParagraphField;
