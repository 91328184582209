import { PURGE } from "redux-persist";

export default (state = [], { type, payload }) => {
  switch (type) {
    case "CREATE_MOOD_SCALE":
      return [...state, payload];
    case "UPDATE_MOOD_SCALE":
      return state.map((item) =>
        item.uuid === payload.uuid ? { ...item, ...payload } : item
      );
    case "DELETE_MOOD_SCALE":
      return state.filter((item) => item.uuid !== payload.uuid);
    case "FETCH_ALL_MOOD_SCALES":
      return payload;
    case PURGE:
      return [];
    default:
      return state;
  }
};
