import { PURGE } from "redux-persist";

const initialState = {
  showConfettiCanvas: false,
  currentTrial: null,
  isUnsubscribed: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SHOW_CONFETTI_CANVAS":
      return {
        ...state,
        showConfettiCanvas: payload,
      };
    case "SET_CURRENT_TRIAL":
      return {
        ...state,
        currentTrial: payload,
      };
    case "SET_UNSUBSCRIBED":
      return {
        ...state,
        isUnsubscribed: payload,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
