import "./WorkbookEmptyState.styles.scss";
import React from "react";
import BalloonTwo from "assets/images/balloon-2.png";

const WorkbookEmptyState = ({ group }) => {
  return (
    <div className="workbook-empty-state">
      <h3>{group.name} doesn&apos;t have any assignments yet.</h3>
      <p>Add an assignment using the &quot;Add Assignment&quot; button above</p>
      <div className="workbook-empty-state__image-holder">
        <img
          className="workbook-empty-state__image"
          src={BalloonTwo}
          alt="green and purple balloon"
        />
      </div>
    </div>
  );
};

export default WorkbookEmptyState;
