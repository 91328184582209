import "./DeleteInviteCodeConfirmation.styles.scss";
import React from "react";
import { Icon, Button } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";
import { useTranslation } from "react-i18next";

interface DeleteInviteCodeConfirmationProps {
  onCancel: () => void;
  onDelete: () => void;
}

const DeleteInviteCodeConfirmation = ({
  onCancel,
  onDelete,
}: DeleteInviteCodeConfirmationProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="delete-invite-code-confirmation">
      <div className="delete-invite-code-confirmation__icon">
        <Icon src={WarningIcon} />
      </div>
      <div className="delete-invite-code-confirmation__title">
        {t("confirmations.confirm_delete", {
          label: t("invite_code.qr_code_label"),
        })}
      </div>
      <div className="delete-invite-code-confirmation__body">
        {t("invite_code.confirm_delete_body")}
      </div>
      <div className="delete-invite-code-confirmation__buttons">
        <Button
          onClick={onCancel}
          name="cancel_delete_invite_code_button"
          value={t("buttons.never_mind")}
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => onDelete()}
          name="confirm_delete_invite_code_button"
          value={t("buttons.confirm_delete")}
          extraClass="danger"
          size="small"
        />
      </div>
    </div>
  );
};

export default DeleteInviteCodeConfirmation;
