import ReactGA from "react-ga4";
import {
  deleteLibraryResourceAction,
  deleteGroupLibraryResourceAction,
} from "store/actions/library";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useFetchLibrary, useLogout, useCurrentUser } from "utils/hooks";

const useDeleteLibraryResource = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchTherapistLibrary, fetchGroupLibrary } = useFetchLibrary();
  const { user } = useCurrentUser();

  const deleteLibraryResource = (resource) => {
    if (!resource) {
      return null;
    }

    return new Promise(() => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
            mutation {
              deleteLibraryResource(uuid: "${resource.uuid}")
            }
          `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(`Hmm, something went wrong.`);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
            } else {
              return dispatch(
                deleteLibraryResourceAction(resData.data.deleteLibraryResource)
              );
            }
          })
          .then((res) => {
            if (res) {
              fetchTherapistLibrary();
              ReactGA.event("library_resource_removed", {
                user_id: user?.id,
                resource_id: resource.uuid,
              });
              toast(`Library resource deleted :)`);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  const deleteGroupLibraryResource = (resource) => {
    if (!resource) {
      return null;
    }

    return new Promise(() => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
            mutation {
              deleteLibraryResource(uuid: "${resource.uuid}")
            }
          `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(`Hmm, something went wrong.`);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
            } else {
              return dispatch(
                deleteGroupLibraryResourceAction(
                  resData.data.deleteLibraryResource
                )
              );
            }
          })
          .then((res) => {
            if (res) {
              fetchGroupLibrary(resource.groupId);
              ReactGA.event("library_resource_removed", {
                user_id: user?.id,
                resource_id: resource.uuid,
                group_id: resource.groupId,
              });
              toast(`Library resource deleted :)`);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    deleteLibraryResource,
    deleteGroupLibraryResource,
  };
};

export default useDeleteLibraryResource;
