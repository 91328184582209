/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import "./DropdownMenu.styles.scss";
import React from "react";
import "./DropdownMenu.styles.scss";
import { Icon } from "components";
import AngleDown from "assets/icons/angle-down.svg";
import useClickOutside from "utils/hooks/useClickOutside";

interface DropdownMenuProps {
  title: React.ReactNode;
  children: React.ReactNode;
  closeOnClick?: boolean;
  extraClass?: string;
}

const DropdownMenu = ({
  title,
  children,
  closeOnClick = false,
  extraClass,
}: DropdownMenuProps) => {
  const { node, isNodeVisible, setIsNodeVisible } = useClickOutside();

  const handleDropdownTrigger = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent triggering click outside
    setIsNodeVisible(!isNodeVisible);
  };

  const handleClickInside = (event: React.MouseEvent) => {
    if (closeOnClick) {
      event.stopPropagation(); // Prevent triggering click outside
      setIsNodeVisible(false);
    }
  };

  return (
    <div ref={node} className={`dropdown-menu ${extraClass}`}>
      <div className="dropdown-menu__title" onClick={handleDropdownTrigger}>
        {title}
        <div className="dropdown-menu__title-icon">
          <Icon src={AngleDown} />
        </div>
      </div>
      {isNodeVisible && (
        <div
          className="dropdown-menu__body dropdown-menu__body-open"
          onClick={handleClickInside}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
