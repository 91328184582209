import "./Radio.styles.scss";
import React from "react";

interface RadioProps {
  choices?: any[];
  label?: string;
  error?: string;
  forwardRef?: any;
  hint?: string;
  variant?: string;
  inputName?: string;
}

const Radio = ({
  choices,
  label,
  error,
  forwardRef,
  hint,
  variant,
  inputName,
  ...props
}: RadioProps) => {
  return (
    <div className="radio">
      <div className={`radio__label ${variant ? variant : ""}`}>{label}</div>
      <div className={`radio__choices ${variant ? variant : ""}`}>
        {choices?.map((choice) => {
          return (
            <div className="radio__field-wrapper" key={choice.label}>
              <input
                type="radio"
                name={inputName}
                aria-label={choice.label}
                id={choice.label}
                value={choice.value}
                className={`radio__field ${error ? " error" : ""}`}
                ref={forwardRef}
                checked={choice.checked}
                {...props}
                {...(choice.options || {})}
              />
              <label htmlFor={inputName}>{choice.label}</label>
            </div>
          );
        })}
      </div>
      {error && (
        <p className="radio__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && (
        <p className="radio__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default Radio;
