import React, { useRef, useState } from "react";
import { Field, FieldOption } from "types";
import "./SelectField.styles.scss";

interface Props {
  field: Field;
  defaultValue: any;
  onChange: (value: any) => void;
  error?: string;
}
const SelectField = ({ field, defaultValue, onChange }: Props) => {
  field.options = field.options as FieldOption[];
  const options = field.options.map((option, index) => {
    if (!option.value && !option.label) {
      const newValue = `Option ${index + 1}`;
      return { label: newValue, value: newValue };
    }
    return { label: option.label, value: option.value };
  });
  options.unshift({ label: "", value: "" });
  const [value, setValue] = useState(defaultValue);
  const [showOptions, setShowOptions] = useState(false);
  const handleSelect = (value: any) => {
    document.removeEventListener("click", handleClickEvent);
    setValue(value);
    setShowOptions(false);
    onChange(value);
  };
  const handleClickEvent = (e: any) => {
    if (e.target.closest(".select-field-new")) {
      return;
    }

    setShowOptions(false);
    document.removeEventListener("click", handleClickEvent);
  };
  // useEffect(() => {
  //   document.addEventListener("click", handleClickEvent);
  //   return () => {
  //     document.removeEventListener("click", handleClickEvent);
  //   };
  // }, []);

  const handleShowOptions = () => {
    document.removeEventListener("click", handleClickEvent);
    if (optionsRef.current) {
      const optionsRect = optionsRef.current.getBoundingClientRect();
      const inputRect = inputRef.current?.getBoundingClientRect();
      if (optionsRect.bottom > window.innerHeight) {
        optionsRef.current.style.top = `-${
          optionsRect.height + 10 + (inputRect?.height || 0)
        }px`;
      }
    }
    setShowOptions(true);
    setTimeout(() => {
      document.addEventListener("click", handleClickEvent);
    }, 500);
  };
  const optionsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div>
      <div className="string-field-new  select-field-new">
        <label className="field__label">{field.label}</label>
        <div className={"input-wrapper"}>
          <input
            ref={inputRef}
            aria-label={field.label}
            placeholder={field.placeholder || ""}
            className={`field__field ${field.error ? "error" : ""}`}
            onFocus={handleShowOptions}
            value={value}
            onChange={() => null}
          />
          <span className={`arrow`}>
            <svg
              width="10"
              height="12"
              viewBox="0 0 20 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18.9492 1.96875L18.1328 1.10938C17.918 0.894531 17.5742 0.894531 17.4023 1.10938L9.625 8.88672L1.80469 1.10938C1.63281 0.894531 1.28906 0.894531 1.07422 1.10938L0.257812 1.96875C0.0429688 2.14062 0.0429688 2.48438 0.257812 2.69922L9.23828 11.6797C9.45312 11.8945 9.75391 11.8945 9.96875 11.6797L18.9492 2.69922C19.1641 2.48438 19.1641 2.14062 18.9492 1.96875Z" />
            </svg>
          </span>
        </div>
        <div className="options-wrapper">
          <div
            ref={optionsRef}
            className={`options-list ${showOptions ? "" : "hidden"}`}
          >
            {options.map((option: any) => {
              return (
                <button
                  type={"button"}
                  className="option"
                  key={option.value}
                  defaultValue={option.value}
                  onClick={() => handleSelect(option.value)}
                >
                  {option.label || option.value}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectField;
