import "./AppLayout.styles.scss";
import React, { useEffect, ReactNode, FC } from "react";
import { MobileBottomNav, SideNav } from "components";
import { useViewport, useGuestRedirect, useCurrentUser } from "utils/hooks";
import useSubmissionUtils from "pages/AppPages/Shared/Submissions/utils";
import {
  AssignmentContext,
  useAssignmentContext,
} from "pages/AppPages/PatientPages/AssignmentContextWrapper/useAssignmentContext";
import { useTopNavHeight } from "components/layout/TopNav/TopNavHeightContext";

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const { redirect } = useGuestRedirect();
  const { isMobile } = useViewport();
  const { topNavHeight } = useTopNavHeight();

  useEffect(() => {
    redirect();
  }, [redirect]);

  const MemoizedSideNav = React.memo(() => <SideNav />);
  MemoizedSideNav.displayName = "MemoizedSideNav";

  const { defaultValues } = useAssignmentContext();

  const { isVerified, isAuthenticated, user } = useCurrentUser();
  const { showConfirmEmailBar } = useSubmissionUtils();

  const showAlertBar =
    isAuthenticated && !isVerified && user && showConfirmEmailBar;

  return (
    <AssignmentContext.Provider value={defaultValues}>
      <div className="flex">
        {isMobile ? <MobileBottomNav /> : <MemoizedSideNav />}
        <div className="flex col app-body">
          <div
            className={`app-content topnav-content ${
              showAlertBar ? "hasAlertBar" : ""
            }`}
            style={{ marginTop: topNavHeight ? topNavHeight + 10 : 0 }}
          >
            {children}
          </div>
        </div>
      </div>
    </AssignmentContext.Provider>
  );
};

export default AppLayout;
