import { PURGE } from "redux-persist";

const initialState = {
  submissionStats: {},
};

export default (state = initialState, { type, payload }: any) => {
  switch (type) {
    case "FETCH_SUBMISSION_STATS":
      return {
        ...state,
        submissionStats: payload,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
