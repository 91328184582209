import "./SubmissionsFilter.styles.scss";
import { PatientsDropdown } from "components";
import React from "react";
import { useTranslation } from "react-i18next";

const SubmissionsFilter = ({ currentPatient, onChange }) => {
  const { t } = useTranslation(["common"]);
  return (
    <div className="submissions-filter-wrapper">
      <PatientsDropdown
        name="submissions-filter"
        label={null}
        includeEmail={false}
        placeholder={t("patients.filter_by_patient")}
        defaultPatient={currentPatient}
        onChange={onChange}
        value={currentPatient}
        className="no-mb"
      />
    </div>
  );
};

export default SubmissionsFilter;
