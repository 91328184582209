export const showConfettiCanvas = (payload: any) => ({
  type: "SHOW_CONFETTI_CANVAS",
  payload: payload,
});

export const setCurrentTrial = (payload: any) => ({
  type: "SET_CURRENT_TRIAL",
  payload: payload,
});

export const setUnsubscribed = (payload: any) => ({
  type: "SET_UNSUBSCRIBED",
  payload: payload,
});
