import "./styles.scss";
import React from "react";

interface AuthenticationFooterProps {
  lineOne?: React.ReactNode;
  lineTwo?: React.ReactNode;
}

const AuthenticationFooter = ({
  lineOne,
  lineTwo,
}: AuthenticationFooterProps) => {
  return (
    <div className="authentication-footer">
      <p className="authentication-footer__line-one">{lineOne}</p>
      <p className="authentication-footer__line-two">{lineTwo}</p>
    </div>
  );
};

export default AuthenticationFooter;
