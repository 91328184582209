import { SetStateAction, useEffect, useState, RefObject } from "react";
interface UseRangeFieldProps {
  defaultValue: number;
  min?: number;
  max?: number;
  id: string;
  value?: number;
  bubbleRef: RefObject<HTMLDivElement>;
}

const useRangeField = ({
  value = 5,
  id,
  min = 0,
  max = 100,
  defaultValue,
  bubbleRef,
}: UseRangeFieldProps) => {
  const [currentValue, setCurrentValue] = useState(
    value || defaultValue || max / 2
  );

  const handleValueChange = (value: SetStateAction<number>) => {
    setCurrentValue(Number(value));
  };

  useEffect(() => {
    if (bubbleRef.current) {
      const locationValue = Number(((currentValue - min) * 100) / (max - min));
      bubbleRef.current.style.left = `calc(${locationValue}% + (${
        8 - locationValue * 0.15
      }px))`;
    }
  }, [currentValue, id, max, min, bubbleRef]);

  return {
    currentValue,
    handleValueChange,
  };
};

export default useRangeField;
