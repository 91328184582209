import React, { useState } from "react";
import { IconButton, Modal } from "components";
import "./GroupMemberButtons.styles.scss";
import { useCurrentUser, useResendGroupInvite } from "utils/hooks";
import { User } from "types";
import RemoveMemberConfirmationModal from "../RemoveMemberConfirmationModal/RemoveMemberConfirmationModal";
interface GroupMemberButtonsProps {
  member: User;
  groupId: string;
}

const GroupMemberButtons = ({ member, groupId }: GroupMemberButtonsProps) => {
  const { user } = useCurrentUser();
  const { resendGroupInvite } = useResendGroupInvite();

  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);

  const handleCloseRemoveMemberModal = () => {
    setIsRemovalModalOpen(false);
  };

  const showResendInviteBtn =
    member.status === "pending" && user?.id === member.invitingTherapist;

  const showRemoveBtn = user?.id === member.invitingTherapist;

  return (
    <>
      <div className="group-member-buttons">
        {showRemoveBtn && (
          <IconButton
            value="Remove"
            icon="Remove"
            name="group-member_remove_btn"
            extraClass="group-member-buttons_btn"
            onClick={() => setIsRemovalModalOpen(true)}
          />
        )}
        {/* <IconButton
        value="Role"
        icon="IdCard"
        name="group-member_role_btn"
        extraClass="group-member-buttons_btn"
      /> */}
        {showResendInviteBtn && (
          <IconButton
            value="Resend Invite"
            icon="Envelope"
            name="group-member_resend_invite_btn"
            extraClass="group-member-buttons_btn"
            onClick={() =>
              resendGroupInvite({
                member,
                groupId,
              })
            }
          />
        )}
      </div>
      <Modal isOpen={isRemovalModalOpen} onClose={handleCloseRemoveMemberModal}>
        <RemoveMemberConfirmationModal
          groupId={groupId}
          member={member}
          onCancel={handleCloseRemoveMemberModal}
        />
      </Modal>
    </>
  );
};

export default GroupMemberButtons;
