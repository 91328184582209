import { useSelector } from "react-redux";
import useGetAssignments from "../assignments/useGetAssignments";

const useGetSubmissionDrafts = ({ assignmentId, takeLastAsDraft }) => {
  const submissionDrafts = useSelector(
    (state) => state.submissions?.submissionDrafts || []
  );

  const submissions = useSelector((state) =>
    (state.submissions?.submissions || []).filter(
      (submission) => submission.assignmentId === assignmentId
    )
  );

  const { assignments } = useGetAssignments({});

  const assignment = assignments?.assignments?.find(
    (a) => a.uuid === assignmentId
  );

  let lastSubmission = submissions[0];
  for (let i = 1; i < submissions.length; i++) {
    if (
      Number.parseInt(lastSubmission.createdAt) <
      Number.parseInt(submissions[i].createdAt)
    ) {
      lastSubmission = submissions[i];
    }
  }

  const submissionDraft = submissionDrafts.find(
    (submissionDraft) =>
      submissionDraft.submission.assignmentId === assignmentId && assignmentId
  );

  if (lastSubmission && takeLastAsDraft) {
    const responseFields = assignment.sections
      .flatMap((section) => section.fields)
      .map((field) => ({
        fieldId: field.uuid,
        fieldLabel: field.label,
        fieldType: field.type,
        sectionId: field.sectionId,
        value:
          (submissionDraft?.responseFields || []).find(
            (responseField) => responseField.fieldId === field.uuid
          )?.value ||
          lastSubmission.responseFields.find(
            (responseField) => responseField.fieldId === field.uuid
          )?.value,
      }));

    return {
      submissionDrafts,
      submissionDraft: {
        submission: submissionDraft,
        responseFields,
      },
    };
  }

  return {
    submissionDrafts,
    submissionDraft,
  };
};

export default useGetSubmissionDrafts;
