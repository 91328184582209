/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import "./MobileBottomNavItem.styles.scss";
import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Icon } from "components";

const MobileBottomNavItem = ({ item }) => {
  const { label, icon, link, hasAlert } = item;
  return (
    <>
      <NavLink
        as="div"
        to={link}
        className="mobile-bottom-nav-item"
        name={label}
        id={label}
        data-tip
        data-for={`${label.toLowerCase()}-tip`}
      >
        <div className="mobile-bottom-nav-item__icon">
          <Icon src={icon} />
        </div>
        <div className="mobile-bottom-nav-item__label">{label}</div>
        {hasAlert && <div className="mobile-bottom-nav-item__dot" />}
      </NavLink>
    </>
  );
};

MobileBottomNavItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string,
    link: PropTypes.string || PropTypes.func,
  }),
  hasAlert: PropTypes.bool,
};

MobileBottomNavItem.defaultProps = {
  hasAlert: false,
};

export default MobileBottomNavItem;
