import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useFetchAttachedFile from "./useFetchAttachedFile";

interface useGetFileAttachmentProps {
  fileId: string;
}

const useGetFileAttachment = ({ fileId }: useGetFileAttachmentProps) => {
  const { fetchFileAttachment } = useFetchAttachedFile({ fileId });

  const files = useSelector((state: RootStateOrAny) => state.files);

  const fileFromState = files?.attachedFiles?.find(
    (f: { uuid: string }) => f.uuid === fileId
  );

  useEffect(() => {
    if (!fileFromState) {
      fetchFileAttachment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    file: fileFromState,
  };
};

export default useGetFileAttachment;
