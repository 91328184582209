import { useState } from "react";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { updatePatientReminderSettingsAction } from "store/actions/users";
import { toast } from "react-toastify";
import { useCurrentUser, useLogout } from "utils/hooks";

const useUpdatePatientReminderSettings = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const updatePatientReminderSettings = async (args: any) => {
    setIsLoading(true);

    const formattedTimes = args.notificationTimes
      .map((time: Date) => `"${String(time)}"`)
      .join(",");

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              updatePatientReminderSettings(reminderSettings: {
                defaultReminder: ${args.defaultReminder},
                notificationTimes: [${formattedTimes}],
                notificationMessage: "${args.notificationMessage}",
                daysOfWeek: "${args.daysOfWeek}"
              }) {
                defaultReminder,
                notificationTimes,
                notificationMessage,
                daysOfWeek
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const resData = await res.json();

      if (res.status !== 200 && res.status !== 201) {
        if (resData.errors[0].message.includes("BRKN_6001")) {
          toast.warn(`For security purposes please log in again.`);
          logOut();
        } else {
          toast.error(`Hmm, something went wrong.`);
          throw new Error("Failed!");
        }
      }

      toast(`Your reminder settings has been updated!`);

      ReactGA.event("patient_reminder_settings_updated", {
        user_id: user?.id,
      });

      dispatch(
        updatePatientReminderSettingsAction(
          resData.data.updatePatientReminderSettings
        )
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    updatePatientReminderSettings,
    isLoading,
  };
};

export default useUpdatePatientReminderSettings;
