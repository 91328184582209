import { useEffect } from "react";
import { useSelector } from "react-redux";
import useFetchLibrary from "./useFetchLibrary";
import useFetchFiles from "../files/useFetchFiles";

const useGetPatientLibrary = () => {
  const { fetchPatientLibrary, fetchPublicPatientLibrary } = useFetchLibrary();
  const { fetchPatientFiles } = useFetchFiles();

  const resources = useSelector((state) => state.resources && state.resources);
  const files = useSelector((state) => state.files && state.files);

  useEffect(() => {
    if (resources?.patientResources?.length === 0) {
      fetchPatientLibrary();
      fetchPublicPatientLibrary();
    }
    fetchPatientFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources?.patientResources?.length, files?.patientFiles?.length]);

  return {
    patientResources: resources?.patientResources || [],
    patientFiles: files?.patientFiles || [],
    patientPublicResources: resources?.patientPublicResources || [],
  };
};

export default useGetPatientLibrary;
