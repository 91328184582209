import "./DashboardPage.styles.scss";
import React, { useState, useMemo, useEffect } from "react";
import {
  PageActionBar,
  StatsCard,
  SidePanel,
  TopNav,
  PageTitle,
} from "components";
import {
  useGetPatients,
  useGetSubmissions,
  useFetchSubmissions,
  useCurrentUser,
} from "utils/hooks";
import DashboardEmptyState from "./components/DashboardEmptyState";
import RecentSubmissionsCard from "./components/RecentSubmissionsCard";
import SubmissionsIcon from "assets/icons/inbox.svg";
import PatientsIcon from "assets/icons/users-medical.svg";
import AddPatientButton from "../Patients/components/AddPatientButton";
import AddPatientsForm from "../Patients/components/AddPatientsForm";
import { useTranslation } from "react-i18next";

const DashboardPage = () => {
  const [showAddPatient, setShowAddPatient] = useState(false);
  const { patients } = useGetPatients({});
  const { fetchAllSubmissions } = useFetchSubmissions();
  const { submissions } = useGetSubmissions({});
  const memoizedPatients = useMemo(() => patients, [patients]);
  const memoizedSubmissions = useMemo(() => submissions, [submissions]);
  const { user } = useCurrentUser();
  const isEmpty = !patients.length;
  const { t } = useTranslation(["common"]);
  const title = t("page_titles.dashboard_title");

  useEffect(() => {
    fetchAllSubmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopNav topL={<PageTitle text={title} />} />
      <div className="dashboard-page">
        {!isEmpty && (
          <>
            <PageActionBar>
              <p className="dashboard-page__greeting">
                Hello, <b>{user?.firstName}.</b> 👋
              </p>
              <div className="flex">
                <div style={{ marginInlineStart: "1em" }}>
                  <AddPatientButton
                    onClick={() => {
                      setShowAddPatient(!showAddPatient);
                    }}
                  />
                </div>
              </div>
            </PageActionBar>
          </>
        )}
        {!isEmpty ? (
          <div className="dashboard-page__wrapper flex justify-between">
            <div className="dashboard-page__wrapper--widget-primary">
              <RecentSubmissionsCard
                data={memoizedSubmissions}
                color="primary"
              />
            </div>
            <div className="dashboard-page__wrapper--stats--column">
              <div className="dashboard-page__wrapper--stats-title">Stats</div>
              <div className="dashboard-page__wrapper--stats">
                <StatsCard
                  data={memoizedSubmissions}
                  title="Submissions"
                  color="primary"
                  icon={SubmissionsIcon}
                />
              </div>
              <div className="dashboard-page__wrapper--stats">
                <StatsCard
                  data={memoizedPatients}
                  title="Patients"
                  color="secondary"
                  icon={PatientsIcon}
                />
              </div>
            </div>
          </div>
        ) : (
          <DashboardEmptyState user={user} />
        )}
      </div>
      <SidePanel
        title="Add Patient"
        isVisible={showAddPatient}
        onClose={() => setShowAddPatient(false)}
      >
        <AddPatientsForm onSuccess={() => setShowAddPatient(false)} />
      </SidePanel>
    </>
  );
};

export default DashboardPage;
