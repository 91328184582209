import { RootStateOrAny, useSelector } from "react-redux";

interface UseGetTherapistsProps {
  email?: string | null;
}

const useGetTherapists = ({ email }: UseGetTherapistsProps) => {
  const therapists = useSelector(
    (state: RootStateOrAny) => state.therapists && state.therapists
  );

  if (!therapists) {
    return {
      therapists: [],
      therapist: null,
    };
  }

  const therapist = therapists.therapists.find(
    (therapist: any) => therapist.email === email && email
  );

  return {
    therapists: therapists?.therapists,
    therapist: therapist,
  };
};

export default useGetTherapists;
