import React from "react";
import { Select } from "components";
import { Field, FieldOption } from "types";

interface SingleSelectFieldProps extends Field {
  id?: string;
  forwardControl?: any;
  readOnly?: boolean;
}

const SingleSelectField = ({
  options,
  defaultValue,
  forwardControl,
  ...props
}: SingleSelectFieldProps) => {
  const someOptions: FieldOption[] = [];
  if (!options) {
    options = [];
  }
  if (typeof options === "string") {
    try {
      options = JSON.parse(options);
    } catch (e) {
      options = [];
    }
  }
  if (Array.isArray(options)) {
    options.forEach((option: FieldOption, index) => {
      if (option.label === "" || option.value === "") {
        someOptions.push({
          label: `Option ${index + 1}`,
          value: `Option ${index + 1}`,
        });
        return;
      }
      someOptions.push(option);
    });
  }

  const currentChoice = someOptions.find(
    (option) => option.value === defaultValue
  );
  return (
    <Select
      className="select-field"
      defaultValue={currentChoice}
      forwardControl={forwardControl}
      options={someOptions}
      menuPlacement="auto"
      onChange={() => {
        return;
      }}
      {...props}
      name="single-select"
      error={props.error ? "error" : undefined}
    />
  );
};

export default SingleSelectField;
