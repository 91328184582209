export const fetchPublicTemplatesAction = (templates: any) => {
  return {
    type: "FETCH_PUBLIC_TEMPLATES",
    payload: templates,
  };
};

export const fetchPrivateTemplatesAction = (templates: any) => {
  return {
    type: "FETCH_PRIVATE_TEMPLATES",
    payload: templates,
  };
};

export const addPrivateTemplateAction = (t: any) => {
  return {
    type: "ADD_PRIVATE_TEMPLATE",
    payload: t,
  };
};

export const addPublicTemplateAction = (t: any) => {
  return {
    type: "ADD_PUBLIC_TEMPLATE",
    payload: t,
  };
};

export const updateTemplateAction = (t: any) => {
  return {
    type: "UPDATE_TEMPLATE",
    payload: t,
  };
};

export const deleteTemplateAction = (t: any) => {
  return {
    type: "DELETE_TEMPLATE",
    payload: t,
  };
};

export const fetchAllPublicTemplateTagsAction = (templateTags: any) => {
  return {
    type: "FETCH_ALL_PUBLIC_TEMPLATE_TAGS",
    payload: templateTags,
  };
};

export const fetchAllPrivateTemplateTagsAction = (templateTags: any) => {
  return {
    type: "FETCH_ALL_PRIVATE_TEMPLATE_TAGS",
    payload: templateTags,
  };
};

export const createPublicTemplateTagAction = (templateTag: any) => {
  return {
    type: "CREATE_PUBLIC_TEMPLATE_TAG",
    payload: templateTag,
  };
};

export const createPrivateTemplateTagAction = (templateTag: any) => {
  return {
    type: "CREATE_PRIVATE_TEMPLATE_TAG",
    payload: templateTag,
  };
};
