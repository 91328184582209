import React, { useRef } from "react";
import { Field } from "types";
import useRangeField from "components/utility/GeneratedField/components/RangeField/RangeField.hooks";

interface Props {
  field: Field;
  defaultValue: any;
  onChange: (value: any) => void;
  error?: string;
}
const RangeField = ({ field, defaultValue, onChange, error }: Props) => {
  const bubbleRef = useRef<HTMLDivElement>(null);
  const { currentValue, handleValueChange } = useRangeField({
    value: Number(defaultValue),
    id: field.name,
    min: field.min || 0,
    max: field.max || 10,
    defaultValue: Number(defaultValue) || 5,
    bubbleRef,
  });
  return (
    <div className="range-field">
      <label className="range-field__label">{field.label}</label>
      <div className="range-field__input-wrapper">
        <div ref={bubbleRef} className={`range-field__bubble`}>
          {defaultValue || currentValue}
        </div>
        <input
          aria-label={field.label}
          type={"range"}
          className={`range-field__field ${error ? "error" : ""}`}
          min={field.min}
          max={field.max}
          value={currentValue}
          onChange={(e) => {
            onChange && onChange(e.target.value);
            handleValueChange(Number(e.target.value));
          }}
        />
      </div>
    </div>
  );
};

export default RangeField;
