import "./SingleGroup.styles.scss";
import React, { useState, useEffect } from "react";
import {
  Tabs,
  PageLoading,
  TopNav,
  SidePanel,
  Button,
  Icon,
  InviteLinkPanel,
} from "components";
import { useGetGroups, useFetchGroups, useCurrentUser } from "utils/hooks";
import { useParams, useNavigate } from "react-router-dom";
import GroupInfoCard from "./components/GroupInfoCard/GroupInfoCard";
import useSingleGroupTabs from "./utils/useSingleGroupTabs";
import GroupChooser from "./components/GroupChooser/GroupChooser";
import GroupActionsMenu from "../Groups/components/GroupActionsMenu";
import { Group } from "types";
import AddGroupsForm from "../Groups/components/AddGroupForm";
import EditIcon from "assets/icons/pen-to-square.svg";
import ArrowBack from "assets/icons/arrow-back.svg";
import EnvelopeIcon from "assets/icons/envelope.svg";
import AddMemberForm from "./components/GroupMembers/components/AddMemberForm/AddMemberForm";
// import VideoIcon from "assets/icons/video.svg";

const SingleGroupPage: React.FC = () => {
  const { fetchGroups } = useFetchGroups();
  const { groupStatus, uuid } = useParams<{
    groupStatus: string;
    uuid: string;
  }>();
  const { groups } = useGetGroups({});

  const [currentGroup, setCurrentGroup] = useState<Group | null>(null);

  const navigate = useNavigate();
  const { user } = useCurrentUser();

  const handleGroupChange = (groupId: string) => {
    const newGroup = groups.find((group: Group) => group?.uuid === groupId);
    if (newGroup) {
      setCurrentGroup(newGroup);
      navigate(`/groups/${groupStatus}/${newGroup?.slug}/${activeTab?.url}`);
    }
  };

  const { tabItems, activeTab, handleTabChange } =
    useSingleGroupTabs(currentGroup);

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (groups && groups.length > 0) {
      const group = groups.find((group: Group) => group?.slug === uuid);
      setCurrentGroup(group || null);
    }
  }, [groups, uuid]);

  const [isEditGroupVisible, setIsEditGroupVisible] = useState(false);
  const [isInviteToGroupVisible, setIsInviteToGroupVisible] = useState(false);
  const [isInviteLinkPanelVisible, setIsInviteLinkPanelVisible] =
    useState(false);

  const handleBackToGroups = () => {
    navigate(`/groups`);
  };

  if (!currentGroup) {
    return <PageLoading />;
  }

  const isGroupOwner = user?.id === currentGroup.groupOwnerId;

  return (
    <>
      <TopNav
        topL={
          <div
            className="single-group__back pointer"
            onClick={() => handleBackToGroups()}
            onKeyDown={() => handleBackToGroups()}
            role="button"
            tabIndex={0}
          >
            <Icon src={ArrowBack} />
            <div>
              <h3>Back to Groups</h3>
            </div>
          </div>
        }
        botL={
          <GroupChooser
            currentGroup={currentGroup}
            onChange={(groupId: { value: string }) =>
              handleGroupChange(groupId?.value)
            }
          />
        }
        botR={
          <div className="single-group-top-bar__actions">
            <Button
              onClick={() => {
                setIsEditGroupVisible(true);
              }}
              name="edit_group"
              value="Edit"
              extraClass="secondary"
              size="small"
              icon={EditIcon}
            />
            <Button
              onClick={() => {
                setIsInviteToGroupVisible(true);
              }}
              name="invite_to_group"
              value="Invite"
              extraClass="secondary"
              size="small"
              icon={EnvelopeIcon}
            />
            {isGroupOwner && (
              <Button
                onClick={() => setIsInviteLinkPanelVisible(true)}
                name="share_link_to_group"
                value="Share"
                extraClass="secondary"
                size="small"
                icon="Share"
              />
            )}
            <div className="flex align-center">
              {/* <Button
              onClick={() =>
                window.open(
                  `/session/${currentGroup.slug}`,
                  "_blank",
                  "rel=noopener noreferrer"
                )
              }
              name={`navigate_to_online_session_button`}
              value="Start Online Session"
              icon={VideoIcon}
              size="small"
              pill
            /> */}
              {isGroupOwner && <GroupActionsMenu group={currentGroup} />}
            </div>
          </div>
        }
      />
      <div className="single-group-top-area">
        <GroupInfoCard group={currentGroup} />
        <div className="single-group-top-left">
          <div className="single-group-tabs-top">
            {activeTab?.actionComponent}
            <Tabs
              responsive
              items={tabItems}
              activeTab={activeTab}
              setActiveTab={handleTabChange}
              size={"large"}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="single-group-tabs-body">{activeTab.mainComponent}</div>
      </div>
      <SidePanel
        title={`Edit ${currentGroup?.name}'s info`}
        isVisible={isEditGroupVisible}
        onClose={() => setIsEditGroupVisible(false)}
      >
        <AddGroupsForm
          onSuccess={() => setIsEditGroupVisible(false)}
          group={currentGroup}
        />
      </SidePanel>

      <SidePanel
        title={`Invite to ${currentGroup?.name}`}
        isVisible={isInviteToGroupVisible}
        onClose={() => setIsInviteToGroupVisible(false)}
      >
        <AddMemberForm
          onSuccess={() => setIsInviteToGroupVisible(false)}
          group={currentGroup}
        />
      </SidePanel>

      <SidePanel
        title={"Share a link to Invite a group member"}
        isVisible={isInviteLinkPanelVisible}
        onClose={() => setIsInviteLinkPanelVisible(false)}
      >
        <InviteLinkPanel group={currentGroup} />
      </SidePanel>
    </>
  );
};

export default SingleGroupPage;
