import "./OnboardingLayout.styles.scss";
import React, { useEffect } from "react";
import { useGuestRedirect } from "utils/hooks";

interface OnboardingLayoutProps {
  children: React.ReactNode;
}

const OnboardingLayout = ({ children }: OnboardingLayoutProps) => {
  const { redirect } = useGuestRedirect();
  useEffect(() => {
    redirect();
  }, [redirect]);

  return (
    <div className="flex">
      <div className="flex col onboarding-body ">{children}</div>
    </div>
  );
};

export default OnboardingLayout;
