import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";

const EmptyTableBody = ({ dataType }) => {
  return (
    <tbody>
      <tr>
        <td className="empty-state-data-cell">
          {dataType ? (
            <span>Add a {dataType} to get started</span>
          ) : (
            <span>You do not have any data yet.</span>
          )}
        </td>
      </tr>
    </tbody>
  );
};

EmptyTableBody.propTypes = {
  text: PropTypes.string,
};

export default EmptyTableBody;
