import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useGetPatients from "../patients/useGetPatients";
import useFetchFiles from "./useFetchFiles";

interface UseGetTherapistFilesProps {
  patientSlug?: string;
}

const useGetTherapistFiles = ({ patientSlug }: UseGetTherapistFilesProps) => {
  const { fetchTherapistFiles } = useFetchFiles();

  const files = useSelector(
    (state: RootStateOrAny) => state.files && state.files
  );

  useEffect(() => {
    if (files?.therapistFiles?.length === 0) {
      fetchTherapistFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let patientFiles: any[] = [];
  const { patient } = useGetPatients({ slug: patientSlug });

  const filterFilesByPatientId = (array: any[], string: any) => {
    const filteredArray = array.map((element: { sharedWith: any[] }) => {
      const hasPatient = element.sharedWith?.find(
        (item: { value: any }) => item.value === string
      );
      if (hasPatient) {
        return element;
      }
      return false;
    });

    return filteredArray;
  };

  if (patient && patient.uuid && files?.therapistFiles?.length) {
    patientFiles = filterFilesByPatientId(files.therapistFiles, patient.uuid);
  }

  const therapistImages =
    files?.therapistFiles?.filter((file: { fileUrl: string }) =>
      /\.(jpg|jpeg|png|gif)$/i.test(file.fileUrl)
    ) || [];

  const therapistJustFiles =
    files?.therapistFiles?.filter(
      (file: { fileUrl: string }) =>
        !/\.(jpg|jpeg|png|gif)$/i.test(file.fileUrl)
    ) || [];

  return {
    therapistFiles: files?.therapistFiles || [],
    therapistJustFiles: therapistJustFiles,
    patientFiles: patientFiles.filter((item: boolean) => item !== false),
    therapistImages: therapistImages,
  };
};

export default useGetTherapistFiles;
