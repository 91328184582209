import "./PageActionBar.styles.scss";
import React from "react";

interface PageActionBarProps {
  children: React.ReactNode;
  align?: "right" | "left";
}

const PageActionBar = ({ children, align = "left" }: PageActionBarProps) => {
  return (
    <div
      className={`flex ${
        align === "right" ? "justify-end" : "justify-between"
      } align-center page-action-bar`}
    >
      {children}
    </div>
  );
};

export default PageActionBar;
