export const createMoodScaleAction = (moodScale) => ({
  type: "CREATE_MOOD_SCALE",
  payload: moodScale,
});

export const deleteMoodScaleAction = (uuid) => ({
  type: "DELETE_MOOD_SCALE",
  payload: uuid,
});

export const fetchAllMoodScalesAction = (moodScales) => ({
  type: "FETCH_ALL_MOOD_SCALES",
  payload: moodScales,
});

export const updateMoodScaleAction = (moodScale) => ({
  type: "UPDATE_MOOD_SCALE",
  payload: moodScale,
});
