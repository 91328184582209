export const createActionPlanAction = (actionPlan) => ({
  type: "CREATE_ACTION_PLAN",
  payload: actionPlan,
});

export const updateActionPlanAction = (actionPlan) => ({
  type: "UPDATE_ACTION_PLAN",
  payload: actionPlan,
});

export const fetchActionPlansAction = (actionPlans) => {
  return {
    type: "FETCH_ACTION_PLANS",
    payload: actionPlans,
  };
};

export const addActionPlanAssignmentAction = (assignment) => {
  return {
    type: "ADD_ACTION_PLAN_ASSIGNMENT",
    payload: assignment,
  };
};

export const deleteActionPlanAssignmentAction = (assignment) => {
  return {
    type: "DELETE_ACTION_PLAN_ASSIGNMENT",
    payload: assignment,
  };
};
