import "react-datepicker/dist/react-datepicker.css";
import "./DateField.styles.scss";
import React from "react";
import { Field } from "types";
import DatePicker from "react-datepicker";
import { format } from "date-fns";

interface Props {
  field: Field;
  onChange: (value: any) => void;
  defaultValue?: any;
  showTimeInput?: boolean;
}
const DateField = ({
  field,
  onChange,
  defaultValue,
  showTimeInput = false,
}: Props) => {
  let formattedDate: Date | string | null = new Date(defaultValue);
  if (formattedDate.toString() === "Invalid Date") {
    formattedDate = null;
  }
  const [value, setValue] = React.useState(formattedDate as Date | null);
  const handleDateChange = (date: Date) => {
    setValue(date);
    const formatted = format(date, "MM/dd/yyyy HH:mm:ss");
    onChange(formatted);
  };
  return (
    <div className="date-field">
      <label className="date-field__label">{field.label}</label>
      <DatePicker
        aria-label={field.label}
        className={`date-field__field ${field.error ? "error" : ""}`}
        selected={value}
        onChange={handleDateChange}
        peekNextMonth
        dropdownMode="select"
        showTimeInput={showTimeInput}
        dateFormat="MM/dd/yyyy"
        placeholderText="MM/dd/yyyy"
      />
    </div>
  );
};

export default DateField;
