import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AuthenticationHeader from "../components/AuthenticationHeader";
import AuthenticationFooter from "../components/AuthenticationFooter";
import {
  Animate,
  Button,
  ButtonLoading,
  Checkbox,
  Input,
  Link,
  Radio,
} from "components";
import { BRAND_NAME } from "utils/constants";

import { useSignUp, useViewport, useQueryParams } from "utils/hooks";
import jwtDecode from "jwt-decode";
import { SignUpArgs } from "utils/hooks/auth/useSignUp";

const SignUp = () => {
  const { hasUrlParams } = useQueryParams();
  const isPatientInvite = hasUrlParams("fromInvite");
  const emailParam = hasUrlParams("email");
  const qrTokenParam = hasUrlParams("qrToken");
  const inviteTherapistTokenParam = hasUrlParams("inviteTherapistToken");
  const { register, handleSubmit, errors } = useForm();
  const { submitSignup, isSubmitting } = useSignUp({
    fromInvite: isPatientInvite,
    inviteTherapistToken: inviteTherapistTokenParam,
  });
  const { isMobile } = useViewport();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isTosChecked, setIsTosChecked] = useState(false);

  useEffect(() => {
    if (isTosChecked) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    localStorage.clear();
  }, [isTosChecked]);

  let decodedToken;

  if (qrTokenParam) {
    decodedToken = jwtDecode(qrTokenParam);
  }

  if (inviteTherapistTokenParam) {
    decodedToken = jwtDecode<any>(inviteTherapistTokenParam);
  }

  const onSubmit = (args: SignUpArgs) => {
    if (qrTokenParam) {
      args.isTherapist = false;
    }
    submitSignup(args);
  };

  const hideIsTherapistRadio =
    isPatientInvite || qrTokenParam || inviteTherapistTokenParam;

  return (
    <div id="signup_page">
      <Animate animation="fadeInLeft" delay=".15">
        <AuthenticationHeader
          subline={
            qrTokenParam
              ? `Sign up to connect with ${decodedToken.therapistFullName} and improve your health journey.`
              : `Sign up, and ${
                  isPatientInvite
                    ? "get your assignments"
                    : "improve patient compliance"
                }.`
          }
        />
      </Animate>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between mobile-column">
          <Animate
            animation="fadeInLeft"
            delay=".25"
            width={isMobile ? "100%" : "48%"}
          >
            <Input
              name="first_name"
              type="text"
              label="First Name"
              placeholder="e.g. Peter"
              forwardRef={register({ required: true })}
              error={errors.email && "First Name is required"}
            />
          </Animate>
          <Animate
            animation="fadeInLeft"
            delay=".35"
            width={isMobile ? "100%" : "48%"}
          >
            <Input
              name="last_name"
              type="text"
              label="Last Name"
              placeholder="e.g. Parker"
              forwardRef={register({ required: true })}
              error={errors.email && "Last Name is required"}
            />
          </Animate>
        </div>
        <Animate animation="fadeInLeft" delay=".45">
          <Input
            name="email"
            type="email"
            label="Email"
            defaultValue={emailParam || undefined}
            placeholder="peter.parker@thedailybugle.com"
            forwardRef={register({ required: true })}
            error={errors.email && "Email field is required"}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".55">
          <Input
            name="password"
            type="password"
            label="Password"
            placeholder="8 character minimum, make it unique"
            forwardRef={register({
              required: "Password field is required",
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
              },
            })}
            error={errors.password && errors.password.message}
          />
        </Animate>
        <Animate
          animation="fadeInLeft"
          delay=".65"
          style={{
            display: `${hideIsTherapistRadio ? "none" : "flex"}`,
          }}
        >
          <Radio
            inputName="isTherapist"
            choices={[
              {
                label: "I am a therapist",
                value: true,
                options: {
                  onChange: (e: { target: { checked: any } }) =>
                    e.target.checked,
                },
              },
              {
                label: "I am a patient",
                value: false,
                options: {
                  onChange: (e: { target: { checked: any } }) =>
                    e.target.checked,
                },
              },
            ]}
            forwardRef={register({
              required: hideIsTherapistRadio
                ? false
                : "Please choose a user type",
            })}
            error={errors.isTherapist && errors.isTherapist.message}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".65">
          <Checkbox
            name="tos"
            label={
              <>
                <span>I agree to {BRAND_NAME}&apos;s </span>
                <Link to="https://reflectiveapp.com/tos/" newTab>
                  Terms of Service
                </Link>
              </>
            }
            ariaLabel={`I agree to ${BRAND_NAME}'s Terms of Service`}
            onChange={(e) => setIsTosChecked(e.target.checked)}
            forwardRef={register({
              required:
                "You must agree to our Terms and Conditions to use this app",
            })}
            error={errors.tos && errors.tos.message}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".75">
          <Button
            type="submit"
            name="signup_button"
            value={isSubmitting ? <ButtonLoading /> : "Create My Account"}
            fullWidth
            isLoading={isSubmitting}
            disabled={isDisabled}
          />
        </Animate>
      </form>
      <Animate animation="fadeInLeft" delay=".75">
        <AuthenticationFooter
          lineOne={
            <>
              Already have an account?{" "}
              <Link
                to={qrTokenParam ? `/login?qrToken=${qrTokenParam}` : "/login"}
              >
                Log in
              </Link>
            </>
          }
        />
      </Animate>
    </div>
  );
};

export default SignUp;
