import { persistor } from "store";
import ReactGA from "react-ga4";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { useCurrentUser } from "utils/hooks";
import { useCallback } from "react";

const useLogout = () => {
  const history: NavigateFunction = useNavigate();
  const { user, isAuthenticated } = useCurrentUser();

  const logOut = useCallback(() => {
    // Define keys to persist in localStorage
    const keysToPersist: string[] = ["skipped_therapist_onboarding"];

    const preservedValues: { [key: string]: string | null } = {};
    keysToPersist.forEach((key) => {
      preservedValues[key] = localStorage.getItem(key);
    });

    // Log the event with ReactGA
    ReactGA.event("logout_success", {
      user_id: isAuthenticated ? user?.id : "action_when_unauthenticated",
    });

    localStorage.clear();

    Object.entries(preservedValues).forEach(([key, value]) => {
      if (value !== null) {
        localStorage.setItem(key, value);
      }
    });

    persistor.purge().then(() => history("/login", { replace: false }));
  }, [history, isAuthenticated, user?.id]);

  return {
    logOut,
  };
};

export default useLogout;
