import React from "react";
import PropTypes from "prop-types";
import { isUri } from "valid-url";
import { Link as RouterLink } from "react-router-dom";

const Link = ({ to, newTab, children, title }) => {
  const linkProps = newTab
    ? {
        target: "_blank",
        rel: "noopener noreferrer",
      }
    : {};
  const linkTitle = `${title || children}${
    newTab ? " opens in a new tab" : ""
  }`;
  if (isUri(to)) {
    return (
      <a href={to} {...linkProps} title={linkTitle}>
        {children}
      </a>
    );
  } else {
    return (
      <RouterLink to={to} {...linkProps} title={linkTitle}>
        {children}
      </RouterLink>
    );
  }
};

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  newTab: PropTypes.bool,
  title: PropTypes.string,
};

Link.defaulProps = {
  newTab: false,
};

export default Link;
