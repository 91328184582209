import "./Spinner.styles.scss";
import { Icon } from "components";
import Loader from "assets/icons/spinner-third.svg";
import React from "react";

interface SpinnerProps {
  isActive: boolean;
}

const Spinner = ({ isActive = false }: SpinnerProps) => {
  return (
    <>
      {isActive ? (
        <div className="spinner__loader">
          <Icon src={Loader} />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Spinner;
