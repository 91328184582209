import "./AssignmentsForPatientsEmptyState.styles.scss";
import React, { useEffect } from "react";
import Balloon1 from "assets/images/balloon-1.png";
import TherapistPendingInviteForPatient from "pages/AppPages/PatientPages/TherapistProfileForPatients/components/TherapistPendingInviteForPatient";
import { useFetchTherapists, useGetTherapists } from "utils/hooks";

interface AssignmentsEmptyStateProps {
  visible: boolean;
}

const AssignmentsEmptyState = ({ visible }: AssignmentsEmptyStateProps) => {
  const { fetchAllTherapists } = useFetchTherapists();
  const { therapists } = useGetTherapists({ email: null });
  useEffect(() => {
    fetchAllTherapists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pendingTherapists = therapists.filter(
    (therapist: any) => therapist.connectionStatus === "pending"
  );

  if (!visible && !pendingTherapists.length) {
    return <></>;
  }
  return (
    <div className="assignments-for-patients-empty-state">
      {pendingTherapists?.length > 0 ? (
        <TherapistPendingInviteForPatient
          pendingTherapists={pendingTherapists}
        />
      ) : (
        <>
          {" "}
          <h3>You don&apos;t have any assignments yet.</h3>
          <p>
            You&apos;ll see them here once your therapist helps you create them.
          </p>
          <p>In the meantime, enjoy your day!</p>
          <div className="assignments-for-patients-empty-state__image-holder">
            <img
              className="assignments-for-patients-empty-state__image"
              src={Balloon1}
              alt="woman in hot air balloon"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AssignmentsEmptyState;
