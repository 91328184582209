import React, { useEffect } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { subYears } from "date-fns";
import {
  Animate,
  Button,
  Input,
  Select,
  DateField,
  ButtonLoading,
} from "components";

import { useAddPatient, useEditPatientProfile } from "utils/hooks";
import { useTranslation } from "react-i18next";
import { Patient } from "types";
import { AddPatientArgs } from "utils/hooks/patients/useAddPatient";

interface AppPatientFormProps {
  onSuccess: () => void;
  onNewPatient?: (arg: any) => void;
  patient?: Patient | null;
}

const AddPatientForm = ({
  onSuccess,
  onNewPatient,
  patient,
}: AppPatientFormProps) => {
  const { t } = useTranslation(["common"]);
  const { register, handleSubmit, errors, control } = useForm();
  const { submitAddPatient, isSubmitSuccessful, newPatient, isSubmitting } =
    useAddPatient();
  const { updatePatientProfile, isProfileSubmitting } = useEditPatientProfile({
    patientId: patient?.uuid,
  });

  const genders = [
    { value: "male", label: `${t("patients.male_label")}` },
    { value: "female", label: `${t("patients.female_label")}` },
    { value: "nonbinary", label: `${t("patients.non_binary_label")}` },
    { value: "other", label: `${t("patients.other_gender_label")}` },
  ];

  useEffect(() => {
    if (isSubmitSuccessful) {
      onSuccess();
      onNewPatient && onNewPatient(newPatient);
    }
  }, [isSubmitSuccessful, newPatient, onNewPatient, onSuccess]);

  const emailHint =
    patient && patient.email
      ? t("patients.email_hint_edit_disabled")
      : t("patients.email_hint_invitation_required");

  const currentGender = genders.filter(
    (gender) => gender.value === patient?.profile?.gender
  )[0];

  const currentDob = patient?.profile?.dob;
  const hasPatientDob =
    currentDob !== null &&
    currentDob !== "null" &&
    currentDob !== "undefined" &&
    currentDob !== undefined;

  const getCurrentFormHandler = ():
    | SubmitHandler<AddPatientArgs>
    | undefined => {
    if (patient) {
      return updatePatientProfile;
    }

    return submitAddPatient;
  };

  return (
    <div id="add_patient_form">
      <form
        onSubmit={handleSubmit(
          getCurrentFormHandler() as SubmitHandler<AddPatientArgs>
        )}
        autoComplete="off"
      >
        <Animate animation="fadeInLeft" delay=".25">
          <Input
            name="first_name"
            type="text"
            label={t("common_labels.first_name")}
            placeholder={t("patients.patient_first_name_placeholder")}
            forwardRef={register({ required: true })}
            error={
              errors.first_name && t("patients.patient_first_name_required")
            }
            defaultValue={patient?.firstName ?? undefined}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".35">
          <Input
            name="last_name"
            type="text"
            label={t("common_labels.last_name")}
            placeholder={t("patients.patient_last_name_placeholder")}
            forwardRef={register({ required: true })}
            error={errors.last_name && t("patients.patient_last_name_required")}
            defaultValue={patient?.lastName ?? undefined}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".45">
          <Input
            name="email"
            type="email"
            label={t("common_labels.email")}
            placeholder={t("patients.patient_email_placeholder")}
            forwardRef={register({ required: true })}
            error={errors.email && t("patients.patient_email_required")}
            hint={emailHint}
            defaultValue={patient ? patient.email : undefined}
            disabled={patient?.email ? true : false}
          />
        </Animate>
        <Animate
          animation="fadeInLeft"
          delay=".55"
          style={{ position: "relative" }}
        >
          <Controller
            name="dob"
            control={control}
            defaultValue={hasPatientDob ? new Date(currentDob) : null}
            render={({ onChange, value }) => (
              <DateField
                name="dob"
                dateFormat="MM/dd/yyyy"
                label={t("common_labels.dob")}
                placeholder={t("patients.patient_dob_placeholder")}
                selected={value}
                onChange={onChange}
                maxDate={subYears(new Date(), 13)}
                showMonthDropdown
                showYearDropdown
              />
            )}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".55">
          <Select
            name="gender"
            label={t("common_labels.gender")}
            placeholder={t("patients.patient_gender_placeholder")}
            options={genders}
            forwardRef={register()}
            forwardControl={control}
            defaultValue={patient && currentGender}
          />
        </Animate>
        <Button
          type="submit"
          name="add_patient_button"
          value={
            patient ? (
              t("patients.edit_patient_button")
            ) : isSubmitting ? (
              <ButtonLoading />
            ) : (
              t("patients.add_patient_button")
            )
          }
          fullWidth
          isLoading={isSubmitting || isProfileSubmitting}
        />
      </form>
    </div>
  );
};

export default AddPatientForm;
