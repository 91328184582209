import { Group, User } from "types";

export const fetchGroupsAction = (groups: Group[]) => {
  return {
    type: "FETCH_GROUPS",
    payload: groups,
  };
};

export const fetchGroupUsersAction = (groupUsers: User[]) => {
  return {
    type: "FETCH_GROUP_USERS",
    payload: groupUsers,
  };
};

export const addGroupAction = (group: Group) => {
  return {
    type: "ADD_GROUP",
    payload: group,
  };
};

export const updateGroupAction = (group: Group) => {
  return {
    type: "UPDATE_GROUP",
    payload: group,
  };
};

export const deleteGroupAction = (groupId: string) => {
  return {
    type: "DELETE_GROUP",
    payload: groupId,
  };
};
