import "./styles.scss";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { default as ReactSelect } from "react-select";
import Creatable from "react-select/creatable";
import makeAnimated from "react-select/animated";

interface SelectProps {
  options: any;
  name: string;
  label?: string;
  error?: string;
  forwardRef?: any;
  forwardControl?: any;
  hint?: string;
  rules?: any;
  defaultValue?: any;
  value?: any;
  menuPlacement?: any;
  className?: string;
  isMulti?: boolean;
  components?: any;
  onChange?: (options: any) => void;
  isCreatable?: boolean;
  onCreateOption?: (option: any) => void;
  placeholder?: string;
}

const Select = ({
  options,
  name,
  label,
  error,
  forwardRef,
  forwardControl,
  hint,
  rules,
  defaultValue,
  value,
  menuPlacement,
  className,
  isMulti,
  onChange,
  isCreatable = false,
  onCreateOption,
  placeholder,
  ...props
}: SelectProps) => {
  const { control } = useForm();
  const animatedComponents = makeAnimated();
  const { t } = useTranslation(["common"]);

  const SelectComponent = isCreatable ? Creatable : ReactSelect;
  return (
    <div className={`select ${className ? className : ""}`} id={name}>
      <label className="select__label">{label}</label>
      <Controller
        key={value}
        classNamePrefix="select"
        forwardRef={forwardRef}
        as={SelectComponent}
        options={options}
        name={name}
        control={forwardControl || control}
        aria-label={label}
        className={`select__field ${error ? "error" : ""}`}
        menuPortalTarget={document.getElementById(`'${name}'`)}
        styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
        rules={rules}
        defaultValue={defaultValue || value || null}
        value={value}
        menuPlacement={menuPlacement}
        components={animatedComponents}
        isMulti={isMulti}
        onChange={onChange}
        placeholder={placeholder || t("common_labels.select_placeholder")}
        onCreateOption={isCreatable ? onCreateOption : null}
        {...props}
      />
      {error && (
        <p className="select__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && hint !== "null" && (
        <p className="select__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default Select;
