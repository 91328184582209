import { useSelector } from "react-redux";

const useGetTherapistPracticeInfo = () => {
  const therapistPracticeInfo = useSelector(
    (state) =>
      state.therapists.therapistPracticeInfo &&
      state.therapists.therapistPracticeInfo
  );

  if (!therapistPracticeInfo) {
    return {
      therapistPracticeInfo: null,
      isTherapistPracticeInfoComplete: false,
    };
  }

  const isTherapistPracticeInfoComplete = () => {
    if (!therapistPracticeInfo) {
      return false;
    }
    return (
      therapistPracticeInfo &&
      therapistPracticeInfo.practiceName &&
      therapistPracticeInfo.treatmentType &&
      therapistPracticeInfo.practiceSize &&
      therapistPracticeInfo.address1 !== "" &&
      therapistPracticeInfo.state &&
      therapistPracticeInfo.zipcode &&
      therapistPracticeInfo.phone
    );
  };

  return {
    therapistPracticeInfo,
    isTherapistPracticeInfoComplete: isTherapistPracticeInfoComplete()
      ? true
      : false,
  };
};

export default useGetTherapistPracticeInfo;
