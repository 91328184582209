import "./ConfirmEmail.styles.scss";
import React, { useEffect, FC } from "react";
import { AlertBar, Icon } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";
import PaperPlaneIcon from "assets/icons/paper-plane.svg";
import { useRequestConfirmationEmail, useRefreshUser } from "utils/hooks";
import { useTranslation } from "react-i18next";
import { User } from "types";

interface ConfirmEmailDefaultProps {
  email?: string;
  handleSubmitConfirmationEmailRequest: () => void;
}

const ConfirmEmailDefault: FC<ConfirmEmailDefaultProps> = ({
  email,
  handleSubmitConfirmationEmailRequest,
}) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="confirm-email-bar">
      <div className="confirm-email-bar__icon">
        <Icon src={WarningIcon} />
      </div>
      <div>
        <p className="confirm-email-bar__title">
          {t("confirmations.confirm_email")}
        </p>

        <p className="confirm-email-bar__body">
          {t("confirmed_actions.email_was_sent", { email })}{" "}
          {t("calls_to_action.click_to_confirm_email")}{" "}
          {t("calls_to_action.didn't_receive_email")}{" "}
          <span
            tabIndex={0}
            role="button"
            className="confirm-email-bar__resend"
            onClick={handleSubmitConfirmationEmailRequest}
            onKeyDown={handleSubmitConfirmationEmailRequest}
          >
            {t("buttons.send_another")}
          </span>
          .
        </p>
      </div>
    </div>
  );
};

interface ConfirmEmailSendingProps {
  email?: string;
}

const ConfirmEmailSending: FC<ConfirmEmailSendingProps> = ({ email }) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="confirm-email-bar">
      <div className="confirm-email-bar__icon">
        <Icon src={PaperPlaneIcon} />
      </div>
      <div>
        <p className="confirm-email-bar__title">
          {t("confirmed_actions.sending", { email })}...
        </p>

        <p className="confirm-email-bar__body">
          {t("confirmed_actions.confirm_email_resend", { email })}
        </p>
      </div>
    </div>
  );
};

interface ConfirmEmailBarProps {
  user: User | null;
}

const ConfirmEmailBar: FC<ConfirmEmailBarProps> = ({ user }) => {
  const { submitConfirmationEmailRequest, isSubmitting } =
    useRequestConfirmationEmail();

  const handleSubmitConfirmationEmailRequest = () => {
    submitConfirmationEmailRequest({ email: user?.email });
  };

  const { submitRefreshRequest } = useRefreshUser();

  useEffect(() => {
    submitRefreshRequest();
  }, [submitRefreshRequest]);

  const getConfirmationContent = () => {
    if (isSubmitting) {
      return <ConfirmEmailSending email={user?.email} />;
    }

    return (
      <ConfirmEmailDefault
        email={user?.email}
        handleSubmitConfirmationEmailRequest={
          handleSubmitConfirmationEmailRequest
        }
      />
    );
  };

  const getAlertType = () => {
    if (isSubmitting) {
      return "info";
    }

    return "error";
  };

  return (
    <div className="confirm-email-bar__wrapper">
      <AlertBar content={getConfirmationContent()} type={getAlertType()} />
    </div>
  );
};

export default ConfirmEmailBar;
