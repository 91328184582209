/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showConfettiCanvas } from "store/actions/uiState";
import ConfettiGenerator from "confetti-js";

const useConfetti = () => {
  const dispatch = useDispatch();
  const [runConfetti, setRunConfetti] = useState(false);

  useEffect(() => {
    if (runConfetti) {
      const confettiSettings = {
        target: "confetti-canvas",
        max: "800",
        size: "1",
        animate: true,
        props: [
          "circle",
          "square",
          "triangle",
          "line",
          { type: "svg", src: "site/hat.svg", size: 25, weight: 0.2 },
        ],
        colors: [
          [165, 104, 246],
          [230, 61, 135],
          [0, 199, 228],
          [253, 214, 126],
        ],
        clock: "150",
        rotate: false,
        width: "1716",
        height: "757",
        start_from_edge: true,
        respawn: false,
      };
      const confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
      setTimeout(() => {
        dispatch(showConfettiCanvas(false));
        confetti.clear();
      }, 2000);
    }
  }, [runConfetti]);

  const triggerConfetti = () => {
    dispatch(showConfettiCanvas(true));
    setRunConfetti(!runConfetti);
  };

  return {
    triggerConfetti,
  };
};

export default useConfetti;
