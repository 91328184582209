/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import "./MoodScaleField.styles.scss";
import React, { useState } from "react";
import moods from "./moods";
import MoodIcon from "./MoodIcon";
import { Field } from "types";

interface MoodScaleFieldProps extends Field {
  id?: string;
  forwardRef?: any;
  readOnly?: boolean;
}

const MoodScaleField = ({
  value,
  label,
  error,
  forwardRef,
  hint,
  ...props
}: MoodScaleFieldProps) => {
  const [selectedMood, setSelectedMood] = useState(value || "");
  return (
    <div
      className={`mood-scale-field ${
        error ? "mood-scale-field_wrapper-error" : ""
      }`}
    >
      <label className="mood-scale-field__label">{label}</label>
      <div className="mood-scale-field__icon-wrapper">
        {moods.map((mood) => {
          const isSelected = mood.label === selectedMood;
          return (
            <div
              className={`mood-scale-field__icon-choice ${mood.theme} ${
                isSelected ? "selected" : ""
              }`}
              key={mood.label}
              onClick={() => setSelectedMood(mood.label)}
            >
              <MoodIcon mood={mood} />
              <p className="mood-scale-field__icon-label">{mood.label}</p>
            </div>
          );
        })}
      </div>
      <select
        aria-label={label}
        className={`mood-scale-field__field ${error ? "error" : ""}`}
        ref={forwardRef}
        {...props}
        value={selectedMood}
        hidden
      >
        <option value=""></option>
        {moods.map((mood) => {
          return (
            <option key={mood.label} value={mood.label}>
              {mood.label}
            </option>
          );
        })}
      </select>
      {error && (
        <p className="mood-scale-field__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && hint !== "null" && (
        <p className="mood-scale-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default MoodScaleField;
