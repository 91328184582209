import React from "react";
import "./Emoji.styles.scss";
interface Props {
  emoji: {
    emoji: string;
    label: string;
  };
  style?: "horizontal" | "vertical";
}
export function Emoji({ emoji, style = "vertical" }: Props) {
  return (
    <div className={`emoji-container ${style}`}>
      <div className={`emoji-wrapper ${style}`}>
        <div className={"emoji-box"}>{emoji.emoji}</div>
        <div className={"emoji-label"}>{emoji.label}</div>
      </div>
    </div>
  );
}
