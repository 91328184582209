import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLogout, useCurrentUser } from "utils/hooks";
import { createInviteCodeAction } from "store/actions/inviteCodes";

const useCreateInviteCode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const createInviteCode = async (groupId?: string) => {
    setIsLoading(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_REST_API_URL}/invite-code`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ groupId }),
        }
      );

      const resData = await res.json();

      if (res.ok) {
        dispatch(createInviteCodeAction(resData));
        toast("Awesome! QR code generated!");
        ReactGA.event("invite_code_created_success", {
          user_id: user?.id,
        });
      }

      if (!res.ok) {
        if (resData.message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(resData.message);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createInviteCode,
    isLoading,
  };
};

export default useCreateInviteCode;
