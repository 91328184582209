import "./AssignmentsForPatients.styles.scss";
import React, { useEffect } from "react";
import { useAssignmentStore } from "./assignmentStore";
import AssignmentsEmptyState from "./components/AssignmentsForPatientsEmptyState";
import AssignmentsForPatientsColumn from "./components/AssignmentsForPatientsColumn";
import AssignmentsForPatientsSectionsColumn from "./components/AssignmentsForPatientsSectionsColumn";
import AssignmentsForPatientsFieldsColumn from "./components/AssignmentsForPatientsFieldsColumn";
import { useLocation, useNavigate } from "react-router-dom";
import { PageTitle, TopNav } from "components";
import { useTranslation } from "react-i18next";

function AssignmentsForPatients() {
  const { state, dispatch } = useAssignmentStore();
  const location = useLocation();
  const history = useNavigate();
  const { sectionSlug, assignmentSlug } = state.parsedLocation(window.location);
  const { activeSection } = state;

  useEffect(() => {
    if (activeSection?.slug !== sectionSlug && !sectionSlug) {
      if (!assignmentSlug || !activeSection?.slug) return;
      history(`/your-assignments/${assignmentSlug}/${activeSection?.slug}`, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection]);

  useEffect(() => {
    // only dispatch if the active assignment or section is different from the URL
    if (
      state.activeAssignment?.slug !== assignmentSlug ||
      state.activeSection?.slug !== sectionSlug
    ) {
      // return;
      dispatch({
        location: {
          assignmentSlug,
          sectionSlug,
        },
      });

      // dispatch a second time to force re-render
      dispatch({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const { t } = useTranslation(["common"]);
  const title = t("patient_nav_items.assignments");
  return (
    <>
      <TopNav topL={<PageTitle text={title} />} />
      <AssignmentsEmptyState visible={state.assignments.length === 0} />
      {state.assignments && state.assignments.length ? (
        <div
          className={`assignments-for-patients ${state.activeColumn}-focused`}
        >
          <div className="assignments-for-patients_column">
            <AssignmentsForPatientsColumn />
          </div>
          {state.activeAssignment &&
            state.activeAssignment?.sections?.length > 1 && (
              <div className="assignments-for-patients-sections_column">
                <AssignmentsForPatientsSectionsColumn />
              </div>
            )}
          <div className="assignments-for-patients-fields_column">
            {state.activeSection &&
              state.activeSection.fields &&
              state.activeSection.fields.length > 0 && (
                <AssignmentsForPatientsFieldsColumn />
              )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default AssignmentsForPatients;
