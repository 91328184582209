import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useFetchTemplates from "./useFetchTemplates";
import useFetchTemplateTags from "./useFetchTemplateTags";
import useCurrentUser from "../auth/useCurrentUser";

interface UseGetTemplatesProps {
  templateId?: string;
}

const useGetTemplates = ({ templateId }: UseGetTemplatesProps) => {
  const { fetchPublicTemplates, fetchPrivateTemplates } = useFetchTemplates();
  const { fetchAllPublicTemplateTags, fetchAllPrivateTemplateTags } =
    useFetchTemplateTags();
  const { user } = useCurrentUser();

  const publicTemplates = useSelector(
    (state: RootStateOrAny) => state.templates.publicTemplates
  );

  const privateTemplates =
    useSelector((state: RootStateOrAny) => state.templates.privateTemplates) ||
    [];

  const publicTemplateTags =
    useSelector(
      (state: RootStateOrAny) => state.templates.publicTemplateTags
    ) || [];

  const privateTemplateTags =
    useSelector(
      (state: RootStateOrAny) => state.templates.privateTemplateTags
    ) || [];

  const builderDrafts =
    privateTemplates.filter((t: any) => t.status === "DRAFT") || [];

  useEffect(() => {
    fetchPublicTemplates();
    fetchPrivateTemplates();
    fetchAllPublicTemplateTags();
    fetchAllPrivateTemplateTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredPrivateTemplates =
    privateTemplates?.filter(
      (t: { status: string }) => t.status === "PUBLISHED"
    ) || [];

  const filteredPublicTemplates =
    publicTemplates?.filter(
      (t: { type: string }) => t.type !== "SAFETY_PLAN"
    ) || [];

  const allTemplates = [...privateTemplates, ...filteredPublicTemplates];

  const allOwnedTemplates =
    allTemplates?.filter(
      (t: { authorId: string }) => t.authorId === user?.id
    ) || [];

  const specificTemplate =
    allTemplates?.filter((t: { uuid: string }) => t.uuid === templateId)[0] ||
    null;

  return {
    publicTemplates: filteredPublicTemplates,
    privateTemplates: filteredPrivateTemplates,
    builderDrafts,
    allPublishedAssignmentTemplates: [
      ...filteredPrivateTemplates,
      ...filteredPublicTemplates,
    ].sort((a, b) => {
      const fa = a.title.toLowerCase(),
        fb = b.title.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    }),
    publicTemplateTags: publicTemplateTags,
    privateTemplateTags: privateTemplateTags,
    allOwnedTemplates,
    specificTemplate,
  };
};

export default useGetTemplates;
