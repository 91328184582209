import React from "react";
import { useForm } from "react-hook-form";
import { useTherapistOnboarding } from "utils/hooks";
import { Button } from "components";
import TherapistProfile from "forms/TherapistForms/TherapistProfile";
import { useTranslation } from "react-i18next";

const TherapistProfileSettings = () => {
  const { register, handleSubmit, control } = useForm();
  const { createTherapistProfile, isSubmitting } = useTherapistOnboarding({
    isFromSettings: true,
  });
  const formProps = { register, control };
  const { t } = useTranslation(["common"]);

  return (
    <section className="settings-section" id="therapist-settings">
      <h2 className="section-title">
        {t("therapist_profile.therapist_profile_settings_label")}
      </h2>
      <hr className="divider" />
      <form onSubmit={handleSubmit(createTherapistProfile)}>
        <div className="settings-section__content">
          <TherapistProfile {...formProps} />
        </div>
        <Button
          type="submit"
          name="therapist_onboarding_button"
          value={t("common_labels.submit_changes")}
          isLoading={isSubmitting}
        />
      </form>
    </section>
  );
};

export default TherapistProfileSettings;
