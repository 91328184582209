import { PURGE } from "redux-persist";
import { Group } from "types";

const initialState = {
  groups: [],
  groupUsers: [],
};

interface GroupReducerType {
  type: string;
  payload: any;
}

export default (state = initialState, { type, payload }: GroupReducerType) => {
  switch (type) {
    case "FETCH_GROUPS":
      return {
        ...state,
        groups: [...payload],
      };
    case "FETCH_GROUP_USERS":
      return {
        ...state,
        groupUsers: [...payload],
      };
    case "ADD_GROUP":
      return {
        ...state,
        groups: [...state.groups, payload],
      };
    case "UPDATE_GROUP":
      return {
        ...state,
        groups: state.groups.map((group: Group) =>
          group.uuid === payload.uuid
            ? {
                ...group,
                ...payload,
              }
            : group
        ),
      };
    case "DELETE_GROUP":
      return {
        ...state,
        groups: state.groups.filter((group: Group) => group.uuid !== payload),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
