import React from "react";
import { Button, ImageSeparator } from "components";
import PartyIcon from "assets/icons/party-horn.svg";
import UpgradeSplash from "assets/images/splashes/relationshiop-counseling.png";
import { useCurrentUser } from "utils/hooks";
import "./UpgradeMessage.styles.scss";

interface UpgradeMessageProps {
  heading?: string;
  limitedEntity: string;
  warningLine?: string;
  valueLine?: string;
  ctaText?: string;
}

const UpgradeMessage = ({
  heading,
  limitedEntity,
  warningLine,
  valueLine,
  ctaText,
}: UpgradeMessageProps) => {
  const { user } = useCurrentUser();
  const handleUpgradeButton = () => {
    window.open(
      `${process.env.REACT_APP_STRIPE_PLAN_URL}?prefilled_email=${user?.email}`,
      "_blank"
    );
  };

  return (
    <div className="upgrade-message">
      <h2>{heading || "Unlock more!"}</h2>
      <p>
        {warningLine ||
          `You've reached the limit of how many ${limitedEntity} you can add
          on your current plan.`}
      </p>
      <p>
        {valueLine ||
          `Upgrade your account to add additional ${limitedEntity} and more!`}
      </p>
      <ImageSeparator image={UpgradeSplash} alt="upgrade icon" />
      <Button
        name="upgrade_button"
        value={ctaText || "Upgrade now"}
        pill
        fullWidth
        icon={PartyIcon}
        onClick={handleUpgradeButton}
      />
    </div>
  );
};

export default UpgradeMessage;
