/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./MobilePageHeaderAction.styles.scss";
import React from "react";
import { Icon } from "components";
import LeftIcon from "assets/icons/chevron-left.svg";

interface MobilePageHeaderActionProps {
  onActionClick: () => void;
  icon: string;
}

const MobilePageHeaderAction = ({
  onActionClick,
  icon,
}: MobilePageHeaderActionProps) => {
  return (
    <div className="mobile-page-header__action" onClick={onActionClick}>
      <Icon src={icon || LeftIcon} />
    </div>
  );
};

export default MobilePageHeaderAction;
