import "./AddNoteButton.styles.scss";
import React from "react";
import { Button } from "components";
import NoteIcon from "assets/icons/note.svg";

const AddNoteButton = ({ onClick }) => {
  return (
    <Button
      name="add_note_button"
      value="Add Note"
      pill
      icon={NoteIcon}
      size="small"
      onClick={onClick}
    />
  );
};

export default AddNoteButton;
