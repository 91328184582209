import "./NoteDataCards.styles.scss";
import React from "react";
import GroupNotesEmptyState from "../GroupNotesEmptyState";
import { Button } from "components";
import { toAmericanDateWithTimeFromUnix } from "utils/helpers";

const NoteDataCard = ({ note, onClick }) => {
  return (
    <div className="note-data-card">
      <div className="note-data-card__top">
        <div>
          <p className="note-data-card__name">
            {toAmericanDateWithTimeFromUnix(note.createdAt)}
          </p>
          <p className="light-text">
            Last update: {toAmericanDateWithTimeFromUnix(note.updatedAt)}
          </p>
        </div>
        <div className="note-data-card__action-button flex align-center">
          <Button
            onClick={() => onClick(note)}
            name={`navigate_to_action_plan_button`}
            value="View Note"
            extraClass="secondary one-fifty-width"
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

const NoteDataCards = ({ notes, onClick }) => {
  return (
    <>
      {notes.length ? (
        <>
          {notes.map((note, i) => {
            return <NoteDataCard note={note} key={i} onClick={onClick} />;
          })}
        </>
      ) : (
        <GroupNotesEmptyState />
      )}
    </>
  );
};

export default NoteDataCards;
