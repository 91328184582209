import { PURGE } from "redux-persist";

const initialState = {
  therapists: [],
  therapistProfile: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_THERAPISTS":
      return {
        ...state,
        therapists: [...payload],
      };
    case "CREATE_THERAPIST_PROFILE":
      return {
        ...state,
        therapistProfile: payload,
      };
    case "FETCH_THERAPIST_PROFILE":
      return {
        ...state,
        therapistProfile: payload,
      };
    case "CREATE_THERAPIST_PRACTICE_INFO":
      return {
        ...state,
        therapistPracticeInfo: payload,
      };
    case "FETCH_THERAPIST_PRACTICE_INFO":
      return {
        ...state,
        therapistPracticeInfo: payload,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
