import React, { useEffect, useState } from "react";
import "./ConfigureAssignment.styles.scss";
import { Assignment, Field, Section } from "types";
import AssignmentFields from "../AssignmentDetails/AssignmentFields";
import FieldConfigure from "./components/FieldConfigure";
import { Button } from "components";
import { useUpdateAssignment, useGetAssignments } from "utils/hooks";

type Props = {
  assignment: Assignment;
  canSaveSettings: boolean;
  setCanSaveSettings?: (data: boolean) => void;
  isGroup?: boolean;
};

type GenerateField = (
  field: Field,
  section: Section,
  originalAssignment?: Assignment
) => any;

const ConfigureAssignment = ({
  assignment,
  canSaveSettings,
  setCanSaveSettings,
  isGroup,
}: Props) => {
  const {
    assignment: originalAssignment,
    groupAssignment: originalGroupAssignment,
  } = useGetAssignments({
    assignmentId: assignment?.uuid,
  });

  const [activeAssignment, setActiveAssignment] = useState({
    ...structuredClone(isGroup ? originalGroupAssignment : originalAssignment),
  });
  const [loaderVisible, setLoaderVisible] = useState(false);

  const {
    updateAssignmentForPatient,
    updateAssignmentForGroup,
    unsavedConfigureSettings,
    isSuccessful,
  } = useUpdateAssignment();

  useEffect(() => {
    if (canSaveSettings && unsavedConfigureSettings) {
      setCanSaveSettings && setCanSaveSettings(false);
      handleSubmit();
    }
  });

  useEffect(() => {
    if (isSuccessful) {
      setLoaderVisible(false);
    }
  }, [isSuccessful]);

  const handleSubmit = () => {
    setLoaderVisible(true);
    isGroup
      ? updateAssignmentForGroup(activeAssignment)
      : updateAssignmentForPatient(activeAssignment);
  };

  const generateField: GenerateField = (field, section, assignm) => {
    return (
      <FieldConfigure
        key={field.uuid}
        field={field}
        section={section}
        setActiveAssignment={setActiveAssignment}
        originalAssignment={assignm}
      />
    );
  };

  return (
    <div className="configure_container">
      <AssignmentFields
        assignment={activeAssignment}
        originalAssignment={
          isGroup ? originalGroupAssignment : originalAssignment
        }
        fieldComponent={generateField}
        panel={"Configure"}
      />
      {unsavedConfigureSettings && (
        <Button
          onClick={handleSubmit}
          name={`save_assignment_notifications_button`}
          value="Save Configuration"
          extraClass="save-settings"
          size="big"
          isLoading={loaderVisible}
        />
      )}
    </div>
  );
};

export default ConfigureAssignment;
