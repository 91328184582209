import "./GroupInfoCard.scss";
import React from "react";
import { Group } from "types";

interface GroupInfoCardProps {
  group: Group;
}

const GroupInfoCard = ({ group }: GroupInfoCardProps) => {
  const { name, description, location, address } = group;
  const { address1, address2, city, state, country } = address;
  return (
    <div className="group-info-card">
      <div className="group-info-card__group_info">
        <table>
          <tbody>
            <tr className="group-info-table-row">
              <td>{name}</td>
            </tr>
            {description && (
              <tr className="group-info-table-row">
                <td>{description}</td>
              </tr>
            )}
            {location && (
              <tr className="group-info-table-row">
                <td>{location}</td>
              </tr>
            )}
            {address1 && (
              <tr className="group-info-table-row">
                <td>{address1}</td>
              </tr>
            )}
            {address2 && (
              <tr className="group-info-table-row">
                <td>{address2}</td>
              </tr>
            )}
            {city && (
              <tr className="group-info-table-row">
                <td>{city}</td>
              </tr>
            )}
            {state && (
              <tr className="group-info-table-row">
                <td>{state}</td>
              </tr>
            )}
            {country && (
              <tr className="group-info-table-row">
                <td>{country}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GroupInfoCard;
