/**
 * @return {
 *  null
 *  | "Mac OS"
 *  | "iOS"
 *  | "Windows"
 *  | "Android"
 *  | "Linux"
 * }
 */
import { useEffect } from "react";

const getOS = () => {
  const userAgent = window.navigator.userAgent;
  const platform =
    window.navigator?.userAgentData?.platform ?? window.navigator.platform;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (/Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
};

const useDeviceInfo = () => {
  useEffect(() => {
    if (process.env.REACT_APP_DEVICE_OS_OVERWRITE) {
      console.warn(
        `Device OS is overwritten on ${process.env.REACT_APP_DEVICE_OS_OVERWRITE}`
      );
    }
  }, []);

  return {
    OS: process.env.REACT_APP_DEVICE_OS_OVERWRITE || getOS(),
  };
};

export default useDeviceInfo;
