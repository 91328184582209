export const fetchPublicFilesAction = (files) => {
  return {
    type: "FETCH_PUBLIC_FILES",
    payload: files,
  };
};

export const fetchTherapistFilesAction = (files) => {
  return {
    type: "FETCH_THERAPIST_FILES",
    payload: files,
  };
};

export const fetchPatientFilesAction = (files) => {
  return {
    type: "FETCH_PATIENT_FILES",
    payload: files,
  };
};

export const fetchGroupFilesAction = (files) => {
  return {
    type: "FETCH_GROUP_FILES",
    payload: files,
  };
};

export const fetchFileAttachmentAction = (file) => {
  return {
    type: "FETCH_FILE_ATTACHMENT",
    payload: file,
  };
};

export const addFileAction = (file) => {
  return {
    type: "ADD_THERAPIST_FILE",
    payload: file,
  };
};

export const addGroupFileAction = (file) => {
  return {
    type: "ADD_GROUP_FILE",
    payload: file,
  };
};

export const updateFile = (files) => {
  return {
    type: "UPDATE_THERAPIST_FILES",
    payload: files,
  };
};

export const updateGroupFile = (files) => {
  return {
    type: "UPDATE_GROUP_FILES",
    payload: files,
  };
};

export const deleteFileAction = (fileId) => {
  return {
    type: "DELETE_THERAPIST_FILE",
    payload: fileId,
  };
};

export const deleteGroupFileAction = (fileId) => {
  return {
    type: "DELETE_GROUP_FILE",
    payload: fileId,
  };
};

export const fetchPatientPublicFilesAction = (files) => {
  return {
    type: "FETCH_PATIENT_PUBLIC_FILES",
    payload: files,
  };
};
