// TopNavHeightContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  FC,
} from "react";

interface TopNavHeightContextType {
  topNavHeight: number;
  setTopNavHeight: (height: number) => void;
}

const TopNavHeightContext = createContext<TopNavHeightContextType | undefined>(
  undefined
);

export const TopNavHeightProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [topNavHeight, setTopNavHeight] = useState(0);

  return (
    <TopNavHeightContext.Provider value={{ topNavHeight, setTopNavHeight }}>
      {children}
    </TopNavHeightContext.Provider>
  );
};

export const useTopNavHeight = () => {
  const context = useContext(TopNavHeightContext);
  if (!context) {
    throw new Error(
      "useTopNavHeight must be used within a TopNavHeightProvider"
    );
  }
  return context;
};
