import { useState } from "react";
import { toast } from "react-toastify";

const useResendPatientInvite = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const submitResendInvite = (email) => {
    if (!email) {
      return null;
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        setIsSubmitting(true);
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                resendPatientInvite(email: "${email}")
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => {
            if (res.status !== 200 && res.status !== 201) {
              setIsSubmitting(false);
              toast.error(`Hmm, something went wrong.`);
              resolve();
              throw new Error("Failed!");
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              setIsSubmitting(false);
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              setIsSubmitting(false);
              toast(`Invitation sent!`);
              return true;
            }
          })
          .catch((err) => {
            setIsSubmitting(false);
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitResendInvite,
    isSubmitting,
  };
};

export default useResendPatientInvite;
