import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useFetchNotes from "./useFetchNotes";
import { Note } from "types";

interface UseGetNotesProps {
  patientId?: string;
  groupId?: string;
}

const useGetNotes = ({ patientId, groupId }: UseGetNotesProps) => {
  const { fetchNotes, fetchGroupNotes } = useFetchNotes();

  const notes = useSelector(
    (state: RootStateOrAny) => state.notes && state.notes
  );

  useEffect(() => {
    if (notes?.notes?.length === 0) {
      fetchNotes();
    }
    if (groupId) {
      fetchGroupNotes(groupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const patientNotes = notes.notes.filter(
    (note: Note) => note.patientId === patientId
  );

  return {
    notes: notes.notes,
    patientNotes: patientNotes,
    groupNotes: notes.groupNotes || [],
  };
};

export default useGetNotes;
