import { useState } from "react";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { updatePatientAction } from "store/actions/patients";
import { toast } from "react-toastify";
import { useCurrentUser, useFetchPatients } from "utils/hooks";

const useEditPatientProfile = ({ patientId }) => {
  const dispatch = useDispatch();
  const [isProfileSubmitting, setIsProfileSubmitting] = useState(false);
  const { fetchAllPatients } = useFetchPatients();
  const { user } = useCurrentUser();

  const updatePatientProfile = (args) => {
    if (!args || !user) {
      return null;
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        setIsProfileSubmitting(true);
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                updatePatientProfile(patient: {
                  patientId: "${patientId}",
                  firstName: "${args.first_name}",
                  lastName: "${args.last_name}",
                  dob: "${args.dob}",
                  gender: "${args.gender?.value}",
                }) {
                  firstName
                  lastName
                  profile {
                    dob
                    gender
                  }
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => {
            if (res.status !== 200 && res.status !== 201) {
              setIsProfileSubmitting(false);
              toast.error(`Hmm, something went wrong.`);
              resolve();
              throw new Error("Failed!");
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              setIsProfileSubmitting(false);
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              if (resData.data.updatePatientProfile) {
                setIsProfileSubmitting(false);
                fetchAllPatients();
                toast(`Patient profile has been updated!`);
                ReactGA.event("patient_profile_updated", {
                  user_id: user.id,
                  patient_id: patientId,
                });
              }
              return dispatch(
                updatePatientAction(resData.data.updatePatientProfile)
              );
            }
          })
          .catch((err) => {
            setIsProfileSubmitting(false);
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    updatePatientProfile,
    isProfileSubmitting,
  };
};

export default useEditPatientProfile;
