import React from "react";
import ReactPlayer from "react-player";
import "./VideoEmbed.styles.scss";

interface VideoEmbedProps {
  videoURL?: string;
  uuid?: string;
}

const VideoEmbed: React.FC<VideoEmbedProps> = ({ videoURL, uuid }) => {
  return (
    <div key={uuid} className="player-wrapper">
      <ReactPlayer
        width="100%"
        height="100%"
        url={videoURL}
        light
        style={{ position: "absolute", top: 0, left: 0 }}
      />
    </div>
  );
};

export default VideoEmbed;
