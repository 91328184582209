import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Assignment } from "types";
import { ASSIGNMENT_STATUSES } from "utils/constants";
import { useFetchAssignments } from "utils/hooks";

interface UseGetAssignmentsProps {
  assignmentId?: string;
  patientId?: string;
  groupId?: string;
}

const useGetAssignments = ({
  assignmentId,
  patientId,
  groupId,
}: UseGetAssignmentsProps) => {
  const { fetchGroupAssignments } = useFetchAssignments();
  const allAssignments = useSelector(
    (state: RootStateOrAny) => state.assignments && state.assignments
  );

  useEffect(() => {
    fetchGroupAssignments(groupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const patientAssignments = allAssignments.assignments.filter(
    (assignment: Assignment) => assignment.patientId === patientId
  );

  const patientAssignment = allAssignments?.assignments?.find(
    (a: Assignment) => a.uuid === assignmentId
  );

  const allVisibleAssignments = allAssignments.assignments.filter(
    (assignment: Assignment) =>
      assignment.status === ASSIGNMENT_STATUSES.VISIBLE &&
      assignment.type !== "SAFETY_PLAN"
  );

  const groupAssignment = allAssignments?.groupAssignments?.find(
    (assignment: Assignment) => assignment.uuid === assignmentId
  );

  return {
    assignment: patientAssignment,
    assignments: patientAssignments,
    allVisibleAssignments,
    hasAssignments: allAssignments.assignments.length > 0,
    patientAssignment: patientAssignment,
    groupAssignments: allAssignments.groupAssignments || [],
    groupAssignment,
  };
};

export default useGetAssignments;
