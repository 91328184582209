import { useState } from "react";
import ReactGA from "react-ga4";
import { addNoteAction, addGroupNoteAction } from "store/actions/notes";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useFetchNotes, useLogout, useCurrentUser } from "utils/hooks";
import { formatInput } from "utils/helpers";

const useAddNote = ({ patientId, groupId }) => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchNotes, fetchGroupNotes } = useFetchNotes();
  const { user } = useCurrentUser();

  const submitAddNote = (args) => {
    if (!args || !args.body) {
      return null;
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                addNote(note: {
                  date: "${args.date}",
                  body: "${formatInput(args.body)}",
                  referenceUrl: "${args.referenceUrl}",
                  patientId: "${patientId}",
                }) {
                  uuid
                  date
                  body
                  referenceUrl
                  patientId
                  createdAt
                  updatedAt
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              fetchNotes();
              toast("Note Added");
              setIsSubmitSuccessful(true);
              resolve();
              ReactGA.event("note_added_success", {
                user_id: user?.id,
              });
              return dispatch(addNoteAction(resData.data.addNote));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  const submitAddGroupNote = (args) => {
    if (!args || !args.body) {
      return null;
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                addNote(note: {
                  date: "${args.date}",
                  body: "${formatInput(args.body)}",
                  referenceUrl: "${args.referenceUrl}",
                  groupId: "${groupId}",
                }) {
                  uuid
                  date
                  body
                  referenceUrl
                  patientId
                  groupId
                  createdAt
                  updatedAt
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              fetchGroupNotes(groupId);
              toast("Note Added");
              setIsSubmitSuccessful(true);
              resolve();
              ReactGA.event("note_added_success", {
                user_id: user?.id,
                group_id: groupId,
              });
              return dispatch(addGroupNoteAction(resData.data.addNote));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitAddNote,
    submitAddGroupNote,
    isSubmitSuccessful,
  };
};

export default useAddNote;
