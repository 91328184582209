import React from "react";
import "./TherapistPendingInviteForPatient.styles.scss";
import BirdTwo from "assets/images/bird-1.png";
import { formatTitles } from "utils/helpers";

interface Therapist {
  uuid: string;
  pendingAssignments: string[];
}

interface TherapistPendingInviteForPatientProps {
  pendingTherapists: Therapist[];
}

const TherapistPendingInviteForPatient: React.FC<
  TherapistPendingInviteForPatientProps
> = ({ pendingTherapists }) => {
  const formattedTherapists = pendingTherapists.map((therapist) => {
    const formattedAssignmentTitles = formatTitles(
      therapist.pendingAssignments
    );
    const assignmentText = formattedAssignmentTitles
      ? `You have currently have ${formattedAssignmentTitles} assigned and waiting for you.`
      : "";

    return <p key={therapist.uuid}>{assignmentText}</p>;
  });

  return (
    <div className="therapist-pending-invite-for-patient">
      <h3>Your therapist would like to connect.</h3>
      {formattedTherapists.length > 0 ? formattedTherapists : <></>}
      <p>Please check your email inbox to accept the invitation.</p>
      <div className="therapist-pending-invite-for-patient__image-holder">
        <img
          className="therapist-pending-invite-for-patient__image"
          src={BirdTwo}
          alt="red bird"
        />
      </div>
    </div>
  );
};

export default TherapistPendingInviteForPatient;
