import { useCurrentUser } from "utils/hooks";
import { useNavigate } from "react-router-dom";
import { useQueryParams } from "utils/hooks";

const useGuestRedirect = () => {
  const { isAuthenticated } = useCurrentUser();
  const history = useNavigate();

  const { hasUrlParams } = useQueryParams();
  const nextUrl = hasUrlParams("next");

  const redirect = () => {
    if (!isAuthenticated) {
      return history("/login");
    } else {
      if (nextUrl) {
        return history(nextUrl);
      }
    }
  };

  return {
    redirect,
  };
};

export default useGuestRedirect;
