import "react-datepicker/dist/react-datepicker.css";
import "./DateField.styles.scss";
import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

interface DateFieldProps {
  label: string;
  error?: string;
  selected?: any;
  onChange: (date?: Date | null) => void;
  hint?: string;
  placeholder?: string;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  showTimeSelect?: boolean;
  dateFormat?: string;
  maxDate?: string | Date;
  minDate?: string | Date;
  disabled?: boolean;
}

const DateField = ({
  label,
  error,
  selected,
  onChange,
  hint,
  placeholder,
  showMonthDropdown,
  showYearDropdown,
  showTimeSelect = false,
  dateFormat = "MM/dd/yyyy",
  maxDate,
  minDate,
  disabled,
  ...props
}: DateFieldProps) => {
  return (
    <div className="date-field">
      <label className="date-field__label">{label}</label>
      <DatePicker
        aria-label={label}
        className={`date-field__field ${error ? "error" : ""}`}
        selected={selected}
        onChange={onChange}
        peekNextMonth
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        showTimeSelect={showTimeSelect}
        dropdownMode="select"
        placeholderText={placeholder}
        dateFormat={dateFormat}
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
        {...props}
      />
      {error && (
        <p className="date-field__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && (
        <p className="date-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};

DateField.defaultProps = {
  type: "date",
};

export default DateField;
