import "./Modal.styles.scss";
import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  const nodeRef = useRef(null);
  const innerRef = useRef(null);

  return (
    <>
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={300}
        classNames="overlay"
        unmountOnExit
      >
        <div
          ref={nodeRef}
          className="local-modal-overlay"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
            onClose();
          }}
          role="button"
          tabIndex={0}
        />
      </CSSTransition>
      <CSSTransition
        nodeRef={innerRef}
        in={isOpen}
        timeout={300}
        classNames="animated-modal"
        unmountOnExit
      >
        <div ref={innerRef} className="local-modal-inner">
          {children}
        </div>
      </CSSTransition>
    </>
  );
};

export default Modal;
