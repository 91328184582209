import React from "react";
import { Icon } from "components";

interface Mood {
  label: string;
  icon: string;
  theme: string;
}

interface MoodIconProps {
  mood: Mood;
}

const MoodIcon = ({ mood }: MoodIconProps) => {
  return (
    <div className="mood-scale-field__icon">
      <Icon src={mood.icon} alt={mood.label} />
    </div>
  );
};

export default MoodIcon;
