import "./PinnedField.styles.scss";
import React, { useState } from "react";
import { Icon } from "components";
import AngleLeftThin from "assets/icons/arrow-left-thin.svg";
import AngleDownThin from "assets/icons/arrow-down-thin.svg";

interface Props {
  title: string;
  collapsed?: boolean;
  onCollapsed?: (collapsed: boolean) => void;
  children?: React.ReactNode;
}
const PinnedField = ({ title, children }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <div className={"pinned collapse"}>
      <div>
        <div
          className={"collapse-icon"}
          onClick={() => setIsCollapsed(!isCollapsed)}
          onKeyDown={() => setIsCollapsed(!isCollapsed)}
          role="button"
          tabIndex={0}
        >
          <Icon src={isCollapsed ? AngleLeftThin : AngleDownThin} />
        </div>
        {isCollapsed && (
          <div className={"collapse-collapsed"}>
            <div className={"collapse-title"}>{title}</div>
          </div>
        )}
        {!isCollapsed && (
          <div className={"collapse-expanded"}>
            <div>
              <div className={"collapse-title"}>{title}</div>
              <div className={"collapse-content"}>{children}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PinnedField;
