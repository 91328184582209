import { AssignmentSubmission } from "types";
import getLastSubmissionDate from "pages/AppPages/TherapistPages/AssignmentSubmissionsPage/utils/getLastSubmissionTime";
type SubmissionArray = Array<AssignmentSubmission>;

const getLastSubmission = (submissionArray: SubmissionArray) => {
  const sortedArr = submissionArray.sort((a, b) => b.createdAt - a.createdAt);
  return getLastSubmissionDate(sortedArr[0].createdAt);
};

export default getLastSubmission;
