import "./Logo.styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { BRAND_NAME } from "utils/constants";

interface LogoProps {
  theme?: string;
}

const Logo = ({ theme }: LogoProps) => {
  return (
    <div className={`logo ${theme}`}>
      <p className="logo__text">{(BRAND_NAME as string).toLowerCase()}</p>
    </div>
  );
};

Logo.propTypes = {
  theme: PropTypes.string,
};

Logo.defaultProps = {
  theme: "light",
};

export default Logo;
