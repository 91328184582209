import { useState } from "react";
import ReactGA from "react-ga4";
import {
  addLibraryResource,
  addGroupLibraryResource,
} from "store/actions/library";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useFetchLibrary,
  useLogout,
  useCurrentUser,
  useGetTherapistLibrary,
} from "utils/hooks";
import { formatInput } from "utils/helpers";
import { formattedSharedWith } from "./libraryUtils";

interface useAddLibraryResourceProps {
  selectedPatients: any;
  groupId?: string;
}

const useAddLibraryResource = ({
  selectedPatients,
  groupId,
}: useAddLibraryResourceProps) => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchTherapistLibrary, fetchGroupLibrary } = useFetchLibrary();
  const { user } = useCurrentUser();
  const { therapistResources } = useGetTherapistLibrary({});

  const submitAddLibraryResource = (args: any) => {
    if (!args) {
      return null;
    }

    const existingUrl = therapistResources.find(
      (resource: any) => resource.resourceUrl === args.resourceUrl
    );

    if (existingUrl) {
      toast.warn(`A resource with this link already exists in your library :)`);
      return null;
    }

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                addLibraryResource(resource: {
                  resourceUrl: "${formatInput(args.resourceUrl)}",
                  title: "${formatInput(args.title)}",
                  description: "${formatInput(args.description)}",
                  sharedWith: [${formattedSharedWith(selectedPatients)}],
                  imageUrl: "${formatInput(args.imageUrl)}",
                }) {
                  resourceUrl
                  title
                  description
                  imageUrl
                  uuid
                  addedBy
                  updatedAt
                  sharedWith {
                    label
                    value
                  }
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              }
              if (
                error.errors[0].message.includes(
                  "CUSTOM_RESOURCE_LIMIT_REACHED"
                )
              ) {
                toast.warn(`Custom link limit reached, please upgrade`);
                setIsSubmitSuccessful(true);
                resolve();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              fetchTherapistLibrary();
              toast("Resource Added");
              setIsSubmitSuccessful(true);
              resolve();
              ReactGA.event("library_resource_added_success", {
                user_id: user?.id,
              });
              return dispatch(
                addLibraryResource(resData.data.addLibraryResource)
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  const submitAddGroupLibraryResource = (args: any) => {
    if (!args) {
      return null;
    }

    const existingUrl = therapistResources.find(
      (resource: any) => resource.resourceUrl === args.resourceUrl
    );

    if (existingUrl) {
      toast.warn(`A resource with this link already exists in your library :)`);
      return null;
    }

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                addLibraryResource(resource: {
                  resourceUrl: "${formatInput(args.resourceUrl)}",
                  title: "${formatInput(args.title)}",
                  description: "${formatInput(args.description)}",
                  sharedWith: [${formattedSharedWith(selectedPatients)}],
                  imageUrl: "${formatInput(args.imageUrl)}",
                  groupId: "${groupId}",
                }) {
                  resourceUrl
                  title
                  description
                  imageUrl
                  uuid
                  addedBy
                  updatedAt
                  groupId
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              }
              if (
                error.errors[0].message.includes(
                  "CUSTOM_RESOURCE_LIMIT_REACHED"
                )
              ) {
                toast.warn(`Custom link limit reached, please upgrade`);
                setIsSubmitSuccessful(true);
                resolve();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              fetchGroupLibrary(groupId);
              toast("Resource Added");
              setIsSubmitSuccessful(true);
              resolve();
              ReactGA.event("library_resource_added_success", {
                user_id: user?.id,
                group_id: groupId,
              });
              return dispatch(
                addGroupLibraryResource(resData.data.addLibraryResource)
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitAddLibraryResource,
    submitAddGroupLibraryResource,
    isSubmitSuccessful,
  };
};

export default useAddLibraryResource;
