import "./AppLayout.styles.scss";
import React, { useEffect, ReactNode, FC } from "react";
import { MobileBottomNav, SideNav, TopNav } from "components";
import { useCurrentUser, useGuestRedirect, useViewport } from "utils/hooks";
import useSubmissionUtils from "pages/AppPages/Shared/Submissions/utils";

interface AppLayoutNoContextProps {
  children: ReactNode;
}

const AppLayoutNoContext: FC<AppLayoutNoContextProps> = ({ children }) => {
  const { redirect } = useGuestRedirect();
  const { isMobile } = useViewport();

  useEffect(() => {
    redirect();
  }, [redirect]);

  const { isVerified, isAuthenticated, user } = useCurrentUser();
  const { showConfirmEmailBar } = useSubmissionUtils();

  const MemoizedSideNav = React.memo(() => <SideNav />);
  MemoizedSideNav.displayName = "MemoizedSideNav";

  const showAlertBar =
    isAuthenticated && !isVerified && user && showConfirmEmailBar;

  return (
    <div className="flex">
      {isMobile ? <MobileBottomNav /> : <MemoizedSideNav />}
      <div className="flex col app-body">
        {!isMobile && <TopNav hasAlertBar={showAlertBar} />}
        <div
          className={`app-content topnav-content ${
            showAlertBar ? "hasAlertBar" : ""
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default AppLayoutNoContext;
