import React from "react";
import { Animate, FileUploader } from "components";
import "./AddFileForm.styles.scss";

interface AddFileFormProps {
  onSuccess: () => void;
  currentFile: any;
  defaultSharedWith?: any;
  showPatients?: boolean;
  groupId?: string;
  title?: string;
  submitButtonText?: string;
}

const AddFileForm = ({
  onSuccess,
  currentFile,
  defaultSharedWith,
  showPatients = true,
  groupId,
  title,
}: AddFileFormProps) => {
  return (
    <div id="add_file_form" className="add_file_form">
      {title && <h1 className="add_file_form_title">{title}</h1>}
      <Animate animation="fadeInLeft" delay=".25">
        <label className="input__label mb-2" htmlFor="file-uploader">
          File
        </label>
        <FileUploader
          onSuccess={() => onSuccess()}
          showPatients={showPatients}
          currentFile={currentFile}
          defaultSharedWith={defaultSharedWith}
          groupId={groupId}
        />
      </Animate>
    </div>
  );
};

export default AddFileForm;
