import React from "react";
import { Field } from "types";
import DatePicker from "react-datepicker";
import { format } from "date-fns";

interface Props {
  field: Field;
  defaultValue: any;
  onChange: (value: any) => void;
  error?: string;
}
const TimeField = ({ field, defaultValue, onChange }: Props) => {
  let formattedDate: Date | null = new Date(defaultValue);

  if (formattedDate.toString() === "Invalid Date") {
    formattedDate = null;
  }
  const [value, setValue] = React.useState(formattedDate as Date | null);
  const handleTimeChange = (date: Date) => {
    setValue(date);

    onChange(format(date, "MM/dd/yyyy hh:mm:ss"));
  };
  return (
    <div className="time-field">
      <label className="time-field__label">{field.label}</label>
      <DatePicker
        aria-label={field.label}
        className={`date-field__field ${field.error ? "error" : ""}`}
        selected={value}
        showTimeSelect
        showTimeSelectOnly
        onChange={handleTimeChange}
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        placeholderText=""
      />
    </div>
  );
};

export default TimeField;
