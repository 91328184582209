import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Animate,
  Button,
  Input,
  Select,
  ButtonLoading,
  Textarea,
} from "components";

import { useCreateGroup, useUpdateGroup } from "utils/hooks";
import { useTranslation } from "react-i18next";
import { Group } from "types";
import { countryOptions, stateOptions } from "forms/TherapistForms/options";

interface AddGroupFormProps {
  onSuccess: () => void;
  group: Group | null;
}

const AddGroupForm = ({ onSuccess, group }: AddGroupFormProps) => {
  const { t } = useTranslation(["common"]);
  const { register, handleSubmit, errors, formState, control } = useForm();
  const { submitCreateGroup, isSubmitSuccessful } = useCreateGroup();
  const { submitUpdateGroup, isUpdateSuccessful } = useUpdateGroup(group?.uuid);
  const { isSubmitting } = formState;

  useEffect(() => {
    if (isSubmitSuccessful || isUpdateSuccessful) {
      onSuccess();
    }
  }, [isSubmitSuccessful, onSuccess, isUpdateSuccessful]);

  const currentState = stateOptions.filter(
    (state) => state.value === group?.address?.state
  )[0];

  const currentCountry = countryOptions.filter(
    (state) => state.value === group?.address?.country
  )[0];

  return (
    <div id="add_group_form">
      <form
        onSubmit={handleSubmit(group ? submitUpdateGroup : submitCreateGroup)}
        autoComplete="off"
      >
        <Animate animation="fadeInLeft" delay=".25">
          <Input
            name="name"
            type="text"
            label={t("groups.group_name")}
            placeholder={t("groups.group_name_placeholder")}
            forwardRef={register({ required: true })}
            error={errors.name && t("groups.group_name_required")}
            defaultValue={group?.name ?? undefined}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".35">
          <Textarea
            name="description"
            label={t("groups.group_description")}
            placeholder={t("groups.group_description_placeholder")}
            forwardRef={register()}
            defaultValue={group?.description ?? undefined}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".45">
          <Input
            name="location"
            label={t("groups.group_location")}
            placeholder={t("groups.group_location_placeholder")}
            forwardRef={register()}
            defaultValue={group?.location ?? undefined}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".55">
          <Input
            name="address1"
            label={t("groups.group_address1")}
            placeholder={t("groups.group_address1_placeholder")}
            forwardRef={register()}
            defaultValue={group?.address?.address1 ?? undefined}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".65">
          <Input
            name="address2"
            label={t("groups.group_address2")}
            placeholder={t("groups.group_address2_placeholder")}
            forwardRef={register()}
            defaultValue={group?.address?.address2 ?? undefined}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".75">
          <Input
            name="city"
            label={t("groups.city")}
            placeholder={t("groups.city_placeholder")}
            forwardRef={register()}
            defaultValue={group?.address?.city ?? undefined}
          />
        </Animate>
        <Animate
          animation="fadeInLeft"
          delay=".85"
          style={{ position: "relative" }}
        >
          <Select
            name="state"
            label={t("groups.state")}
            placeholder={t("groups.group_state_placeholder")}
            options={stateOptions}
            forwardRef={register()}
            forwardControl={control}
            defaultValue={group && currentState}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".95">
          <Select
            name="country"
            label={t("groups.country")}
            placeholder={t("groups.group_country_placeholder")}
            options={countryOptions}
            forwardRef={register()}
            forwardControl={control}
            defaultValue={group && currentCountry}
          />
        </Animate>
        <Button
          type="submit"
          name="add_group_button"
          value={
            group ? (
              t("groups.edit_group_button")
            ) : isSubmitting ? (
              <ButtonLoading />
            ) : (
              t("groups.add_group_button")
            )
          }
          fullWidth
          isLoading={isSubmitting}
        />
      </form>
    </div>
  );
};

export default AddGroupForm;
