import React from "react";
import { useForm } from "react-hook-form";
import AuthenticationHeader from "../components/AuthenticationHeader";
import AuthenticationFooter from "../components/AuthenticationFooter";
import { Animate, Button, Input, Link } from "components";
import { BRAND_NAME } from "utils/constants";

import { useRequestPasswordReset } from "utils/hooks";

const ForgotPassword = () => {
  const { register, handleSubmit, errors, formState } = useForm();
  const { submitResetRequest } = useRequestPasswordReset();
  const { isSubmitting } = formState;

  return (
    <div id="forgot_password_page">
      <Animate animation="fadeInLeft" delay=".15">
        <AuthenticationHeader
          headline={`Reset your password`}
          subline={`Type in the email address you used when you signed up for ${BRAND_NAME} and we'll email you a password reset link.`}
        />
      </Animate>
      <form onSubmit={handleSubmit(submitResetRequest)}>
        <Animate animation="fadeInLeft" delay=".25">
          <Input
            name="email"
            type="email"
            label="Email"
            placeholder="name@email.com"
            forwardRef={register({ required: true })}
            error={errors.email && "Email field is required"}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".45">
          <Button
            type="submit"
            name="password_reset_button"
            value="Send Reset Link"
            fullWidth
            isLoading={isSubmitting}
          />
        </Animate>
      </form>
      <Animate animation="fadeInLeft" delay=".55">
        <AuthenticationFooter
          lineTwo={
            <>
              Go back to <Link to="/login">Log in</Link>
            </>
          }
        />
      </Animate>
    </div>
  );
};

export default ForgotPassword;
