// ColorPicker.tsx
import React, { useCallback } from "react";
import "./ColorPicker.styles.scss";
import { defaultColors } from "./DefaultColors";

interface ColorPickerProps {
  selectedColor?: string;
  onColorChange: (color: string) => void;
  colors?: string[];
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  selectedColor,
  onColorChange,
  colors,
}) => {
  const handleColorClick = useCallback(
    (color: string) => {
      onColorChange(color);
    },
    [onColorChange]
  );

  const colorChoices = colors || defaultColors;

  return (
    <div>
      <label className="color-icon-label" htmlFor="colorOptions">
        Select a color:
      </label>
      <div className="color-options" id="colorOptions">
        {colorChoices.map((color, index) => (
          <div
            key={index}
            onClick={() => handleColorClick(color)}
            onKeyDown={() => handleColorClick(color)}
            role="button"
            tabIndex={0}
            className="color-option"
            style={{
              backgroundColor: color,
              borderColor: color === selectedColor ? "black" : "transparent",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorPicker;
