import React, { useEffect, useState } from "react";
import { Icon, ImageDisplay } from "components";
import "./ImageGallery.styles.scss";
import { useGetTherapistFiles } from "utils/hooks";
import CheckIcon from "assets/icons/check.svg";

type ImageGalleryProps = {
  currentImageId?: string;
  onSelect: (file: any) => void;
};

const ImageGallery = ({ currentImageId, onSelect }: ImageGalleryProps) => {
  const { therapistImages } = useGetTherapistFiles({});
  const [currentImage, setCurrentImage] = useState(currentImageId);
  const handleImageClick = (image: any) => {
    setCurrentImage(image.uuid);
    onSelect(image);
  };

  useEffect(() => {
    setCurrentImage(currentImageId);
  }, [currentImageId]);

  if (therapistImages?.length === 0) {
    return <></>;
  }
  return (
    <>
      <p className="text-center choose-image-label">
        or choose from your gallery
      </p>
      <div className="image-gallery">
        {therapistImages?.map((image: any, i: React.Key | null | undefined) => {
          return (
            <div
              tabIndex={0}
              role="button"
              key={i}
              className={`image-gallery-thumb ${
                currentImage === image.uuid ? "active-thumb" : ""
              }`}
              onClick={() => handleImageClick(image)}
              onKeyDown={() => handleImageClick(image)}
            >
              <div className="image-gallery-thumb__active-icon">
                <Icon src={CheckIcon} />
              </div>
              <ImageDisplay image={image.fileUrl} type="thumb" />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ImageGallery;
