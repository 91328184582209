import { useState } from "react";
import ReactGA from "react-ga4";
import { updateFile, updateGroupFile } from "store/actions/files";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useFetchFiles,
  useLogout,
  useCurrentUser,
  useGetTherapistFiles,
  useGetGroupFiles,
} from "utils/hooks";
import { formatInput } from "utils/helpers";
import { formattedSharedWith } from "./fileUtils";
import { FileResource } from "types";

interface UseUpdateFileProps {
  files: any;
  selectedPatients?: any[] | null;
  groupId?: string;
}

interface argsProps {
  fileUrl: any;
  title: any;
  description: any;
}

const useUpdateFile = ({
  files,
  selectedPatients,
  groupId,
}: UseUpdateFileProps) => {
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchTherapistFiles, fetchGroupFiles } = useFetchFiles();
  const { user } = useCurrentUser();
  const { therapistFiles } = useGetTherapistFiles({});
  const { groupFiles } = useGetGroupFiles({ groupId });

  const formatFiles = (args: argsProps[], files: FileResource[]) =>
    files.map(
      (file, index) =>
        `{
          uuid: "${file.uuid}",
          title: "${formatInput(args[index].title)}",
          description: "${formatInput(args[index].description)}",
          sharedWith: [${formattedSharedWith(selectedPatients)}],
          }`
    );

  const submitUpdateFile = (args: argsProps[]) => {
    if (!args || args.length === 0) {
      return null;
    }

    if (files?.length === 0 && args.length === 1) {
      const filteredFiles = therapistFiles.filter(
        (therapistFile: { uuid: any }) => therapistFile.uuid !== files[0].uuid
      );

      const existingUrl = filteredFiles.find(
        (therapistFile: { fileUrl: any }) =>
          therapistFile.fileUrl === args[0].fileUrl
      );

      if (existingUrl) {
        toast.warn(`A file with this url already exists in your files :)`);
        return null;
      }
    }

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                updateFile(files: [${formatFiles(args, files)}]) {
                  fileUrl
                  title
                  description
                  uuid
                  addedBy
                  updatedAt
                  groupId
                  sharedWith {
                    label
                    value
                  }
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              fetchTherapistFiles();
              toast("File Updated");
              setIsUpdateSuccessful(true);
              resolve();
              ReactGA.event("files_file_added_success", {
                user_id: user?.id,
              });
              return dispatch(updateFile(resData.data.updateFile));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  const submitUpdateGroupFile = (args: argsProps[]) => {
    if (!args || args.length === 0) {
      return null;
    }

    if (files?.length === 0 && args.length === 1) {
      const filteredFiles = groupFiles.filter(
        (groupFile: { uuid: any }) => groupFile.uuid !== files[0].uuid
      );

      const existingUrl = filteredFiles.find(
        (groupFile: { fileUrl: any }) => groupFile.fileUrl === args[0].fileUrl
      );

      if (existingUrl) {
        toast.warn(`A file with this url already exists in your files :)`);
        return null;
      }
    }

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                updateFile(files: [${formatFiles(args, files)}]) {
                  fileUrl
                  title
                  description
                  uuid
                  addedBy
                  updatedAt
                  groupId
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              fetchGroupFiles(groupId);
              toast("File Updated");
              setIsUpdateSuccessful(true);
              resolve();
              ReactGA.event("files_file_added_success", {
                user_id: user?.id,
                group_id: groupId,
              });
              return dispatch(updateGroupFile(resData.data.updateFile));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitUpdateFile,
    submitUpdateGroupFile,
    isUpdateSuccessful,
  };
};

export default useUpdateFile;
