import { useDispatch } from "react-redux";
import setAuthenticatedUser from "store/actions/auth";

const useRefreshUser = () => {
  const dispatch = useDispatch();
  const submitRefreshRequest = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
            query {
              refreshUser {
                userId
                firstName
                lastName
                email
                expiresIn
                type
                verified
                lastLogin
                isSubscribed
                createdAt
                digestSubscription
                plan {
                  name
                  patientLimit
                  assignmentLimit
                  customAssignmentLimit
                  customResourceLimit
                  fileLimit
                  groupLimit
                }
                defaultLanguage
                is2FAEnabled
                slug
                defaultReminder
                notificationTimes
                daysOfWeek
                notificationMessage
                color
                icon
                alias
              }
            }
          `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => {
            if (res.status !== 200 && res.status !== 201) {
              console.log("There is an issue, grab a tissue.");
              resolve();
              throw new Error("Failed!");
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              console.log("There is an issue, grab a tissue.");
              resolve();
              return true;
            } else {
              return dispatch(setAuthenticatedUser(resData.data.refreshUser));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitRefreshRequest,
  };
};

export default useRefreshUser;
