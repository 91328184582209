import React, { useCallback, useState } from "react";
import { useFileUploader } from "utils/hooks";
import { useDropzone } from "react-dropzone";

import { getFileTypeIconFromUrl } from "pages/AppPages/TherapistPages/Resources/components/Files/utils";
import { Icon } from "components";
import { FileResponse } from "utils/hooks/files/useFileUploader";
import ImageIcon from "assets/icons/image.svg";
import "./ImageUploader.styles.scss";

type Props = {
  onSuccess: (fileData: FileResponse["fileData"]) => void;
  validationError?: string;
};
const ImageUploader = ({ onSuccess, validationError }: Props) => {
  const [error, setError] = useState<string | null>(null);
  const validateFileType = (file: File) => {
    const validTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
    return validTypes.indexOf(file.type) !== -1;
  };
  const validateSingleFile = (files: File[] | null) => {
    return files?.length == 1;
  };

  const { onFileChange, onFileUpload, selectedFile, isLoading } =
    useFileUploader();
  const onDrop = useCallback(
    async (acceptedFiles: File[] | null) => {
      if (!validateSingleFile(acceptedFiles)) {
        setError("Only a single file is allowed");
        return;
      }
      if (acceptedFiles) {
        const file = acceptedFiles[0];
        if (!validateFileType(file)) {
          setError("Only .jpeg, .png, .gif, .jpg, .svg files are allowed");
          return;
        }

        onFileChange(acceptedFiles);
        onFileUpload({
          file: acceptedFiles[0],
          args: {
            title: acceptedFiles[0].name,
            description: "",
            isImage: true,
          },
          onSuccess: (data) => onSuccess(data as FileResponse["fileData"]),
          allData: true,
        }).then(() => {
          setTimeout(() => {
            onFileChange(null);
          }, 1000);
        });
      }
    },
    [onFileChange, onFileUpload, onSuccess]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div
        {...getRootProps()}
        className={`file-dropzone ${
          isDragActive ? "file-dropzone--isActive" : ""
        }`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="file-name">
            <div className="selected-file__inner-blank">
              <div>
                <div className="image-icon">
                  <Icon src={ImageIcon} />
                </div>
                <div>Drop your image here ...</div>
              </div>
            </div>
          </div>
        ) : selectedFile ? (
          isLoading ? (
            <div className="selected-file__inner">
              <div className="file-icon">
                <Icon src={getFileTypeIconFromUrl(selectedFile.name)} />
              </div>
              <div className="selected-file__name">Uploading...</div>
            </div>
          ) : (
            <div className="selected-file__inner">
              <div className="file-icon">
                <Icon src={getFileTypeIconFromUrl(selectedFile.name)} />
              </div>
              <div className="selected-file__name">{selectedFile.name}</div>
            </div>
          )
        ) : (
          <div className="file-name">
            <div className="selected-file__inner-blank">
              <div>
                <div className="image-icon">
                  <Icon src={ImageIcon} />
                </div>
                <div>Drop an image file here or click to upload an image</div>
              </div>
            </div>
          </div>
        )}
      </div>
      {error ? (
        <p className="input__error">
          <small>{error}</small>
        </p>
      ) : (
        <p className="input__error">
          <small>{validationError}</small>
        </p>
      )}
    </>
  );
};

export default ImageUploader;
