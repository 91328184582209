import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";
import i18n from "i18next";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["uiState"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const languageMiddleware = () => (next) => (action) => {
  if (
    (action.type === "SET_AUTH_USER" ||
      action.type === "UPDATE_USER_PROFILE") &&
    action.payload.defaultLanguage
  ) {
    i18n.changeLanguage(action.payload.defaultLanguage);
    document.body.dir = i18n.dir();
  }
  return next(action);
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(languageMiddleware),
});

export const persistor = persistStore(store);
