export const addSubmissionDraftAction = (draft) => ({
  type: "ADD_SUBMISSION_DRAFT",
  payload: draft,
});

export const clearSubmissionDraftAction = (assignmentId) => ({
  type: "CLEAR_SUBMISSION_DRAFT",
  payload: assignmentId,
});

export const createSubmissionAction = (submission) => ({
  type: "CREATE_SUBMISSION",
  payload: submission,
});

export const editSubmissionAction = (submission) => ({
  type: "EDIT_SUBMISSION",
  payload: submission,
});

export const fetchSubmissionsAction = (submissions) => {
  submissions = submissions?.filter(
    (submission) =>
      submission.isDraft === undefined || submission.isDraft === false
  );
  return {
    type: "FETCH_SUBMISSIONS",
    payload: submissions,
  };
};

export const fetchGroupSubmissionsAction = (submissions) => {
  submissions = submissions?.filter(
    (submission) =>
      submission.isDraft === undefined || submission.isDraft === false
  );
  return {
    type: "FETCH_GROUP_SUBMISSIONS",
    payload: submissions,
  };
};
