import React from "react";
import { useCurrentUser } from "utils/hooks";

const SupportPage = () => {
  const { user } = useCurrentUser();

  return (
    <>
      <div className="text-center">
        <h1>Hi {user?.firstName}, how can we help you?</h1>
        <p>
          Please let us know if there is something we can help you with. <br />
          Feel free to report bugs, feature requests, or general questions.
        </p>
        <p>
          Email support:{" "}
          <a href="mailto:support@reflectiveapp.com">
            support@reflectiveapp.com
          </a>
        </p>
      </div>
    </>
  );
};

export default SupportPage;
