/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { default as Submissions } from "pages/AppPages/Shared/Submissions";
import usePatientSubmissionsDataColumns from "./utils/usePatientSubmissionsDataColumns";
import { useTranslation } from "react-i18next";

const SubmissionsPage = () => {
  const submissionDataColumns = usePatientSubmissionsDataColumns();
  const { t } = useTranslation(["common"]);
  return (
    <Submissions
      title={t("patient_assignments.your_submission_label")}
      submissionDataColumns={submissionDataColumns}
    />
  );
};

export default SubmissionsPage;
