import React from "react";
import { Button } from "components";
import { useTranslation } from "react-i18next";

interface SubscriptionSettingsProps {
  user: any;
}

const SubscriptionSettings = ({ user }: SubscriptionSettingsProps) => {
  const handleUpgradeButton = () => {
    window.location.href = `${process.env.REACT_APP_STRIPE_PLAN_URL}?prefilled_email=${user?.email}`;
  };
  const isFreePlan = user?.plan?.name !== "Free";

  const { t } = useTranslation(["common"]);

  return (
    <section className="settings-section" id="profile-settings">
      <h2 className="section-title">
        {t("settings.subscription_settings_label")}
      </h2>
      <hr className="divider" />
      <div className="settings-section__content">
        <p>
          Plan: <b>{user?.plan?.name}</b>
        </p>
        <Button
          name="upgrade_plan"
          value={t("settings.subscription_upgrade_button")}
          onClick={handleUpgradeButton}
          disabled={isFreePlan}
        />
      </div>
      {isFreePlan && (
        <div className="mt-2">
          <p>
            <small>
              {t("settings.subscription_downgrade_prefix")}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:support@reflectiveapp.com"
              >
                {t("settings.subscription_downgrade_label")}
              </a>
              .
            </small>
          </p>
        </div>
      )}
    </section>
  );
};

export default SubscriptionSettings;
