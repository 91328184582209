import "./Toggle.styles.scss";
import React from "react";
import { useTranslation } from "react-i18next";

interface ToggleProps {
  id?: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  onText?: string;
  offText?: string;
  isSmall?: boolean;
  forwardRef?: any;
  extraClass?: string;
}
const Toggle = ({
  id,
  checked,
  onChange,
  name,
  onText = "On",
  offText = "Off",
  isSmall,
  forwardRef,
  extraClass,
  ...props
}: ToggleProps) => {
  const { t } = useTranslation(["common"]);
  onText = onText == "On" ? t("common_labels.on") : onText;
  offText = offText == "Off" ? t("common_labels.off") : offText;
  const checkedClassName = checked ? "isChecked" : "";
  return (
    <div
      className={`toggle-switch ${
        isSmall ? "small-switch" : "big-switch"
      } ${checkedClassName} ${extraClass ? extraClass : ""}`}
    >
      <label className="toggle-switch-label" htmlFor={id}>
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          checked={checked}
          name={name}
          id={id}
          onChange={onChange}
          ref={forwardRef}
          {...props}
        />
        <span
          className="toggle-switch-inner"
          data-yes={onText}
          data-no={offText}
        />
        <span className="toggle-switch-switch" />
      </label>
    </div>
  );
};

export default Toggle;
