import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import { useLogin } from "utils/hooks";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const usePatientInvite = () => {
  const { submitLogin } = useLogin();
  const navigate = useNavigate();

  const acceptPatientInvite = (password, token) => {
    return new Promise((resolve) => {
      fetch(`${process.env.REACT_APP_REST_API_URL}/v2/invite/patient`, {
        method: "POST",
        body: JSON.stringify({
          token,
          password,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => (res.ok ? Promise.resolve(res) : Promise.reject(res)))
        .then((res) => res.json())
        .then((resData) => {
          toast(`Your password has been reset, logging you in :)`);
          ReactGA.event("password_reset_on_signup_success", {
            user_id: "action_when_unauthenticated",
          });
          const payload = jwtDecode(resData.accessToken.token);
          submitLogin({
            email: payload.email,
            password,
          }).then(() => {
            navigate("/settings/account");
            toast("Please update your first and last name");
          });
          resolve();
        })
        .catch((err) => {
          if (err.status >= 400 && err.status < 500) {
            err.json().then((errorData) => toast.error(errorData.message));
          } else {
            toast.error("Hmm, something went wrong.");
            console.error(err);
          }
          resolve();
        });
    });
  };

  return {
    acceptPatientInvite,
  };
};

export default usePatientInvite;
