import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store, persistor } from "store";
import App from "app-root/App";
import * as serviceWorker from "./serviceWorker";
import { PersistGate } from "redux-persist/integration/react";
import { PageLoading } from "components";
import "./i18next";
import { TopNavHeightProvider } from "components/layout/TopNav/TopNavHeightContext";

const root = document.getElementById("root");
const reactRoot = createRoot(root);

reactRoot.render(
  <Provider store={store}>
    <TopNavHeightProvider>
      <React.StrictMode>
        <PersistGate loading={<PageLoading />} persistor={persistor}>
          <App />
        </PersistGate>
      </React.StrictMode>
    </TopNavHeightProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
