import { PURGE } from "redux-persist";

const initialState = {
  resources: [],
  therapistResources: [],
  patientResources: [],
  patientPublicResources: [],
  groupResources: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PUBLIC_LIBRARY":
      return {
        ...state,
        resources: [...payload],
      };
    case "FETCH_PATIENT_LIBRARY":
      return {
        ...state,
        patientResources: [...payload],
      };
    case "FETCH_THERAPIST_LIBRARY":
      return {
        ...state,
        therapistResources: [...payload],
      };
    case "FETCH_GROUP_LIBRARY":
      return {
        ...state,
        groupResources: [...payload],
      };
    case "ADD_THERAPIST_LIBRARY_RESOURCE":
      return {
        ...state,
        therapistResources: [...state.therapistResources, payload],
      };
    case "ADD_GROUP_LIBRARY_RESOURCE":
      return {
        ...state,
        groupResources: [...state.groupResources, payload],
      };
    case "UPDATE_THERAPIST_LIBRARY_RESOURCE":
      if (payload.length === 0) return;
      const updatedResources = [...state.therapistResources];
      payload.map((item) => {
        const indexToUpdate = state.therapistResources.findIndex(
          (resource) => resource.uuid === item.uuid
        );
        if (indexToUpdate > 0) {
          updatedResources.splice(indexToUpdate, 1, item);
        }
      });
      return {
        ...state,
        therapistResources: updatedResources,
      };
    case "UPDATE_GROUP_LIBRARY_RESOURCE":
      if (payload.length === 0) return;
      const updatedGroupResources = [...state.groupResources];
      payload.map((item) => {
        const indexToUpdate = state.groupResources.findIndex(
          (resource) => resource.uuid === item.uuid
        );
        if (indexToUpdate > 0) {
          updatedGroupResources.splice(indexToUpdate, 1, item);
        }
      });
      return {
        ...state,
        groupResources: updatedGroupResources,
      };
    case "DELETE_THERAPIST_LIBRARY_RESOURCE":
      return {
        ...state,
        therapistResources: state.therapistResources.filter(
          (resource) => resource !== payload
        ),
      };
    case "DELETE_GROUP_LIBRARY_RESOURCE":
      return {
        ...state,
        groupResources: state.groupResources.filter(
          (resource) => resource !== payload
        ),
      };
    case "FETCH_PUBLIC_PATIENT_LIBRARY":
      return {
        ...state,
        patientPublicResources: [...payload],
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
