import "./RemoveFileConfirmationModal.styles.scss";
import React from "react";
import { Icon, Button } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";
import { useDeleteFile } from "utils/hooks";

interface RemoveFileConfirmationProps {
  file: any;
  onCancel: () => void;
  group: any;
}

const RemoveFileConfirmationModal = ({
  file,
  onCancel,
  group,
}: RemoveFileConfirmationProps) => {
  const { deleteGroupFile } = useDeleteFile();

  return (
    <div className="file-removal-confirmation">
      <div className="file-removal-confirmation__icon">
        <Icon src={WarningIcon} />
      </div>
      <div className="file-removal-confirmation__title">
        Are you sure you want to remove this file?
      </div>
      <div className="file-removal-confirmation__body">
        {group.name} will no longer have access.
      </div>
      <div className="file-removal-confirmation__buttons">
        <Button
          onClick={onCancel}
          name={`cancel_remove_file_button`}
          value="Never mind"
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => {
            deleteGroupFile(file);
            onCancel();
          }}
          name={`confirm_remove_file_button`}
          value="Yes, remove it"
          extraClass="danger"
          size="small"
        />
      </div>
    </div>
  );
};

export default RemoveFileConfirmationModal;
