import React, { useEffect, useState } from "react";
import ZoomContext from "./utils/context/zoom-context";
import ZoomVideo from "@zoom/videosdk";
import { zoomConfig } from "./utils/zoomConfig";
import { useGetZoomToken } from "utils/hooks";
import VideoCall from "./components/VideoCall";

const meetingArgs = { ...zoomConfig };
const client = ZoomVideo.createClient();

const VideoSession = () => {
  const { getZoomToken } = useGetZoomToken({ meetingArgs });
  const [updatedMeetingArgs, setUpdatedMeetingArgs] = useState(meetingArgs);

  useEffect(() => {
    if (!updatedMeetingArgs.signature) {
      getZoomToken().then((signature) => {
        setUpdatedMeetingArgs({ ...meetingArgs, signature: signature || "" });
      });
    }
  }, [getZoomToken, updatedMeetingArgs.signature]);

  return (
    <ZoomContext.Provider value={client}>
      {updatedMeetingArgs.signature ? (
        <VideoCall meetingArgs={updatedMeetingArgs} />
      ) : (
        <p>Loading...</p>
      )}
    </ZoomContext.Provider>
  );
};

export default VideoSession;
