import { User } from "types";

//  Upgrades user attributes in SimpleBase Chatbox after user login (only therapists)
export function gatherUserAttributes(user: User | null, isTherapist: boolean) {
  //  Gets user signup full date
  const dateCreatedAt = new Date(Number(user?.createdAt));
  //  Gets month full name
  const monthCreatedAt =
    dateCreatedAt
      .toLocaleString("default", { month: "long" })
      .charAt(0)
      .toUpperCase() +
    dateCreatedAt?.toLocaleString("default", { month: "long" }).slice(1);
  //  Creates an object with therapist's info for chatbox
  const attributes = {
    Name: user?.fullName,
    "User Type": isTherapist ? "Therapist" : "",
    "Signup Date:": `${monthCreatedAt} ${dateCreatedAt?.getUTCDate()}, ${dateCreatedAt?.getFullYear()}`,
    Plan: user?.plan?.name ? user.plan.name : "No plan",
  };

  return attributes;
}
