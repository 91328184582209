import "react-datepicker/dist/react-datepicker.css";
import "./TimeField.styles.scss";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

interface TimeFieldProps {
  name: string;
  label?: string | null;
  error?: string | null;
  hint?: string;
  placeholder?: string;
  selected?: Date;
  onChange?: (value: Date) => void;
  extraClass?: string;
  disabled?: boolean;
}

const TimeField = ({
  name,
  label,
  error,
  hint,
  placeholder,
  selected,
  onChange,
  extraClass,
  disabled,
  ...props
}: TimeFieldProps) => {
  const [selectedTime, setSelectedTime] = useState(selected || new Date());

  const handleTimeChange = (value: Date) => {
    setSelectedTime(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className={`time-field ${extraClass ? extraClass : ""}`}>
      {label && <label className="time-field__label">{label}</label>}
      <DatePicker
        name={name}
        aria-label={label}
        className={`time-field__field ${error ? "error" : ""}`}
        selected={selectedTime}
        onChange={handleTimeChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        placeholderText={placeholder}
        disabled={disabled}
        readOnly={disabled}
        {...props}
      />
      {error && (
        <p className="time-field__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && (
        <p className="time-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default TimeField;
